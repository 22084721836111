var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_call_task_search_result_page bbox" },
    [
      _vm.initLoading
        ? _c(
            "div",
            {
              staticClass: "loading_wrapper d-flex align-center justify-center",
            },
            [
              _c(
                "div",
                { staticClass: "loading_group" },
                [_c("loading-icon", { attrs: { size: "30px" } })],
                1
              ),
            ]
          )
        : _vm._e(),
      !_vm.initLoading
        ? [
            [
              _c(
                "div",
                {
                  staticClass: "title_group d-flex align-center un_sel",
                  class: { close: !_vm.switch_task },
                },
                [
                  _c("div", {
                    staticClass: "icon cp",
                    on: {
                      click: function ($event) {
                        _vm.switch_task = !_vm.switch_task
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "title cp",
                      on: {
                        click: function ($event) {
                          _vm.switch_task = !_vm.switch_task
                        },
                      },
                    },
                    [_vm._v("任务")]
                  ),
                ]
              ),
              _vm.switch_task
                ? [
                    _vm.task_list.length !== 0
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "task_list d-flex align-start flex-wrap",
                            },
                            [
                              _vm._l(_vm.task_list, function (item, index) {
                                return [
                                  _c("thumb-task", {
                                    key: index,
                                    staticClass: "mr-10 mb-12 pl-10",
                                    attrs: {
                                      item: item,
                                      menu: false,
                                      show_from: true,
                                    },
                                    on: { handleClick: _vm.callTask },
                                  }),
                                ]
                              }),
                            ],
                            2
                          ),
                          !_vm.nomore_task
                            ? _c("div", { staticClass: "load_more" }, [
                                !_vm.loading_task
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "text cp",
                                        on: {
                                          click: function ($event) {
                                            return _vm.loadMoreTask()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              加载更多\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.loading_task
                                  ? _c(
                                      "div",
                                      { staticClass: "loading_group" },
                                      [
                                        _c("loading-icon", {
                                          attrs: { size: "20px" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      : [
                          _c("div", { staticClass: "no_data" }, [
                            _vm._v("\n            无相关任务\n          "),
                          ]),
                        ],
                  ]
                : _vm._e(),
            ],
            _vm.user_list.length || 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title_group d-flex align-center un_sel",
                      class: { close: !_vm.switch_user },
                    },
                    [
                      _c("div", {
                        staticClass: "icon cp",
                        on: {
                          click: function ($event) {
                            return _vm.switchTag("switch_user")
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "title cp",
                          on: {
                            click: function ($event) {
                              return _vm.switchTag("switch_user")
                            },
                          },
                        },
                        [_vm._v("联系人")]
                      ),
                    ]
                  ),
                  _vm.switch_user
                    ? [
                        !_vm.loadingWeb
                          ? [
                              _vm.user_list && _vm.user_list.length !== 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user_list d-flex align-center flex-wrap",
                                      },
                                      [
                                        _vm._l(
                                          _vm.user_list,
                                          function (item, index) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "user_group d-flex align-center cp",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.appointUser(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "name" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.friendRemark
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    !_vm.nomore_user && 0
                                      ? _c(
                                          "div",
                                          { staticClass: "load_more" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text cp",
                                                on: { click: _vm.loadMoreTask },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                加载更多\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _c("div", { staticClass: "no_data" }, [
                                      _vm._v(
                                        "\n              无相关联系人\n            "
                                      ),
                                    ]),
                                  ],
                            ]
                          : _vm._e(),
                        _vm.loadingWeb
                          ? [
                              _c(
                                "div",
                                { staticClass: "loading_group" },
                                [
                                  _c("loading-icon", {
                                    attrs: { size: "20px" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.group_list.length || 1
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title_group d-flex align-center un_sel",
                      class: { close: !_vm.switch_group },
                    },
                    [
                      _c("div", {
                        staticClass: "icon cp",
                        on: {
                          click: function ($event) {
                            return _vm.switchTag("switch_group")
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "title cp",
                          on: {
                            click: function ($event) {
                              return _vm.switchTag("switch_group")
                            },
                          },
                        },
                        [_vm._v("\n          工作组\n        ")]
                      ),
                    ]
                  ),
                  _vm.switch_group
                    ? [
                        !_vm.loadingWeb
                          ? [
                              _vm.group_list && _vm.group_list.length !== 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "group_list d-flex align-center flex-wrap",
                                      },
                                      [
                                        _vm._l(
                                          _vm.group_list,
                                          function (item, index) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "group_group d-flex align-center cp",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.appointGroup(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column align-start",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "name" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.chatName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      item.secondleveContent !=
                                                      null
                                                        ? _c("div", {
                                                            staticClass:
                                                              "member",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.$tools.highlightKeywords(
                                                                  `成员: ${item.secondleveContent}`,
                                                                  _vm.keyword
                                                                )
                                                              ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    !_vm.nomore_group && 0
                                      ? _c(
                                          "div",
                                          { staticClass: "load_more" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text cp",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.loadMoreTask()
                                                  },
                                                },
                                              },
                                              [_vm._v("加载更多")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _c("div", { staticClass: "no_data" }, [
                                      _vm._v(
                                        "\n              无相关工作组\n            "
                                      ),
                                    ]),
                                  ],
                            ]
                          : _vm._e(),
                        _vm.loadingWeb
                          ? [
                              _c(
                                "div",
                                { staticClass: "loading_group" },
                                [
                                  _c("loading-icon", {
                                    attrs: { size: "20px" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.nodata && 0
              ? _c("div", { staticClass: "nodata" }, [
                  _vm._v("\n      搜索结果为空\n    "),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }