var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget_copy_button cp" }, [
    _c(
      "div",
      {
        staticClass: "content_group d-flex align-center justify-space-between",
      },
      [
        _c("div", { staticClass: "icon flex-shrink-0", class: [_vm.iconType] }),
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.name) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "fun_group flex-shrink-0 d-flex align-center" },
          [
            _c("div", {
              staticClass: "delete",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doDelete.apply(null, arguments)
                },
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }