var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showUnit
    ? _c(
        "div",
        {
          staticClass:
            "c_fun_template_select_contace_content_thumb_unit d-flex",
        },
        [
          _c("div", { staticClass: "name_group" }, [
            _c("img", {
              staticClass: "avatar my-10",
              attrs: {
                src: `${_vm.$avatar_url}${_vm.avatar}@!small200`,
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.name) },
              },
              [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }