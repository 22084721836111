var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: `${300 * _vm.column}px`, max_height: "700px" } },
    [
      _c(
        "div",
        {
          staticClass:
            "components_pop_up_select_contacts_index d-flex flex-column",
        },
        [
          _c("common-title", {
            attrs: { title: _vm.title, close: "" },
            on: { handleClose: _vm.handleClose },
          }),
          _c("main-group", {
            staticClass: "main",
            attrs: {
              select_target: _vm.select_target,
              dialog_user_list: _vm.dialog_user_list,
            },
          }),
          _c("operation", { on: { handleCancel: _vm.handleClose } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }