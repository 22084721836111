var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_select_contacts_operation d-flex align-center justify-end bbox flex-shrink-0",
    },
    [
      _c(
        "div",
        {
          staticClass: "btn btn_light cp",
          on: {
            click: function ($event) {
              return _vm.handleCancel()
            },
          },
        },
        [_vm._v("取消")]
      ),
      _c("div", { staticClass: "btn btn_dark cp" }, [_vm._v("确定")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }