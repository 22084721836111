var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_sidebar_chat_container_page bbox" },
    [
      _c("chat", {
        attrs: {
          task_id: _vm.task_id,
          chat_type: _vm.chat_type,
          chat_id: Number(_vm.chat_id),
          type: _vm.type,
          chat_message_id: Number(_vm.chat_message_id),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }