<!--
 * @Author       : Hugo
 * @Date         : 2021-11-24 10:34:21
 * @LastEditTime : 2021-11-24 15:39:27
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/modules/Unit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_select_contacts_modules_user bbox d-flex align-center justify-space-between"
  >
    <div class="info_group d-flex align-center">
      <img :src="avatar" alt="" class="avatar flex-shrink-0 mr-16">
      <div class="user_name">{{name}}</div>
    </div>
    <div class="check cp flex-shrink-0"
      v-if="type === 'select'"
    ></div>
    <div class="delete cp flex-shrink-0"
      v-if="type === 'delete'"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'select'
    },
    avatar: {
      type: String,
      default: '',
    },
    name: {
      typt: String,
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_modules_user{
  @include transition;
  font-size: 14px;
  color: #666666;
  overflow: auto;
  &:hover{
    background-color: #ddd;
  }
  .check{
    width: 16px;
    height: 16px;
    background-image: url(~@/assets/images/select_contacts/unselected.png);
    &.active{
      background-image: url(~@/assets/images/select_contacts/selected.png);
    }
  }
  .delete{
    width: 16px;
    height: 16px;
    background-image: url(~@/assets/images/select_contacts/delete.png);
  }
  .info_group{
    flex: 1;
    overflow: auto;
    .avatar{
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 4px;
    }
    .user_name{
      @include single_line_intercept;
      flex: 1;
    }
  }
}
</style>