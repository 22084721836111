var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_start_page bbox d-flex align-center justify-center",
    },
    [
      _c("div", { staticClass: "un_sel d-flex flex-column align-center" }, [
        _c("img", {
          staticClass: "start",
          attrs: { src: require("@/assets/images/common/empty.png"), alt: "" },
        }),
        _c("div", { staticClass: "hint" }, [_vm._v("当前没有教学内容")]),
        _c(
          "div",
          { staticClass: "button btn_dark", on: { click: _vm.doStart } },
          [_vm._v("开始制作教学内容")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }