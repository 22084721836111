<template>
  <div
    class="components_pop_up_select_target_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <select-contact-content
          :select_target="select_target"
          :select_target_chat_id="select_target_chat_id"
          :select_target_chat_type="select_target_chat_type"
          :list="list"
          @closeSelectContact="doClose"
          @handleSure="doSure"
        ></select-contact-content>
      </div>
    </div>
  </div>
</template>

<script>
import SelectContactContent from '@/components/FunTemplate/SelectContactContent.vue';
export default {
  components:{
    SelectContactContent,
  },
  props:{
    select_target: {
      type: Boolean,
      default: false,
    },
    select_target_chat_id: {
      default: null,
    },
    select_target_chat_type: {
      default: null,
    },
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
  },
  data(){
    return {
      index: 1
    }
  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    doSure(data){
      // // console.log(data);
      this.$emit('handleSure', data);
    },
  },
}
</script>
<style lang="scss" scoped>
.components_pop_up_select_target_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    // width: 100%;
    // height: 100%;
    // max-width: 80%;
    height: 80%;
    // border-radius: 4px;
    // box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    // background-color:#fff;
  }
}
</style>