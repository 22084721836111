<template>
  <div 
    class="components_task_sidebar_chat_container_page bbox"
  >
    <chat
      :task_id="task_id"
      :chat_type="chat_type"
      :chat_id="Number(chat_id)"
      :type="type"
      :chat_message_id="Number(chat_message_id)"
    ></chat>
  </div>
</template>
<script>
import Chat from '@/components/dialog/Chat/Page.vue';
export default {
  components: {
    Chat,
  },
  props:{
    type: {
      type: String,
      default: 'widget'
    },
  },
  data(){
    return {
    }
  },
  computed: {
    task_id(){
      const {task} = this.$store.state;
      return task.task.id;
    },
    chat_type(){
      const {task_config} = this.$store.state;
      return task_config.chat_type;
    },
    chat_id(){
      const {task_config} = this.$store.state;
      return task_config.chat_id;
    },
    chat_message_id(){
      const {task_config} = this.$store.state;
      return task_config.chat_message_id;
    }
  },
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_chat_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;

}
</style>