var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_select_target_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c(
            "div",
            { staticClass: "container bbox d-flex flex-column" },
            [
              _c("select-contact-content", {
                attrs: {
                  select_target: _vm.select_target,
                  select_target_chat_id: _vm.select_target_chat_id,
                  select_target_chat_type: _vm.select_target_chat_type,
                  list: _vm.list,
                },
                on: { closeSelectContact: _vm.doClose, handleSure: _vm.doSure },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }