var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_popup_custom_classify_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c("div", { staticClass: "container bbox d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass:
                  "title_group d-flex align-center un_sel justify-center flex-shrink-0",
              },
              [
                _c("div", { staticClass: "title" }, [_vm._v("自定义分类")]),
                _c("div", {
                  staticClass: "close cp",
                  on: {
                    click: function ($event) {
                      return _vm.doClose()
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "main d-flex flex-column" },
              [
                _vm.status_breadcrumb
                  ? _c(
                      "div",
                      { staticClass: "path_group flex-shrink-0 bbox" },
                      [
                        _c(
                          "overlay-scrollbars",
                          { ref: "overlay", staticClass: "overlay_container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "path_overlay_group d-flex flex-align",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text top cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToTop()
                                      },
                                    },
                                  },
                                  [_vm._v("全部分组")]
                                ),
                                _c("breadcrumb", {
                                  attrs: {
                                    data: _vm.breadcrumb,
                                    folder_id: _vm.folder_id,
                                    call_custom: true,
                                    current: true,
                                  },
                                  on: { handleChangeFolder: _vm.changeFolder },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("views", {
                  ref: "views",
                  attrs: { call_custom: true, folder_id: _vm.folder_id },
                  on: {
                    handleChangeFolder: _vm.changeFolder,
                    handleCallTask: _vm.callTask,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }