<!--
 * @Author       : Hugo
 * @Date         : 2022-04-26 17:03:09
 * @LastEditTime : 2022-04-27 16:57:10
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/CallTask/PurchaseAndSell.vue
 * @Description  :
 * @^_^          :
-->
<template>
  <div
    class="c_pop_up_call_task_purchase_and_sell bbox"
  >
    <div class="navigation_group d-flex align-center flex-wrap bbox"
      v-if="task_call_config.p_package"
    >
      <div
        class="navigation cp"
        @click.stop="doReturn()"
      >全部课程</div>
      <div class="navigation mx-6">></div>
      <div
        class="navigation active"
      >{{task_call_config.p_package.title}}</div>
    </div>
    <package-list
      v-if="show_package_list"
      :task_call_config="task_call_config"
    ></package-list>
    <task-list
      v-if="show_task_list"
      :task_call_config="task_call_config"
    ></task-list>
    <package-detail
      v-if="show_package_detail"
      :task_call_config="task_call_config"
    ></package-detail>

  </div>
</template>

<script>
import {listForClasses, Navigation} from '@/classes'
import PackageList from './Modules/PackageList.vue'
import PackageDetail from './Modules/PackageDetail.vue'
import TaskList from './Modules/TaskList.vue'
export default {
  components: {
    PackageList,
    PackageDetail,
    TaskList,
  },
  data(){
    return {
      navigation_list: [],
    }
  },
  props: {
    task_call_config: {
      type:Object,
      default: undefined,
    }
  },
  computed: {
    show_package_list(){
      const {task_call_config} = this
      if(task_call_config.package && task_call_config.package_id === undefined){
        return true
      }
      return false
    },
    show_package_detail(){
      const {task_call_config} = this
      if(task_call_config.package && task_call_config.package_id !== undefined){
        return true
      }
      return false
    },
    show_task_list(){
      const {task_call_config} = this
      if(!task_call_config.package){
        return true
      }
      return false
    }

  },
  mounted(){
    this.init()
  },
  methods: {
    clearNavigation(){
      this.$set(
        this,
        'navigation_list',
        [
          ...new Set([
            new Navigation({
              name: '全部',
            })
          ])
        ]
      )
    },
    init(){
      this.clearNavigation()
    },
    doReturn(){
      this.task_call_config.package_id = undefined
      this.task_call_config.p_package = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.c_pop_up_call_task_purchase_and_sell{
  .navigation_group{
    width: 100%;
    padding: 0 10px 10px;
    font-size: 14px;
    color: #666;
    .navigation{
      @include transition;
      &:hover,&.active{
        color: $main_blue;
      }
    }
  }
}
</style>
