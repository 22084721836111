var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list && _vm.list.length
    ? _c(
        "div",
        {
          staticClass:
            "components_task_sidebar_page bbox d-flex flex-column flex-shrink-0",
          class: { close: !_vm.switch_sidebar },
        },
        [
          !_vm.public_notice && !_vm.shopping_mail
            ? _c("div", { staticClass: "open_group" }, [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.switch_sidebar,
                      expression: "!switch_sidebar",
                    },
                  ],
                  staticClass: "open button",
                  on: {
                    click: function ($event) {
                      return _vm.changeSwitchSidebar()
                    },
                  },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.switch_sidebar,
                      expression: "switch_sidebar",
                    },
                  ],
                  staticClass: "close button",
                  on: {
                    click: function ($event) {
                      return _vm.changeSwitchSidebar()
                    },
                  },
                }),
              ])
            : _vm._e(),
          !_vm.public_notice && !_vm.sell
            ? _c("fun", {
                staticClass: "fun_component border-bottom",
                attrs: {
                  switch_sidebar: _vm.switch_sidebar,
                  list: _vm.active_list,
                },
                on: { handleClick: _vm.changePannel },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switch_sidebar,
                  expression: "switch_sidebar",
                },
              ],
              staticClass: "pannel_group",
            },
            [
              _c("widget-container", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.type === "widget",
                    expression: "type==='widget'",
                  },
                ],
                attrs: {
                  editable: _vm.editable,
                  public_notice: _vm.public_notice,
                },
                on: { handleAddNewWidget: _vm.addNewWidget },
              }),
              !_vm.public_notice
                ? [
                    _vm.type === "chat"
                      ? _c("chat-container", { attrs: { type: _vm.type } })
                      : _vm._e(),
                    _vm.type === "record" ? _c("record-container") : _vm._e(),
                    _c("teach-container", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === "teach",
                          expression: "type==='teach'",
                        },
                      ],
                      attrs: {
                        can_teach: _vm.can_teach,
                        sell: _vm.sell,
                        show_intro: _vm.show_intro,
                        shopping_mail: _vm.shopping_mail,
                      },
                      on: { handleSavaTask: _vm.handleSavaTask },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }