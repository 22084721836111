var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "components_pop_up_select_contacts_modules_search_group" },
      [
        _c("div", { staticClass: "group d-flex align-center bbox" }, [
          _c("div", { staticClass: "icon" }),
          _c("input", {
            staticClass: "search",
            attrs: { type: "text", placeholder: "搜索" },
          }),
          _c("div", { staticClass: "clear" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }