var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_main_page bbox d-flex flex-column" },
    [
      _vm.task_user_status
        ? _c("task-user", {
            staticClass:
              "flex-shrink-0 task_user_component px-20 border-bottom",
            attrs: {
              editable: _vm.editable,
              select_target: _vm.select_target,
              view: _vm.view,
              recycle: _vm.recycle,
            },
          })
        : _vm._e(),
      _vm.task_title_status
        ? _c("task-title", {
            staticClass:
              "flex-shrink-0 task_title_component px-20 border-bottom",
            attrs: { editable: _vm.editable },
          })
        : _vm._e(),
      _vm.tag_status && !_vm.task_config.hide_tag
        ? _c("task-tag", {
            staticClass: "flex-shrink-0 task_tag_component px-20 border-bottom",
            attrs: { tags: _vm.task.task.tags || null, editable: _vm.editable },
          })
        : _vm._e(),
      _c("widget-container", {
        ref: "widget_container",
        staticClass: "widge_container_component",
        attrs: {
          list: _vm.taskDeatails,
          editable: _vm.editable,
          sell: _vm.sell,
          public_notice: _vm.public_notice,
          shopping_mail: _vm.shopping_mail,
          recycle: _vm.recycle,
          system: _vm.system,
        },
        on: {
          handleDeleteWidget: _vm.deleteWidget,
          handleDeleteRelevancy: _vm.deleteRelevancy,
          handleMofifiedSign: _vm.modifiedSign,
          handleSortFile: _vm.handleSortFile,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.call_hint_status
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "call_task_entrance d-flex flex-column align-center justify-center cp bbox",
                        on: {
                          click: function ($event) {
                            return _vm.startCallTask()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "un_sel",
                          attrs: {
                            src: require("@/assets/images/task/call_task_entrance.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text un_sel" }, [
                          _vm._v("调用已有模板 创建全新任务"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.switch_call_task
        ? _c("call-task-pop-up", {
            on: { handleClose: _vm.closeCallTask, handleCall: _vm.callTask },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }