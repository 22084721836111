var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_titel_page bbox d-flex align-start justify-space-between",
      class: [_vm.statusType],
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("教学内容" + _vm._s(_vm.edit ? "制作" : "")),
          ]),
          _vm.showCheckStatus
            ? [
                _c("div", { staticClass: "check_icon" }),
                _c("div", { staticClass: "wait_check" }, [
                  _vm._v("\n        " + _vm._s(_vm.statusText) + "\n      "),
                ]),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top",
                      width: "200",
                      trigger: "hover",
                      "visible-arrow": false,
                      "popper-class":
                        "v_task_teaching_area_check_reason_popover_wrapper",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "v_task_teaching_area_check_reason_popover",
                      },
                      [
                        _vm.task.task.checkstatus === 4
                          ? _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n            未通过原因: " +
                                  _vm._s(_vm.task.task.reason) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        0
                          ? _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n            审核人: " +
                                  _vm._s(_vm.task.task.checkUserName) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm.task.task.checkstatus === 4
                      ? _c("template", { slot: "reference" }, [
                          _c("div", { staticClass: "hint_icon" }),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.edit
        ? _c("div", { staticClass: "fun_group d-flex align-center" }, [
            _c("div", {
              staticClass: "menu cp",
              on: {
                click: function ($event) {
                  return _vm.doReturn()
                },
              },
            }),
            _c("div", {
              staticClass: "close cp",
              on: {
                click: function ($event) {
                  return _vm.doClose()
                },
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }