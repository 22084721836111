var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog_chat_page" },
    [
      _c("div", { staticClass: "content_group" }, [
        !_vm.task_chat_message_list ||
        (_vm.task_chat_message_list.length == 0 && _vm.load_init_message_list)
          ? _c("div", { staticClass: "empty_group" }, [
              _c("img", {
                staticClass: "empty_image",
                attrs: {
                  src: require("@/assets/images/common/none.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "empty_text" }, [
                _vm._v("暂无讨论内容"),
              ]),
            ])
          : _c("div", { staticClass: "message_group" }, [
              _c(
                "div",
                {
                  ref: "message_box",
                  staticClass: "message_box",
                  attrs: { id: "message_box" },
                },
                [
                  _vm.no_more
                    ? _c("div", { staticClass: "no_more" }, [
                        _vm._v("没有更多了"),
                      ])
                    : _vm._e(),
                  _vm.nomore_prev
                    ? _c("div", { staticClass: "no_more" }, [
                        _vm._v("没有更多了"),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_prev,
                          expression: "loading_prev",
                        },
                      ],
                      staticClass: "loading_more",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("loading-icon", { attrs: { size: "17px" } })],
                        1
                      ),
                      _c("div", { staticClass: "text" }, [_vm._v("加载中...")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_more_status,
                          expression: "loading_more_status",
                        },
                      ],
                      staticClass: "loading_more",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("loading-icon", { attrs: { size: "17px" } })],
                        1
                      ),
                      _c("div", { staticClass: "text" }, [_vm._v("加载中...")]),
                    ]
                  ),
                  _vm._l(_vm.task_chat_message_list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        ref: "msg_unit",
                        refInFor: true,
                        staticClass: "msg_unit",
                      },
                      [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "msg_info" }, [
                          _c("div", { staticClass: "title_group" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.getUserName(item))),
                            ]),
                            _c("div", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(_vm._f("universalDate")(item.createTime))
                              ),
                            ]),
                          ]),
                          _c("div", {
                            staticClass: "content",
                            domProps: { innerHTML: _vm._s(item.work) },
                          }),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_next,
                          expression: "loading_next",
                        },
                      ],
                      staticClass: "loading_more",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("loading-icon", { attrs: { size: "17px" } })],
                        1
                      ),
                      _c("div", { staticClass: "text" }, [_vm._v("加载中...")]),
                    ]
                  ),
                ],
                2
              ),
            ]),
      ]),
      _c("div", { staticClass: "edit_group" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: {
            src: `${_vm.$avatar_url}${_vm.user.thumbnail}@!small200`,
            alt: "",
          },
        }),
        _c(
          "div",
          { staticClass: "input_group" },
          [
            _c("el-input", {
              ref: "editor",
              staticClass: "editor",
              attrs: {
                type: "textarea",
                rows: 3,
                placeholder: _vm.editor_placeholder,
                resize: "none",
              },
              nativeOn: {
                keydown: [
                  function ($event) {
                    return _vm.handleKeydown($event)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.doEnter($event)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.ctrlKey) return null
                    return _vm.doEnterControl($event)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.shiftKey) return null
                    return _vm.doShiftEnter($event)
                  },
                ],
                input: function ($event) {
                  return _vm.handleKeyup($event)
                },
              },
              model: {
                value: _vm.editor_content,
                callback: function ($$v) {
                  _vm.editor_content = $$v
                },
                expression: "editor_content",
              },
            }),
            _c(
              "div",
              { staticClass: "send_group" },
              [
                _c(
                  "button",
                  {
                    staticClass: "send button",
                    on: {
                      click: function ($event) {
                        return _vm.doSend()
                      },
                    },
                  },
                  [_vm._v("发布")]
                ),
                _c(
                  "el-popover",
                  {
                    attrs: { placement: "top-end", trigger: "click" },
                    model: {
                      value: _vm.switch_send_options,
                      callback: function ($$v) {
                        _vm.switch_send_options = $$v
                      },
                      expression: "switch_send_options",
                    },
                  },
                  [
                    _c("div", { staticClass: "send_option_group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "options",
                          class: { on: !_vm.send_type },
                          on: {
                            click: function ($event) {
                              _vm.send_type = 0
                              _vm.switch_send_options = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content" }, [
                            _vm._v("按Enter发布讨论内容"),
                          ]),
                          _c("div", { staticClass: "selected" }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "options",
                          class: { on: _vm.send_type },
                          on: {
                            click: function ($event) {
                              _vm.send_type = 1
                              _vm.switch_send_options = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content" }, [
                            _vm._v("按Ctrl+Enter发布讨论内容"),
                          ]),
                          _c("div", { staticClass: "selected" }),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "option_btn",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.switch_at && 0
        ? _c("at-pop-up", {
            attrs: {
              chat_type: Number(_vm.chat_type),
              chat_id: Number(_vm.chat_id),
              task_id: Number(_vm.task_id),
              title: "从任务参与人中选择@对象",
            },
            on: {
              handleClose: _vm.closeAt,
              handleSure: _vm.addAt,
              handleAll: _vm.atAll,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }