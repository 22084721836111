var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.handleResize,
          expression: "handleResize",
        },
      ],
      ref: "page",
      staticClass:
        "components_task_main_linebar_task_user_page bbox d-flex align-center flex-wrap align-content-center",
      attrs: { id: "components_task_main_linebar_task_user_page" },
    },
    [
      _c("div", { staticClass: "icon flex-shrink-0" }),
      _c(
        "div",
        { staticClass: "list flex-shrink-0 d-flex align-center" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              index < _vm.max_avatar_number
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "avatar_group",
                      on: {
                        click: function ($event) {
                          return _vm.openUserDetail(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                          alt: "",
                        },
                      }),
                      item.isread && !_vm.auditMode
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "readed_group d-flex align-center justify-center",
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("已读"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "hint" }, [
                        _vm._v(_vm._s(_vm.priorityName(item))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm.is_more
        ? _c("div", {
            staticClass: "more flex-shrink-0",
            on: {
              click: function ($event) {
                return _vm.checkMore()
              },
            },
          })
        : _vm._e(),
      _vm.status_add
        ? _c(
            "div",
            {
              staticClass: "add flex-shrink-0",
              on: {
                click: function ($event) {
                  return _vm.startSelectTaskUser()
                },
              },
            },
            [_c("div", { staticClass: "hint" }, [_vm._v("点击添加参与人")])]
          )
        : _vm._e(),
      _vm.switch_select_users
        ? _c("select-users", {
            attrs: { select_all: _vm.select_all, list: _vm.select_list },
            on: {
              handleClose: _vm.closeSelectTaskUser,
              handleSure: _vm.changeTaskUser,
            },
          })
        : _vm._e(),
      _vm.switch_select_target
        ? _c("select-target", {
            attrs: {
              select_target: _vm.select_target,
              select_target_chat_id: _vm.select_target_chat_id,
              select_target_chat_type: _vm.select_target_chat_type,
              list: _vm.list,
            },
            on: {
              handleClose: _vm.closeSelectTarget,
              handleSure: _vm.changeSelectTarget,
            },
          })
        : _vm._e(),
      _vm.switch_net_user_list
        ? _c("net-user-list", {
            attrs: {
              title: "查看参与人",
              list: _vm.list,
              open_readed: true,
              open_add: _vm.editable,
              loading_users: _vm.loading_users,
            },
            on: {
              handleTouchAdd: _vm.startSelectTaskUser,
              handleClose: _vm.closeNetUserList,
            },
          })
        : _vm._e(),
      _vm.switch_select_contacts
        ? _c("select-contacts", {
            attrs: {
              title: _vm.select_contacts_title,
              select_target: _vm.select_target,
              chat_id: Number(_vm.select_contacts_data.chat_id),
              chat_type: Number(_vm.select_contacts_data.chat_type),
            },
            on: {
              handleClose: function ($event) {
                return _vm.closeSelectContacts()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }