<!-- <script>export default {
  components: {}
}
</script> -->
<template>
  <div
    class="c_task_main_task_title_status_tag d-flex align-center"
  >
    <el-popover
      v-model="switchOpen"
      placement="bottom-start"
      width="120"
      trigger="click"
      popper-class="c_task_main_task_title_status_tag_select_group"
      :visible-arrow="false"
      @show="showSelect"
    >
      <select-group
        ref="selectGroup"
        @handleClose="handleClose"
      ></select-group>
      <template
        slot="reference"
      >
        <div
          class="toggle_button cp"
        ></div>
      </template>
    </el-popover>
    <!--<div class="tag_list d-flex align-center">-->
    <!--  <template-->
    <!--    v-for="(item, index) in titleStatusList"-->
    <!--  >-->
    <!--    <div-->
    <!--      :key="index"-->
    <!--      class="tag"-->
    <!--      :class="{ [item.type]: true }"-->
    <!--    ></div>-->
    <!--  </template>-->
    <!--</div>-->
  </div>
</template>

<script>
import SelectGroup from '@/components/Task/Main/LineBar/TaskTitle/SelectGroup'
// import SelectUnit from '@/components/Task/Main/LineBar/TaskTitle/SelectUnit'
// import store from '@/store'
export default {
  name: 'StatusTag',
  components: {
    SelectGroup,
    // SelectUnit
  },
  data() {
    return {
      switchOpen: false,
    }
  },
  methods: {
    handleClose() {
      this.switchOpen = false
    },
    showSelect() {
      this.$refs.selectGroup.init()
    }
  }

}
</script>

<style scoped lang="scss">
@mixin tag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/#{$name}.png');
  }
}
.c_task_main_task_title_status_tag{
  .toggle_button{
    @include background(16px, 16px);
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/task/status_tag/button.png');
  }
  .tag_list{
    .tag{
      @include tag(urgency);
      @include tag(importance);
      @include tag(finish);
      @include tag(unfinished);
      @include tag(pause);
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }
  }
}
</style>
<style lang="scss">
@mixin tag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/#{$name}.png');
  }
}
@mixin unTag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/_#{$name}.png');
  }
}
.c_task_main_task_title_status_tag_select_group{
  min-width: unset;
  padding: 0 16px;
  .select_list{
    .tag_list{
      padding: 12px 0;
      border-bottom: 2px solid #F4F4F4;
      .tag{
        padding: 8px 0 ;
        width: 100%;
        cursor: pointer;
        &.active{
          .select_icon{
            border: 5px solid #0054A7;
          }
          .type_icon{
            @include tag(urgency);
            @include tag(importance);
            @include tag(finish);
            @include tag(unfinished);
            @include tag(pause);
          }
        }
        //&:hover{
        //  .type_icon{
        //    @include tag(urgency);
        //    @include tag(importance);
        //    @include tag(finish);
        //    @include tag(unfinished);
        //    @include tag(pause);
        //  }
        //}
        .select_icon{
          @include transition;
          box-sizing: border-box;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid #AAAAAA;
          margin-right: 20px;
        }
        .type_icon{
          @include background(20px, 20px);
          @include transition;
          @include unTag(urgency);
          @include unTag(importance);
          @include unTag(finish);
          @include unTag(unfinished);
          @include unTag(pause);
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
        .name{
          font-size: 14px;
          font-family: MicrosoftYaHei,sans-serif;
          color: #333333;
          line-height: 19px;
        }
      }
    }
    .fun_group{
      padding: 12px 0;
      .btn{
        font-size: 12px;
        text-align: center;
        padding: 0 12px;
        line-height: 26px;
        border-radius: 4px;
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
</style>