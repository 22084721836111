var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_select_contacts_main d-flex bbox" },
    [
      _vm.select_target ? _c("dialog-list") : _vm._e(),
      _c("list", { attrs: { dialog_user_list: _vm.dialog_user_list } }),
      _c("selected"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }