<template>
  <div
    class="components_task_side_bar_teach_pages_make_titel_page bbox d-flex align-start justify-space-between"
    :class="[statusType]"
  >
    <div
      class="d-flex align-center"
    >
      <div class="title">教学内容{{edit?'制作':''}}</div>
      <!-- <div class="status_group">

      </div> -->
      <template
        v-if="showCheckStatus"
      >
        <div
          class="check_icon"
        ></div>
        <div

          class="wait_check"
        >
          {{ statusText }}
        </div>
        <el-popover
          placement="top"
          width="200"
          trigger="hover"
          :visible-arrow="false" popper-class="v_task_teaching_area_check_reason_popover_wrapper"
        >
          <div
            class="v_task_teaching_area_check_reason_popover"
          >
            <div
              v-if="task.task.checkstatus === 4"
              class="text"
            >
              未通过原因: {{ task.task.reason }}
            </div>
            <div
              v-if="0"
              class="text"
            >
              审核人: {{ task.task.checkUserName }}
            </div>

          </div>
          <template
            v-if="task.task.checkstatus === 4"
            slot="reference"
          >
            <div
              class="hint_icon"
            ></div>
          </template>

        </el-popover>
      </template>
    </div>

    <div class="fun_group d-flex align-center"
      v-if="edit"
    >
      <div class="menu cp"
        @click="doReturn();"
      ></div>
      <div class="close cp"
        @click="doClose();"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      task: state => state.task,
    }),
    statusType () {
      const {
        checkstatus
      } = this.task.task
      if (checkstatus === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return 'audit'
      }
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    statusText () {
      const {
        checkstatus
      } = this.task.task
      if (checkstatus === 1) {
        return '未审核'
      }
      if (checkstatus === 2) {
        return '待审核'
      }
      if (checkstatus === 3) {
        return '通过'
      }
      if (checkstatus === 4) {
        return '未通过'
      }
      return ''
    },
    showCheckStatus () {
      const auditMode = store.state.task_config.auditMode
      if (auditMode) {
        return false
      }
      const {
        task,
      } = this
      if (!task) {
        return false
      }
      if (!task.task) {
        return false
      }
      const {
        checkstatus,
        taskType,
      } = task.task
      if (taskType === 2) {
        if ((checkstatus === 2 || checkstatus === 3 || checkstatus === 4)) { // 通过 || 未通过
          return true
        }
      }
      return false
    }
  },
  methods: {
    doReturn(){
      this.$emit('handleChangePage', 'price')
    },
    doClose(){
      this.$emit('handleChangePage', 'start')
    },
  }
}
</script>


<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_titel_page{
  width: 100%;
  &.fail {
    .check_icon {
      background-image: url(~@/assets/images/course_package/select_fail.png);
    }
    .wait_check {
      color: #D82E0D;
    }
  }
  &.approve {
    .check_icon {
      background-image: url(~@/assets/images/course_package/select_approve.png);
    }
    .wait_check {
      color: #05AD00;
    }
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  .check_icon {
    @include background;
    width: 14px;
    height: 14px;
    background-image: url(~@/assets/images/course_package/select_audit.png);
    margin-right: 5px;
    margin-left: 10px;
    &.fail {

    }
  }
  .hint_icon {
    width: 16px;
    height: 16px;
    @include background;
    margin-left: 5px;
    background-image: url(~@/assets/images/course_package/question.png);
  }
  .wait_check {
    font-weight: bold;
    font-size: 14px;
    color: #F78900;
    text-align: left;
    font-style: normal;
    text-transform: uppercase;
  }
  .fun_group{
    height: 30px;
    background: #F4F4F4;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 4px;
    .menu{
      @include background(18px, 4px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/teach/menu.png);
      margin: {
        left: 6px;
        right: 16px;
      }
      position: relative;
      &::after{
        content: '';
        display: block;
        position: absolute;
        height: 14px;
        width: 1px;
        background-color: #d8d8d8;
        top:50%;
        right: -11px;
        transform: translateY(-50%);
      }
    }
    .close{
      @include background(10px, 10px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/teach/close.png);
    }
  }
}
</style>
