import { render, staticRenderFns } from "./Start.vue?vue&type=template&id=697b7560&scoped=true"
import script from "./Start.vue?vue&type=script&lang=js"
export * from "./Start.vue?vue&type=script&lang=js"
import style0 from "./Start.vue?vue&type=style&index=0&id=697b7560&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697b7560",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('697b7560')) {
      api.createRecord('697b7560', component.options)
    } else {
      api.reload('697b7560', component.options)
    }
    module.hot.accept("./Start.vue?vue&type=template&id=697b7560&scoped=true", function () {
      api.rerender('697b7560', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Task/SideBar/Teach/Pages/Start.vue"
export default component.exports