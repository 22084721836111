var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_pop_up_call_task_modules_package_list bbox" },
    [
      _c(
        "div",
        { staticClass: "list_group d-flex align-start flex-wrap bbox" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              _c("thumb-package", {
                key: index,
                staticClass: "mt-10 mr-10",
                attrs: { p_package: item, show_menu: false },
                on: { handleClick: _vm.handleClick },
              }),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "loadmore_group d-flex align-center justify-center bbox un_sel",
        },
        [
          !_vm.nomore
            ? _c(
                "div",
                {
                  staticClass: "loadmore cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.loadMore()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.loading ? "加载中" : "加载更多"))]
              )
            : _vm._e(),
          _vm.nomore
            ? _c("div", { staticClass: "nomore" }, [_vm._v("没有更多了")])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }