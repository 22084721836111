<!--
 * @Author       : Hugo
 * @Date         : 2021-09-29 14:21:06
 * @LastEditTime: 2022-05-17 11:25:31
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/PopUp/CallTask/Custom.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_call_task_custom_page d-flex flex-column"
  > 
    <template
      v-if="0"
    >
        
      <div class="path_group flex-shrink-0 bbox"
        v-if="status_breadcrumb"
      >
        <overlay-scrollbars
          class="overlay_container"
          ref="overlay"
        >
          <div class="path_overlay_group d-flex flex-align">
            <div class="text top cp"
              @click="goToTop();"
            >全部分组</div>
            <breadcrumb
              :data="breadcrumb"
              :folder_id="folder_id"
              :call_custom="true"
              :current="true"
              @handleChangeFolder="changeFolder"
            ></breadcrumb>
          </div>
          
        </overlay-scrollbars>
        
        
      </div>
      <views
        :call_custom="true"
        :folder_id="folder_id"
        ref="views"
        @handleChangeFolder="changeFolder"
        @handleCallTask="callTask"
      ></views>
    </template>
    <collect-main
      v-else
      :show_menu="false"
      :call="true"
      :top_jump="false"
    ></collect-main>
  </div>
</template>

<script>
import Views from '@/components/FunTemplate/Custom/View.vue';
import Breadcrumb from '@/components/FunTemplate/Custom/Breadcrumb.vue';
import CollectMain from '@/components/Collect/Main.vue'
import store from '@/store'
import tools from '@/api/tools.js'
export default {
  components: {
    Views,
    Breadcrumb,
    CollectMain,
  },
  data(){
    return {
      breadcrumb: {},
      folder_id: 0,
    }
  },
  computed:{
     // 面包屑导航
    status_breadcrumb(){
      const {folder_id} = this;
      if(folder_id === 0){
        return false;
      }
      return true;
    },
  },
  methods: {
    /**
     * @description: 获取面包屑导航
     * @param {*}
     * @return {*}
     */
    async getBreadcurmb(fId){
      // const {folderid} = this.$route.params;
      // let fId = 0;
      // if(folderid){
      //   fId = this.$global.decodeBase64(folderid);
      // }
      if(fId){
        let res = await this.$global.doPost({
          url: '/projectCustom/queryCustomFolderRoute',
          data: {fId},
          notice: '获取文件路径失败',
        })
        if(res.contents && res.contents.PFolderRoute){
          this.$set(this, 'breadcrumb', res.contents.PFolderRoute);
          this.$nextTick(function(){
            this.$refs.overlay.osInstance().scroll({x: '100%'});
          })
        }
      }
    },
    changeFolder(data){
      const {id} = data;
      this.folder_id = id;
      this.$nextTick(function(){
        this.getBreadcurmb(id);
        this.$refs.views.init();
      })
    },
    goToTop(){
      this.folder_id = 0;
      this.$nextTick(function(){
        this.getBreadcurmb(0);
        this.$refs.views.init();
      })
    },
    callTask(data){
      this.$api.moduleTask.callTask({taskId:data.id, config: {
        open_by_call: true,
      }})
      // this.$emit('handleCallTask', data);
      this.doClose();
    },
    doClose(){
      this.$emit('handleClose');
    }
  },
  mounted(){
    // this.$store.commit('set_task_config', Object.assign(
    //   {},
    //   this.$store.state.task_config,
    //   {call_custom:true},
    // ))
    store.commit('set_collect_nav', undefined)
    tools.removeCookie('collect_nav')
  },
}
</script>
<style lang="scss" scoped>
.components_pop_up_call_task_custom_page{
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  overflow: auto;

  .path_group{
    width: 100%;
    padding: 10px 20px;
  }
}
</style>
