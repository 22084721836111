<template>
  <div
    class="components_task_sidebar_page bbox d-flex flex-column flex-shrink-0"
    :class="{close: !switch_sidebar}"
    v-if="list && list.length"
  >
    <div class="open_group"
      v-if="!public_notice && !shopping_mail"
    >
      <div class="open button"
        v-show="!switch_sidebar"
        @click="changeSwitchSidebar()"
      ></div>
      <div class="close button"
        v-show="switch_sidebar"
        @click="changeSwitchSidebar()"
      ></div>
    </div>
    <fun
      v-if="!public_notice && !sell"
      class="fun_component border-bottom"
      :switch_sidebar="switch_sidebar"
      :list="active_list"
      @handleClick="changePannel"
    ></fun>
    <div class="pannel_group"
      v-show="switch_sidebar"
    >
      <widget-container
        v-show="type==='widget'"
        :editable="editable"
        :public_notice="public_notice"
        @handleAddNewWidget="addNewWidget"
      ></widget-container>
      <template
        v-if="!public_notice"
      >
        <chat-container
          :type="type"
          v-if="type==='chat'"
        ></chat-container>
        <record-container
          v-if="type==='record'"
        ></record-container>
        <teach-container
          v-show="type==='teach'"
          :can_teach="can_teach"
          :sell="sell"
          :show_intro="show_intro"
          :shopping_mail="shopping_mail"
          @handleSavaTask="handleSavaTask"
        ></teach-container>
      </template>
    </div>
  </div>
</template>
<script>
import Fun from './SideBar/Fun.vue'
import WidgetContainer from './SideBar/Widget/Container.vue'
import ChatContainer from './SideBar/Chat/Container.vue'
import RecordContainer from './SideBar/Record/Container.vue'
import TeachContainer from './SideBar/Teach/Container.vue'
import store from '@/store'
export default {
  components: {
    Fun,
    WidgetContainer,
    ChatContainer,
    RecordContainer,
    TeachContainer,
  },
  props: {
    type :{
      type: String,
      default: 'widget',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    public_notice: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_intro: {
      type: Boolean,
      default: true,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      origin_list: [
        {
          type: 'widget',
          name: '控件区',
        },
        {
          type: 'chat',
          name: '讨论区',
        },
        {
          type: 'record',
          name: '历史区',
        },
        {
          type: 'teach',
          name: '教学区',
        },
      ]
    }
  },

  computed: {
    auditMode () {
      return store.state.task_config.auditMode
    },
    list () {
      let list = this.$tools.deepClone(this.origin_list)
      const { system, recycle, sell, $route, auditMode } = this
      const { task_config, task } = store.state
      const { call, create, draft, editable, view, public_notice, task_type, price, share, use } = task_config
      const isBuy = task?.task?.buy ?? false

      if (auditMode) {
        list = [
          {
            type: 'teach',
            name: '教学区'
          }
        ]
        return list
      }

      if ($route.name === 'check_task') {
        list = [
          {
            type: 'teach',
            name: '教学区'
          }
        ]
        return list
      }
      if (share) {
        list = [ ]
        if ((task_type == 2 || task_type == 3)) {
          list.push({
            type: 'teach',
            name: '教学区'
          })
        }
        return list
      }
      if (sell) {
        list = [
          {
            type: 'teach',
            name: '教学区'
          }
        ]
        return list
      }
      if (call || create || draft) {
        list = [
          {
            type: 'widget',
            name: '控件区'
          },
          {
            type: 'teach',
            name: '教学区'
          }
        ]
      }
      if (!editable || view) {
        list = [
          {
            type: 'widget',
            name: '控件区'
          },
          {
            type: 'teach',
            name: '教学区'
          }
        ]
      }
      if (task_type == 2) {
        if (isBuy) {
          if (use) {
            list =  [
              {
                type: 'widget',
                name: '控件区'
              },
              {
                type: 'record',
                name: '历史区'
              },
              {
                type: 'teach',
                name: '教学区'
              }
            ]
          } else {
            list =  [
              {
                type: 'teach',
                name: '教学区'
              }
            ]
          }
        } else {
          list =  [
            {
              type: 'widget',
              name: '控件区'
            },
            {
              type: 'record',
              name: '历史区'
            },
            {
              type: 'teach',
              name: '教学区'
            }
          ]
        }
      }
      if (system) {
        list =  [
          {
            type: 'widget',
            name: '控件区'
          }
        ]
        if(price !== null){
          list.push({
            type: 'teach',
            name: '教学区'
          })
        }
      }
      if (recycle) {
        list =  [
          {
            type: 'widget',
            name: '控件区'
          },
        ]
      }
      return list
    },
    switch_sidebar () {
      const { task_config } = this.$store.state
      const { switch_sidebar } = task_config
      return switch_sidebar
    },
    active_list () {
      const list = this.$tools.deepClone(this.list)
      const { type } = this
      list.map((unit) => {
        if (unit.type == type) {
          unit.active = true
        }
        return unit
      })
      return list
    },
    can_teach () {
      const user = this.$tools.getCookie('user')
      if (!user) {
        return false
      }
      const {sign} = user
      if (sign === 1) {
        return true
      }
      return false
    }
  },
  watch: {
    switch_sidebar: {
      handler: function(){
        // setTimeout(() => {

          // this.$redrawVueMasonry();
        // }, 3000);
      }
    }
  },
  methods: {
    changeSwitchSidebar () {
      const {task_config} = this.$store.state
      this.$store.commit('set_task_config', Object.assign(
        {},
        task_config,
        {switch_sidebar: !this.switch_sidebar}
      ))
    },
    changePannel (type) {
      if (!this.switch_sidebar) {
        this.changeSwitchSidebar()
      }
      this.$emit('handleChangePannel', type)
    },
    addNewWidget () {
      this.$emit('handleAddNewWidget')
    },
    handleSavaTask () {
      this.$emit('handleSavaTask')
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_page{
  // @include transition;
  position: relative;
  z-index: 3;
  // height: 100%;
  width: 400px;
  background-color:#fff;
  &.close{
    width: 54px;
  }
  .open_group{
    position: absolute;
    top:50%;
    left: -19px;
    transform: translateY(-50%);
    .button{
      @include background;
      width: 19px;
      height: 48px;
      &.open{
        background-image: url(~@/assets/images/task/sidebar_open.png);
      }
      &.close{
        background-image: url(~@/assets/images/task/sidebar_close.png);
      }
    }
  }
  .fun_component{
    height: 50px;
  }
  .border-bottom{
    border-bottom: 1px solid #f4f4f4;
  }
  .pannel_group{
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
