<template>
  <div
    class="components_pop_up_call_task_correlation_list_page bbox d-flex flex-column"
    ref="wrapper"
  >
    <div class="list d-flex flex-wrap align-center"
      ref="list"
    >
      <template
        v-for="(item,index) in task_list"
      >
        <thumb-task
          class="mr-10 mb-12 pl-10"
          :key="index"
          :item="item"
          :menu="false"
          :show_from="type == 'craft' ? false : true"
          @handleClick="callTask"
        ></thumb-task>
      </template>

    </div>
    <div class="loadmore flex-shrink-0"
      v-if="loading"
    >
      <loading-icon
        size="20px"
      ></loading-icon>
    </div>
    <div class="nomore un_sel flex-shrink-0"
      v-if="nomore"
    >没有更多了</div>
  </div>
</template>
<script>
import ThumbTask from '@/components/Common/ThumbTask.vue';
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    ThumbTask,
    LoadingIcon,
  },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    keyword: {
      type: String,
      default: '',
    },
    appoint: {
      type: Object,
      default: null,
    },
    tags: {
      type: String,
      default: '',
    }
  },
  data(){
    return {
      task_list: [],
      scroll_top: -1,
      wrapper_heihgt: 0,
      loading: false,
      nomore: false,
    }
  },
  computed: {
    touch_bottom(){
      if(!this.$refs.wrapper){
        return false;
      }
      const {scroll_top} = this;
      const {clientHeight, scrollHeight} = this.$refs.wrapper;
      if(scroll_top == -1){
        return false;
      }
      if((scrollHeight - scroll_top - clientHeight) < 50){
        return true;
      }
      return false;
    }
  },
  watch:{
    scroll_top: {
      handler: async function(val){
        const {loading,nomore} = this;
        if(!this.$refs.wrapper){
          return false;
        }
        const {clientHeight, scrollHeight} = this.$refs.wrapper;
        if(val == -1){
          return false;
        }
        if((scrollHeight - val - clientHeight) < 50){
          if(!loading && !nomore){
            await this.getMyTaskList();
          }
          return true;
        }
        return false;
      },
    },
  },
  async mounted(){
    // this.getMyTaskList();
    await this.init();
    this.$nextTick(function(){
      if(!this.$refs.wrapper){
        return
      }
      this.$refs.wrapper.addEventListener('scroll', this.scrollEventListenerHandler, false);
      this.wrapper_heihgt = this.$refs.wrapper.clientHeight;
    })
  },
  methods:{
    handleChange(){
      this.clearData();
      this.$nextTick( async function(){
        await this.init();
        this.$nextTick(function(){
          if(!this.$refs.wrapper){
            return
          }
          this.$refs.wrapper.addEventListener('scroll', this.scrollEventListenerHandler, false);
          // this.wrapper_heihgt = this.$refs.wrapper.clientHeight;
        })
      })   
    },
    clearData(){
      this.scroll_top = -1;
      this.$refs.wrapper.removeEventListener('scroll', this.scrollEventListenerHandler);
      this.$set(this, 'task_list', []);
      this.nomore = false;
      this.loading = false;
      
    },
    async init () {
      const { type } = this
      // // console.log('init', type)
      if(type == 'default'){
        await this.getMyTaskList();
      }
      if(type == 'craft'){
        await this.getDraftList();
      }
      if(type == 'custom'){
        return ;
      }
      this.$nextTick(async function(){
        const {clientHeight} = this.$refs.wrapper;
        const listClientHeight = this.$refs.list.clientHeight;
        const {nomore} = this;
        // 高度没填满则自动加载更多
        if(!nomore &&( clientHeight > listClientHeight)){
          await this.init();
        }
      })
    },
    async getMyTaskList(){
      this.loading = true;
      const {keyword, task_list, appoint, tags} = this;
      const {state} = this.$store;
      const {task_config} = state;
      const {chat_id, chat_type} = task_config;
      let data = {
        chatId: chat_id,
        chatType: chat_type,
        isAll: 1,
        keyword,
        start: task_list.length,
        tagId: tags,
      }
      if(appoint !== null){
        data.chatId = appoint.chat_id;
        data.chatType = appoint.chat_type;
        data.isAll = 0;
      }
      console.log(data)
      const res = await this.$global.doPost({
        url: 'projectTask/mytaskList',
        data,
        openNoitce: false,
      })
      if(res.message == 'success'){
        this.$set(this, 'task_list', task_list.concat(res.contents.taskList));
        if(res.contents.taskList < 50){
          this.nomore = true;
        }
      }else{
        this.nomore=true;
      }
      this.loading = false;
      return;
    },
    async getDraftList(){
      this.loading = true;
      const {keyword, task_list} = this;
      let data = {
        keyword,
        start: task_list.length,
      }
      const res = await this.$global.doPost({
        url: '/projectDraft/draftList',
        data,
        openNoitce: false,
      })
      if(res.message == 'success'){
        this.$set(this, 'task_list', task_list.concat(res.contents.list));
        if(res.contents.list < 20){
          this.nomore = true;
        }
      }else{
        this.nomore=true;
      }
      this.loading = false;
      return ;
    },
    /**
     * 滚动监听函数
     */
    scrollEventListenerHandler(){
      this.scroll_top = this.$refs.wrapper.scrollTop;
    },
    callTask(data){
      this.$emit('handleCallTask', data);
    }
  }
}
</script>
<style lang="scss" scoped>
.components_pop_up_call_task_correlation_list_page{
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .list{
    width: 100%;
    .mr-10{
      margin-right: 10px;
    }
    .mb-12{
      margin-bottom: 12px;
    }
    .pl-10{
      padding-left: 10px;
    }
  }
  .loadmore{
    padding: 10px 0;
    height: 40px;
  }
  .nomore{
    width: 100%;
    padding: 10px 0;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #aaa;
  }
}
</style>
