<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 15:47:14
 * @LastEditTime : 2021-11-24 16:07:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    :max_width="`${300*column}px`"
    max_height="700px"
  >
    <div
      class="components_pop_up_select_contacts_index d-flex flex-column"
    >
      <common-title
        :title="title"
        close
        @handleClose="handleClose"
      ></common-title>
      <main-group
        :select_target="select_target"
        class="main"
        :dialog_user_list="dialog_user_list"
      ></main-group>
      <operation
        @handleCancel="handleClose"
      ></operation>
    </div>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper.vue';
import CommonTitle from '@/components/PopUp/Common/Title.vue';

import Operation from './Operation.vue';
import MainGroup from './Main.vue';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    MainGroup,
    Operation,
  },
  props: {
    title: {
      type: String,
      default: '选择联系人'
    },
    select_target: {
      type: Boolean,
      default: false,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    chat_id: {
      type: Number,
      default: null,
    },
    
    
  },
  computed: {
    column(){
      const {select_target} = this;
      if(select_target){
        return 3;
      }
      return 2;
    }
  },
  data(){
    return {
      dialog_user_list: [],
    }
  },
  methods: {
    handleClose(){
      this.$emit('handleClose');
    },
    async init(){
      const {select_target, chat_type, chat_id} = this;
      if(chat_type !== null && chat_id !== null){
        await this.getUserList();
      }

    },
    async getUserList(){
      const {chat_type, chat_id} = this;
      const res = await this.$api.dialog.getDialogUsers({chat_type, chat_id});
      if(res.list){
        this.$set(this, 'dialog_user_list', res.list);
      }
      // console.log(res);
      return false;
    }
  },
  mounted(){
    // console.log(this)
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_index{
  width: 100%;
  height: 100%;
  .main{
    flex: 1;
    width: 100%;
  }
}
</style>