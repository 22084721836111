<template>
  <div
    class="components_task_main_linebar_task_tag_page bbox d-flex align-center"
  >
  
    <div class="add_group cp flex-shrink-0 d-flex align-center"
      @click="startAdd();"
      v-show="!switch_input"
    >
      <div class="icon"></div>
      <div class="text"
        v-if="!list || (list && !list.length)"
      >添加自定义标签</div>
    </div>
    <div class="input_group flex-shrink-0"
      v-show="switch_input"
    >
      <input type="text" class="input bbox"
        ref="input"
        v-model="input_tag"
        @input="handleInput();"
        @focus="handleFocus();"
        @blur="handleBlur();"
        @keyup.enter="inputAddTag();"
      >
      <div class="placeholder bbox">{{input_tag}}</div>
    </div>
    <overlay-scrollbars
      class="overlay_container bbox d-flex align-center"
      ref="overlay"
    >
      <div class="list d-flex align-center">
        <template
          v-for="(item, index) in list"
        >
          <div class="tag flex-shrink-0"
            :key="index"
          >
            <div class="text">{{item.tagName}}</div>
            <div class="close cp"
              @click="removeTag(item.tagName);"
            ></div>
          </div>
        </template>
      
      </div>
    </overlay-scrollbars>
    
    <div class="tag_list_group bbox"
      :class="{active: switch_tag_list}"
    >
      <div class="tag_list bbox"
        @click="$refs.input.focus();"
      >
        <div class="title">选择已有标签</div>
        <div class="tag_group bbox d-flex align-center flex-wrap">
          <template
            v-for="(val,index) in tag_list"
          >
            <div
              :key="index"
              class="tag cp"
              :class="{on: val.selected}"
              @click="selectTagList(val)"
            >{{val.tagName}}</div>
          </template>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import tools from '@/api/tools'
import taskList from '@/api/modules/task_list';
export default {
  props: {
    tags: {
      type: Array,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    }
  },
  data(){
    return {
      input_tag: '',
      switch_input: false,
      switch_tag_list: false,
      timeout: null,
      list: [],
      tagList: [],
      remove_input: null,
      user: undefined,
    }
  },
  computed:{
    tag_list(){
      const {tagList, list} = this;
      let tag_list = this.$tools.deepClone(tagList);
      tag_list.forEach(unit=>{
        const isin = list.find(val=>{
          return val.tagName === unit.tagName;
        })
        if(isin){
          unit.selected = true;
        }
      })
      return tag_list;
    }
  },
  watch:{
    list: {
      handler: function(val){
        let addtags = '';
        if(val && val.length){
          val.forEach(unit=>{
            addtags += addtags ? `,${unit.tagName}`:unit.tagName;
          })
        }
        this.$store.commit('set_task_config', Object.assign(
          {},
          this.$store.state.task_config,
          {
            addtags,
            tags: val
          }
        ))
      },
      deep: true,
    }
  },
  methods: {
    switchTagList(value){
      this.switch_tag_list = value;
    },
    handleFocus(){
      this.switchTagList(true);
      this.getTagList();
      clearTimeout(this.remove_input);
      this.remove_input = null;

    },
    handleBlur(){
      this.switchTagList(false);
      // this.remove_input = setTimeout(()=>{
        this.switch_input = false;
        setTimeout(()=>{
          this.$refs.overlay.osInstance().scroll({x: '100%'});
        }, 50)
      // }, 3000)
    },
    handleInput(){
      // // console.log(this.input_tag);
      this.getTagList();
    },
    startAdd(){
      this.switch_input = true;
      this.$nextTick(function(){
        this.$refs.input.focus();
      })

    },
    inputAddTag(){
      const {input_tag, list, tagList} = this;
      const membership_privileges = this.$api.user.membershipPrivileges({notice: false});
      if(input_tag.trim() !== ''){
        // 如果已存在在list中 则不追加
        const tagName = input_tag.trim();
        const isin = list.find(unit=>{
          return unit.tagName == tagName;
        })
        // 判断是否是vip
        if(!membership_privileges){
          if(tagList && tagList.length){

            // 判断是否是已有标签
  
            const is_in_tag_list = tagList.find(item=>{
              return item.tagName === input_tag.trim()
            })
            if(!is_in_tag_list){
              this.$openNotMember()
              return
            }
          }else{
            this.$openNotMember()
            return
          }

        }



        if(!isin){
          this.list.push({tagName: input_tag.trim()});
        }

        this.input_tag = '';
        this.getTagList();
      }
      this.$nextTick(function(){
        this.$refs.overlay.osInstance().scroll({x: '100%'});
      })
    },
    async getTagList(){
      const {input_tag} = this;
      const keyword = input_tag;
      const res = await this.$global.doPost({
        url: '/projectTask/myTags',
        data: {keyword},
        openNoitce: false,
      })
      if(res && res.contents && res.contents.tagList){
        this.$set(this, 'tagList', res.contents.tagList);
      }
    },
    addTag(tag){
      const {list} = this;
      list.push(tag);
    },
    removeTag(tagName){
      const {list} = this;
      const index = list.findIndex( unit=>{
        return unit.tagName === tagName;
      })
      if(index !== -1){
        list.splice(index, 1);
      }
    },
    selectTagList(val){
      const {tagName, selected} = val;
      if(selected){
        this.removeTag(tagName);
        this.$set(val, 'selected', true);
      }else{
        this.addTag(val);
        this.$set(val, 'selected', false);
      }
      this.$refs.input.focus();
      this.$nextTick(function(){
        this.$refs.overlay.osInstance().scroll({x: '100%'});
      })
    }
  },
  mounted(){
    const {tags} = this;
    // // console.log(tags);
    if(tags && tags.length){
      this.$set(this, 'list', this.$tools.deepClone(tags));
    }

  }

}
</script>
<style lang="scss" scoped>
.components_task_main_linebar_task_tag_page{
  position: relative;
  background-color: #fff;
  width: 100%;
  .overlay_container{
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  .tag_list_group{
    @include transition;
    transition-delay: 0.5s;
    transform: scaleY(0);
    transform-origin: top center;
    padding: 0 20px;
    width: 100%;
    position: absolute;
    top: 60px;
    left:0;
    z-index: 4;
    &.active{
      transform: scaleY(1);
    }
    .title{
      font-size: 12px;
      color: #333333;
      line-height: 16px;
      padding-bottom: 12px;
    }
    .tag_group{
      @include scrollbar;
      overflow-y: auto;
      max-height: 38*3px;
      .tag{
        @include transition;
        padding: 0 12px;
        line-height: 28px;
        background: #E9E9E9;
        border-radius: 16px;
        font-size: 12px;
        color: #555555;
        margin: {
          right: 10px;
          bottom: 10px;
        }
        &.on{
          color: $main_blue;
          background-color: #E6EEF7;
        }
        &:hover{
          color: $main_blue;
          background-color: #B2CBE4;
        }
      }
    }
  }
  .tag_list{
    padding: 12px;
    width: 100%;
    // height: 118px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
   
  }
  .add_group{
    padding-left: 2px;
    padding-right: 10px;
    .icon{
      @include background(16px, 16px);
      width: 16px;
      height: 20px;
      background-image: url(~@/assets/images/task/add_tag.png);
    }
    .text{
      padding-left: 10px;
      font-size: 16px;
      color: #CCCCCC;
    }
  }
  .input_group{
    position: relative;
    height: 28px;
    margin-right: 10px;
    // margin-left: 10px;
    .input{
      width: 100%;
      position: absolute;
      top:0;
      left: 0;
      background-color: #E6EEF7;
      color: $main_blue;
      font-size: 12px;
      color: #0054A7;
      padding:  0 12px;
      line-height: 28px;
      border-radius: 14px;
      border:none;
      outline: none;
      z-index: 3;
    }
    .placeholder{
      position: relative;
      z-index: 2;
      opacity: 0;
      color: $main_blue;
      font-size: 12px;
      padding:  0 12px;
      line-height: 28px;
      // border-radius: 14px;
      width: 100%;
      min-width: 50px;
    }
  }
  .list{
    height: 48px;
  }
  .tag{
    position: relative;
    padding-right: 10px;
    &:last-child{
      padding-right: 0;
    }
    &:hover{
      .close{
        opacity: 1;
      }
    }
    .text{
      background-color: #E6EEF7;
      color: $main_blue;
      font-size: 12px;
      color: #0054A7;
      padding:  0 12px;
      line-height: 28px;
      border-radius: 14px;
    }
    .close{
      @include transition;
      opacity: 0;
      transition-delay: 0.3s;
      width: 16px;
      height: 16px;
      position: absolute;
      background-image: url(~@/assets/images/task/close_tag.png);
      z-index: 3;
      right: 3px;
      top: -4px;
    }
  }
}
</style>