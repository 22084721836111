<template>
  <div
    class="widget_copy_button cp"
  >
    <div
      class="content_group d-flex align-center justify-space-between"
    >
      <div
        class="icon flex-shrink-0"
        :class="[iconType]"
      ></div>
      <div
        class="name"
      >
        {{ name }}
      </div>
      <div
        class="fun_group flex-shrink-0 d-flex align-center"
      >
        <div
          class="delete"
          @click.stop="doDelete"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import tools from '@/api/tools'
const widgetDict = {
  1: 'text',
  2: 'number',
  3: 'money',
  4: 'time',
  6: 'file',
  7: 'hyperlink',
  9: 'picture',
  10: 'contact',
  11: 'wisdom'
}
export default {
  props: {
    pTaskDetail: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    name () {
      const { pTaskDetail } = this
      if (pTaskDetail) {
        const { remark } = pTaskDetail
        if ((remark ?? '') !== '') {
          return remark
        }
      }
      return '未命名控件'
    },
    iconType () {
      const { pTaskDetail } = this
      if (pTaskDetail) {
        const { type } = pTaskDetail
        return widgetDict[type]
      }
      return 'text'
    }
  },
  methods: {
    doDelete () {
      const index = store.state.copyWidgetList.findIndex((unit) => {
        return unit.id === this.pTaskDetail.id
      })
      if (index !== -1) {
        store.state.copyWidgetList.splice(index, 1)
        tools.setCookie(
          'copyWidgetData',
          {
            userId: tools.getUserId(),
            list: store.state.copyWidgetList
          }
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
.widget_copy_button {
  @include transition;
  padding: 10px 5px;
  border-radius: 4px;
  color: #333;
  margin-bottom: 10px;
  width: 300px;
  box-shadow: 0 0 10px 3px RGBA(0, 84, 167, 0.1);
  &:hover {
    .fun_group .delete {
      opacity: 1;
    }
  }
  .icon {
    @include background(18px, 18px);
    @include icon(text);
    @include icon(number);
    @include icon(money);
    @include icon(time);
    @include icon(file);
    @include icon(hyperlink);
    @include icon(picture);
    @include icon(contact);
    @include icon(wisdom);
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .name {
    @include single_line_intercept;
    font-size: 14px;
    flex: 1;
    text-align: left;
  }
  .fun_group {
    .delete {
      @include transition;
      @include background(14px, 14px);
      opacity: 0;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/delete.png);
    }
  }
}
</style>