<template>
  <div class="c_pop_up_call_task_modules_package_list bbox">

    <div class="list_group d-flex align-start flex-wrap bbox">
      <template
        v-for="(item,index) in list"
      >
        <thumb-package
          :key="index"
          :p_package="item"
          :show_menu="false"
          class="mt-10 mr-10"
          @handleClick="handleClick"
        ></thumb-package>
      </template>
    </div>
    <div class="loadmore_group d-flex align-center justify-center bbox un_sel">
      <div class="loadmore cp"
        @click.stop="loadMore()"
        v-if="!nomore"
      >{{loading ? '加载中' : '加载更多'}}</div>
      <div class="nomore"
        v-if="nomore"
      >没有更多了</div>
    </div>
  </div>
</template>

<script>
import ThumbPackage from '@/components/Common/Modules/Thumb/Package.vue'
import {listForClasses, PPackage} from '@/types'
import global from '@/api/global'
export default {
  components: {
    ThumbPackage
  },
  props: {
    task_call_config: {
      type: Object,
      default: undefined
    },
  },
  watch: {
    'task_call_config.search_type': function() {
      this.init()
    },
    'task_call_config.search_keyword': function() {
      this.init()
    }
  },
  data(){
    return {
      list: [],
      loading: false,
      nomore: false,
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    async init(){
      this.clearList()
      this.getList()
    },
    clearList(){
      this.loading = false
      this.nomore = false
      this.$set(this, 'list', [])
    },

    async getList(){
      const {task_call_config, list, loading} = this
      if(loading){
        return
      }
      const {search_type, search_keyword: keyword} = task_call_config
      const data = {
        keyword,
        startId: list.length ? list[list.length-1].id : ''
      }
      if(search_type === 'purchase'){
        data.type = 2
      }
      if(search_type === 'sell'){
        data.type = 1
      }
      this.loading = true
      const res = await global.doPost({
        url: '/projectTeach/queryPackage',
        data,
      })
      this.loading = false
      if(res.message === 'success'){
        const {contents} = res
        const {packageList} = contents
        // this.$set(this, 'list', listForClasses({
        //   list: packageList,
        //   classes: PPackage,
        // }))
        this.$set(this, 'list', [
          ...new Set([
            ...this.list,
            ... listForClasses({
              list: packageList,
              classes: PPackage,
            })
          ])]
        )
        if(!packageList || packageList.length < 20){
          this.nomore = true
        }
      }

    },
    async loadMore(){
      if(this.loading){
        return
      }
      this.getList()
    },
    handleClick(p_package){
      this.task_call_config.package_id = p_package.id
      this.task_call_config.p_package = p_package
    }
  }
}
</script>

<style lang="scss" scoped>
.c_pop_up_call_task_modules_package_list{
  width: 100%;
  padding: 0 10px;
  .loadmore_group{
    padding: 10px 0;
    font-size: 14px;
    color: $main_blue;
    .nomore{
      color: #999;
    }
  }
}
</style>