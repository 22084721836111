var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "components_pop_up_call_task_tag_page bbox d-flex align-end flex-shrink-0",
    },
    [
      _c(
        "div",
        {
          staticClass: "list d-flex align-center",
          class: { "flex-wrap": _vm.switch_more, all: _vm.switch_more },
        },
        [
          _c(
            "div",
            { staticClass: "tag flex-shrink-0", on: { click: _vm.clickAll } },
            [_vm._v("全部")]
          ),
          _vm._l(_vm.tag_list, function (item, index) {
            return [
              (
                _vm.switch_more
                  ? 1
                  : _vm.tag_max_num
                  ? index < _vm.tag_max_num - 3
                  : 1
              )
                ? _c(
                    "div",
                    {
                      key: index,
                      ref: "tag",
                      refInFor: true,
                      staticClass: "tag flex-shrink-0 cp",
                      class: { active: item.active },
                      on: {
                        click: function ($event) {
                          return _vm.changeTag(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.tagName))]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm.tag_max_num
        ? _c(
            "div",
            {
              staticClass: "button flex-shrink-0",
              on: {
                click: function ($event) {
                  _vm.switch_more = !_vm.switch_more
                },
              },
            },
            [_vm._v(_vm._s(_vm.switch_more ? "收起" : "更多..."))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }