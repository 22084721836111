var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c_common_mudoles_thumb_package bbox",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick()
        },
      },
    },
    [
      _c("div", { staticClass: "name_group d-flex align-center bbox" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.p_package.title)),
        ]),
        _vm.show_menu ? _c("div", { staticClass: "menu" }) : _vm._e(),
      ]),
      _c("img", {
        staticClass: "img mt-10",
        attrs: { src: `${_vm.$img_url}${_vm.p_package.webpic}`, alt: "" },
      }),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status_group mt-10" }, [
      _c("div", { staticClass: "status success" }, [_vm._v("通过")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }