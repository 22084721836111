var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_select_contacts_list bbox d-flex flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "div",
            { staticClass: "sticky_group" },
            [
              _c("search-group", {
                staticClass: "flex-shrink-0 bbox borderside_padding",
              }),
              _c("check-all", {
                staticClass:
                  "pt-16 pb-16 flex-shrink-0 borderside_padding bbox",
              }),
            ],
            1
          ),
          _vm._l(_vm.dialog_user_list, function (item) {
            return [
              _c("unit", {
                key: item.userId,
                staticClass: "py-10 user borderside_padding",
                attrs: {
                  avatar: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                  name: _vm.UserInfo(item).priorableName(),
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }