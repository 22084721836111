<!--
 * @Author       : Hugo
 * @Date         : 2021-03-08 11:25:48
 * @LastEditTime : 2022-01-13 10:16:37
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/dialog/Chat/Page.vue
 * @Description  : [模板]讨论区
 * @^_^          : 
-->
<template>
  <div class="dialog_chat_page">
    <div class="content_group">
      <div
        class="empty_group"
        v-if="!task_chat_message_list || task_chat_message_list.length == 0 && load_init_message_list"
      >
        <img src="~@/assets/images/common/none.png" alt="" class="empty_image">
        <div class="empty_text">暂无讨论内容</div>
      </div>
      <div
        class="message_group"
        v-else
      > 
        <div
          class="message_box"
          id="message_box"
          ref="message_box"
        > 
          <div class="no_more"
            v-if="no_more"
          >没有更多了</div>
          <div class="no_more"
            v-if="nomore_prev"
          >没有更多了</div>
          <div class="loading_more"
            v-show="loading_prev"
          >
            <div class="icon">
              <loading-icon size="17px"></loading-icon>  
            </div>
            <div class="text">加载中...</div>
          </div>
          <div class="loading_more"
            v-show="loading_more_status"
          >
            <div class="icon">
              <loading-icon size="17px"></loading-icon>  
            </div>
            <div class="text">加载中...</div>
          </div>
          <div class="msg_unit"
            v-for="(item,index) in task_chat_message_list"
            :key="item.id"
            ref="msg_unit"
          >
            <img
              :src="`${$avatar_url}${item.thumbnail}@!small200`"
              alt=""
              class="avatar"
            >
            <div class="msg_info">
              <div class="title_group">
                <div class="name">{{getUserName(item)}}</div>
                <div class="time">{{item.createTime | universalDate}}</div>
              </div>
              <div class="content" v-html="item.work"></div>
            </div>
          </div>
          <div class="loading_more"
            v-show="loading_next"
          >
            <div class="icon">
              <loading-icon size="17px"></loading-icon>  
            </div>
            <div class="text">加载中...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="edit_group">
      <img :src="`${$avatar_url}${user.thumbnail}@!small200`" alt="" class="avatar">
      <div class="input_group">
        <!-- <div class="editor"
          ref="editor"
        ></div> -->
        <el-input
          class="editor"
          type="textarea"
          :rows="3"
          :placeholder="editor_placeholder"
          ref="editor"
          resize="none"
          v-model="editor_content"
          @keydown.native="handleKeydown($event)"
          @input.native="handleKeyup($event)"
          @keydown.enter.native="doEnter($event);"
          @keydown.ctrl.enter.native="doEnterControl($event)"
          @keydown.shift.enter.native="doShiftEnter($event)"
        >
        </el-input>
        <div class="send_group">
          <button class="send button"
            @click="doSend();"
          >发布</button>
          
          <el-popover
            placement="top-end"
            trigger="click"
            v-model="switch_send_options"  
          >
            <div class="send_option_group">
              <div class="options"
                :class="{on: !send_type}"
                @click="send_type=0;switch_send_options=false;"
              >
                <div class="content">按Enter发布讨论内容</div>
                <div class="selected"></div>
              </div>
              <div class="options"
                :class="{on: send_type}"
                @click="send_type=1;switch_send_options=false;"
              >
                <div class="content">按Ctrl+Enter发布讨论内容</div>
                <div class="selected"></div>
              </div>
            </div>
            <div
              slot="reference"
              class="option_btn"></div>
          </el-popover>
        </div>
      </div>
    </div>
    <at-pop-up
      v-if="switch_at && 0"
      :chat_type="Number(chat_type)"
      :chat_id="Number(chat_id)"
      :task_id="Number(task_id)"
      @handleClose="closeAt"
      @handleSure="addAt"
      @handleAll="atAll"
      title="从任务参与人中选择@对象"
    ></at-pop-up>
  </div>
</template>

<script>

import LoadingIcon from '@/components/basic/loadingIcon.vue';
import { PTaskLog } from '@/types';
import AtPopUp from '@/components/PopUp/At/index.vue';
import {UserInfo} from '@/types';
import router from '@/router'
import tools from '@/api/tools'
import store from '@/store'
export default {
  components: {
    LoadingIcon,
    AtPopUp,
  },
  props: {
    task_id: {
      type: Number,
      default: null,
    },
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'widget'
    },
    chat_message_id: {
      type: Number,
      default: null,
    }
  },
  data(){
    return{
      user: this.$tools.getCookie('user'),
      send_type: 0,                               // 发送方式 0/1 enter/control-enter;
      editor_content: '',
      switch_send_options: false,
      load_init_message_list: false,                           // 是否加载过消息列表
      message_list: [],
      options: {
        container: '#message_box',      //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },
      init_scroll_bottom: false,   // 初始滚动到底部
      scrollTop: '',      // 监听滚动条距离顶部的距离
      loading_more_status: false,
      no_more: false,
      heartbeat_interval: false,
      nomore_next: false,
      nomore_prev: false,
      loading_next: false,
      loading_prev: false,
      switch_at: false,
      at_cursor_index: null,
      sending: false,   // 是否正在发送
    }
  },
  updated () {
    this.$refs.editor.focus();
  },
  computed: {
    editor_placeholder () {
      let status = '输入讨论内容，按Enter快速发布'
      const { send_type } = this
      if (send_type) {
        status = '输入讨论内容，按Ctrl+Enter快速发布'
      } else {
        status = '输入讨论内容，按Enter快速发布'
      }
      return status
    },
    task_chat_message_list () {
      return this.$store.state.task_chat_message_list
    },
    task_chat_trigger_scroll_to_bottom () {
      return this.$store.state.task_chat_trigger_scroll_to_bottom
    },
    task_chat_at_callback () {
      const { task_chat_at_callback } = this.$store.state
      return task_chat_at_callback
    }
  },
  watch: {
    task_chat_trigger_scroll_to_bottom: function (val) {
      if(val){
        this.$nextTick(function () {
          setTimeout(() => {
            this.scrollToEnd()
            this.$store.commit('set_task_chat_trigger_scroll_to_bottom', false)
          }, 300)
          
            
        })
      }
    },
    scrollTop: async function(val){
      const {loading_more_status, no_more} = this;
      if(val< 10){
        if(!loading_more_status && !no_more){
          this.loading_more_status = true;
          await this.loadMoreMessageList();
        }
      }
    },
    type: {
      handler: function(val){
        if(val == 'chat'){
          this.scrollToEnd();
        }

      }
    },
    '$store.state.task_pannel': function(val){
      const {task_unreader_chat,  my_task_list, task_data, task_chat_heartbeat_interval} = this.$store.state;
      
      const {id} = task_data;
      if(!id){
        return;
      }
      // console.log(task_unreader_chat, val);
      if(val == 'chat' && task_unreader_chat){
        this.scrollToEnd();
        this.$store.commit('set_task_unreader_chat', false);
        // 移除my_task_list的新消息提示
        
        const aim = my_task_list.find(item=>{
          return item.id == id;
        })
        if(aim){
          this.$set(aim, 'taskchatnum', 0);
        }
      }
      
      if(val == 'chat'){
        this.$store.commit('set_task_chat_trigger_scroll_to_bottom', true);
        // 开启心跳
        // this.heartbeatHandler();
      }else{
        // 关闭心跳定时
        clearInterval(task_chat_heartbeat_interval);
        // 移除心跳
        // this.$api.task.removeHeartbeat(id);
      }

    },
    editor_content: function(val){
      this.$store.commit('set_task_unsent_message', val === '' ? false : true);
    },
    task_chat_at_callback:{
      handler: function(val){
        if(val && val.atStr){
          this.addAt(val.atStr);
        }
      },
      deep:true,
    }
  },
  methods: {
    chineseHandler(e){
      let status = false;
      if(e.keyCode == 229){
        status = true;
      }
      return status;
    },
    /**
     * @description: Enter发送
     * @param {*} e
     * @return {*}
     * 
     * 如果是中文状态 回车不发送 do nothing
     */
    async doEnter(e){
      // // console.log(e);
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      const {send_type} = this;
      // enter发送 && 没按ctrl && 没按shift
      if(!send_type && !e.ctrlKey && !e.shiftKey){
        e.preventDefault();
        this.doSend();
      }
      // this.$refs.editor.blur();
      // return false;
    },
    doShiftEnter(e){
      // // console.log(e);
    },
    /**
     * @description: Ctrl+Enter发送
     * @param {*} e
     * @return {*}
     */
    async doEnterControl(e){
      const {send_type} = this;
      if(send_type){
        e.preventDefault();
        this.doSend();
      }else{
        this.editor_content += '\n';
      }
    },
    /**
     * @description: 发送消息
     * @param {*}
     * @return {*}
     */
    async doSend(){

      const { task_config } = this.$store.state
      const { editor_content, user, sending } = this
      if (sending) {
        return false
      }
      this.sending = true
      const { task_id, chat_id, chat_type, hide, task_name, from, users } = task_config
      let data = {
        taskId: task_id,
        content: editor_content,
        chat_type: chat_type,
        chat_id: chat_id
      }
      const linkusers = await this.$api.at.getSendUsersList({task_id,content: editor_content,chat_type,chat_id})
      if (linkusers) {
        data.linkusers = linkusers
      }
      // 单聊发送对方id
      if (Number(chat_type) === 0) {
        const userid = this.$tools.getUserId()
        const opposite = users.find((item) => {
          return Number(item.userId) !== Number(userid)
        })
        if (opposite) {
          data.chat_id = opposite.userId
        }
      }
      this.$global.preventDuplicateClicks(async () => {
        let res
        try{
          res = await this.$http.request('/projectNews/sendTaskChat', data)
          this.sending = false
          if (res.message === 'success') {
            const date = new Date()
            this.editor_content = ''
            const message_unit = {
              thumbnail: user.thumbnail,
              work: data.content,
              cretateTime: date,
              userName: user.userName
            }
            this.addToMessageList(new PTaskLog(res.contents.PTaskLog))
            // 如果返回的chatId不同并且是chatType === 1 则触发迁移提醒

            const { contents } = res
            if (Number(data.chat_type) === 1) {
              if (Number(contents.PTaskLog.chatId) !== Number(data.chat_id)) {
                this.$notice({
                  desc: '该任务已迁移至其他工作组！'
                })
              }
            }
            this.$api.chat.pushChatAtMessageList(res.contents.PTaskLog)
            this.$api.chat.pushChatAtMessageBoxList({
              chatId: res.contents.PTaskLog.chatId ?? data.chat_id,
              chatType: chat_type,
              lastSendTime: res.contents.PTaskLog.createTime,
              messageContent: `[${data.content}]`,
              name: from,
              // photo: 
              updateusername: user.userName,
              updateUserId: user.userId
            })
          }
        }catch(err){
          console.log(err)
        }
      })
    },
    /**
     * @description: 获取列表
     * @param {*}
     * @return {*}
     */
    async getTaskMessageList(){
      this.$store.commit('set_task_chat_message_list', []);
      const {task_data, my_task_list, task_config} = this.$store.state;
      const {id} = task_data;
      const {task_id} = task_config;
      if(!task_id){
        return;
      }
      let res;
      let data = {
        taskId: task_id,
        startId: '',
      }
      try{
        res = await this.$http.request('/projectNews/taskMessageList', data);
        if(res.message == 'success'){
          this.load_init_message_list = true;
          const {taskMessageList} = res.contents;
          // this.$set(this, 'message_list', taskMessageList);
          if(taskMessageList && taskMessageList.length){
            this.$store.commit('set_task_chat_message_list', taskMessageList.reverse());
          }
          this.isNoMore(taskMessageList);
          
          this.$nextTick(function(){
            // //  200ms后开启监听
            if(this.$refs.message_box){
              setTimeout(() => {
                this.$refs.message_box.addEventListener('scroll', this.messageBoxScrollListener, false);
              }, 200);
            }
          })
          // this.removeMyTaskListChatSign({taskId: id});


          this.scrollToEnd();
          // 移除my_task_list的新消息提示
          this.$store.commit('set_task_unreader_chat', false);
          if(my_task_list){
            const aim = my_task_list.find(item=>{
              return item.id == id;
            })
            if(aim){
              this.$set(aim, 'taskchatnum', 0);
            }
          }
        }
      }catch(err){
        // console.log(err);
      }
    },
    /**
     * @description: 是否没有更多了
     * @param {*}
     * @return {*}
     */
    isNoMore(list){
      if(!list || list.length < 20){
        this.no_more = true;
      }
    },
    /**
     * @description: 监听消息盒子
     * @param {*}
     * @return {*}
     */
    messageBoxScrollListener(){
      this.scrollTop = this.$refs.message_box.scrollTop;
    },

    /**
     * @description: 加载更多消息
     * @param {*}
     * @return {*}
     */
    async loadMoreMessageList(){
      // console.log('111');
      const { task_chat_message_list, task_data, task_config} = this.$store.state;
      // let {task_chat_message_list} = this.$store.state;
      const {id} = task_data;
      const {task_id} = task_config;
      if(!task_id){
        return;
      }
      let res;
      let data = {
        taskId: task_id,
        startId: task_chat_message_list[0].id,
      }
      try{
        res = await this.$http.request('/projectNews/taskMessageList', data);
        const {taskMessageList} = res.contents;
        if(taskMessageList && taskMessageList.length){
          let new_list = taskMessageList.reverse().concat(task_chat_message_list);
          this.$store.commit('set_task_chat_message_list', new_list);
        }
        this.isNoMore(taskMessageList);

        //跳转到追加前的位置 (-1 向上移动一位)
        this.$nextTick(function(){
          let element = this.$refs.msg_unit[task_chat_message_list.length - 2];
          this.$scrollTo(element,1, this.options);
        })
      }catch(err){
        // console.log(err);
      }
      this.loading_more_status = false;
    },
    /**
     * @description: 滚动到底部
     * @param {*}
     * @return {*}
     */
    scrollToEnd(){
      const {msg_unit} = this.$refs;
      if(msg_unit && msg_unit.length != 0){
        let element = msg_unit[msg_unit.length - 1];
        this.$scrollTo(element, 1, this.options);
      }
    },
    async init(){
      const {chat_message_id} = this;
      if(chat_message_id){
        if(this.$store.state.chat_context_data){
          this.$store.commit('set_task_chat_message_list', [
            ...new Set([
              ...[this.$store.state.chat_context_data],
            ])
          ])
        }
        await this.getNextList();
      }else{
        await this.getTaskMessageList();
      }
      if(chat_message_id){
        // this.$refs.message_box.addEventListener('wheel', (e)=>{
        //   // console.log(e);
        // }, false);
        this.$refs.message_box.addEventListener(
          'wheel',
          this.handelWheelContext,
          false,
        )
        return ;
      }
      this.$nextTick(function(){
        this.$store.commit('set_task_chat_trigger_scroll_to_bottom', true);
      })
      // this.heartbeatHandler();
    },
    /**
     * @description: 追加到消息列表
     * @param {Object} params {content,createTime,username, userthumbnail}
     * @return {*}
     */
    addToMessageList(params){
      const {content,createTime,username, userthumbnail} = params
      const {task_chat_message_list} = this.$store.state;
      task_chat_message_list.push(params);
      this.$nextTick(function(){
        this.$store.commit('set_task_chat_trigger_scroll_to_bottom', true);
      })
    },
    /**
     * @description: 心跳接口
     * @param {*}
     * @return {*}
     */
    async heartbeat(){
      const {task_data} = this.$store.state;
      const {id} = task_data;
      let res;
      if(id){
        try{
          res = await this.$http.request('/projectNews/taskMessageheartbeat', {taskId: id});
        }catch(err){
          // console.log(err);
        }
      }
    },
    /**
     * @description: 调用心跳
     * @param {*}
     * @return {*}
     */
    heartbeatHandler(){
      const {task_data} = this.$store.state;
      const {id} = task_data;
      if(id){
        this.$store.commit('set_task_chat_heartbeat_status', true);
        // // console.log(id);
        this.heartbeat();
        this.heartbeat_interval = setInterval(()=>{
          this.heartbeat();
        },5000);
        this.$store.commit('set_task_chat_heartbeat_interval', this.heartbeat_interval);
      }
    },
    getUserName(item){
      // // console.log(this.$store.state.dialog_user_list);
      if(item == undefined){
        return '';
      }
      const {dialog_user_list} = this.$store.state;
      // // console.log('--------');
      // // console.log(item);
      // // console.log(dialog_user_list);
      if(dialog_user_list && dialog_user_list.length){
        const target = dialog_user_list.find(unit => {
          return unit.userId == item.fromUserId;
        })
        // // console.log(target);
        if(target){
          return new UserInfo(target).priorableName();
        }
        // if(target){
        //   if(target.friendRemark){
        //     return target.friendRemark;
        //   }
        //   if(target.chatUserName){
        //     return target.chatUserName;
        //   }
        
        //   return target.userName;
        // }
        

      }
      return item.userName;
      
    },
    handelWheelContext(e){
      const { wheelDelta } = e;
      const {scrollTop, scrollHeight, clientHeight} = this.$refs.message_box;
      // // console.log(scrollTop, scrollHeight, clientHeight);
      const top = scrollTop ;
      const bottom = scrollHeight - scrollTop - clientHeight;
      // 向上滚动 距离顶部少于200;
      if(wheelDelta > 0 && top < 200){
        this.getPrevList({bottom});
      }
      // 乡下滚动 距离底部小于200;
      if(wheelDelta < 0 && bottom < 200){
        this.getNextList();
      }
    },
    async getNextList(){
      const {task_chat_message_list, nomore_next, loading_next} = this;
      if(!task_chat_message_list || task_chat_message_list.length == 0){
        return;
      }
      if(nomore_next || loading_next){
        return true;
      }
      const start_id = task_chat_message_list[task_chat_message_list.length-1].id;
      this.loading_next = true;
      const { task_id, chat_id, chat_type} = this;
      const res = await this.$api.search.taskNextContext({
        chat_type,
        chat_id,
        task_id,
        start_id,
      });
      // console.log(res);
      if(!res){
        return ;
      }
      const {message, contents} = res;
      const {tasklogList} = contents;
      if(tasklogList.length < 20){
        this.nomore_next = true;
      }
      if(tasklogList.length){
        this.$store.commit('set_task_chat_message_list', [
          ...new Set([
            ...this.$store.state.task_chat_message_list,
            ...tasklogList,
          ])
        ])
      }
      this.loading_next = false;

    },
    async getPrevList(params){
      const {bottom} = params || {};
      const {task_chat_message_list, nomore_prev, loading_prev,} = this;
      if(!task_chat_message_list || task_chat_message_list.length == 0){
        return;
      }
      if(nomore_prev || loading_prev){
        return ;
      }
      const start_id = task_chat_message_list[0].id;
      this.loading_prev = true;
      const { task_id, chat_id, chat_type} = this;
      const res = await this.$api.search.taskPrevContext({
        chat_type,
        chat_id,
        task_id,
        start_id,
      });
      // console.log(res);
      if(!res){
        return ;
      }
      const {message, contents} = res;
      const {tasklogList} = contents;
      if(tasklogList.length < 20){
        this.nomore_prev = true;
      }
      if(tasklogList.length){
        this.$store.commit('set_task_chat_message_list', [
          ...new Set([
            ...tasklogList.reverse(),
            ...this.$store.state.task_chat_message_list,
          ])
        ])
      }
      // 获取之前的bottom 
      this.$nextTick(function(){
        if(bottom){
          const {scrollTop, scrollHeight, clientHeight} = this.$refs.message_box;
          // this.scrollTop = this.$refs.scrollBox.scrollTop;
          const after_bottom = scrollHeight - scrollTop - clientHeight;
          // console.log({bottom,after_bottom})
          this.$refs.message_box.scrollTop = after_bottom - bottom;
        }
      })
      this.loading_prev = false;
    },
    closeAt(){
      this.switch_at = false
    },
    handleKeyup(e){
      const chat_type = Number(this.chat_type);
      if(chat_type === 0){
        return;
      }
      const {data} = e;
      if(!data || !data.length){
        return;
      }
      const at = data[data.length - 1] === '@';
      // const at = this.$api.input.atListener(e);
      // const is_del = this.$api.input.deleteListener(e);
      const cursor_index = this.$refs.editor.$el.firstChild.selectionStart;
      if(at){
        // this.switch_at = true;
        const { task_id, chat_id } = this
        // this.$store.commit('set_task_chat_at_data', Object.assign({},{switch_at:true}))
        this.$openAt({
          title: '从任务参与人中选择@对象',
          task_id: Number(task_id),
          chat_id: Number(chat_id),
          chat_type: Number(chat_type),
          handleSure: this.handleAddAt,
          handleAll: this.handleAtAll
        })
        this.$nextTick(function(){
          setTimeout(()=>{
            this.$refs.editor.blur();
            // 获取光标索引
            if(cursor_index){
              this.at_cursor_index = cursor_index;
            }
            // 中文@blur两次
            setTimeout(()=>{
              this.$refs.editor.blur();
            })
          }, 50);
        })
      }


     
      // console. log(this.$refs.editor.$el.firstChild.selectionStart);
   
      // if(is_del){
      //   const str = this.editor_content;
      //   // console.log(str);
      //   const last_char = this.editor_content.substring(cursor_index-1, cursor_index);
      //   // console.log({last_char});
      //   if(last_char === ' '){
      //     const new_str = this.deleteAt({str: this.editor_content, cursor_index});
      //     this.$nextTick(function(){
      //       if(new_str){
      //         this.editor_content = new_str;
      //       }
      //     })
      //   }
      // }
    },
    handleKeydown(e){
      const is_del = this.$api.input.deleteListener(e);
      const cursor_index = this.$refs.editor.$el.firstChild.selectionStart;
   
      if(is_del){
        const str = this.editor_content;
        const last_char = this.editor_content.substring(cursor_index-1, cursor_index);
        if(last_char === ' '){
          const res = this.deleteAt({str: this.editor_content, cursor_index});
          this.$nextTick(function(){
            if(res){
              setTimeout(()=>{
                const {new_str, new_cursor_index} = res;
                this.editor_content = new_str;
                this.$nextTick(function(){
                  // 设置新光标位置
                  this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
                  this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
                })
              },50)
            }
          })
        }
      }
    },
    deleteAt(params={}){
      const {str, cursor_index} = params;
      // const start_str = str.substring(0, cursor_index);
      // const end_str = str.substring(cursor_index);
      const has_at = str.lastIndexOf('@', cursor_index);
      if(has_at !== -1){
        const new_str = str.substring(0, has_at) + str.substring(cursor_index);
        const new_cursor_index = has_at;
        return {new_str, new_cursor_index};
      }
      return false;

    },
    // addAt(item){
    //   const atStr = this.$api.at.atStr(item.priorableName());
    //   const {at_cursor_index, editor_content} = this;
    //   // if(at_cursor_index !== null){
    //   //   if(at_cursor_index != editor_content.length){

    //   //   }
    //   // }
    //   const start_str = editor_content.substring(0, at_cursor_index);
    //   const end_str = editor_content.substring(at_cursor_index);
    //   const new_str = `${start_str}${atStr}${end_str}`;
    //   const new_cursor_index = `${start_str}${atStr}`.length;
    //   this.closeAt();
    //   this.$nextTick(function(){
    //     this.editor_content = new_str;
    //     setTimeout(()=>{
    //       // 设置新光标位置
    //       this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
    //       this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
    //     },50)
    //   })
      
    // },
    // atAll(){
    //   const atStr = this.$api.at.atAll();
    //   const {at_cursor_index, editor_content} = this;
    //   const start_str = editor_content.substring(0, at_cursor_index);
    //   const end_str = editor_content.substring(at_cursor_index);
    //   const new_str = `${start_str}${atStr}${end_str}`;
    //   const new_cursor_index = `${start_str}${atStr}`.length;
    //   this.closeAt();
    //   this.$nextTick(function(){
    //     this.editor_content = new_str;
    //     setTimeout(()=>{
    //       // 设置新光标位置
    //       this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
    //       this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
    //     },50)
    //   })
    // },
    addAt(atStr){
      // const atStr = this.$api.at.atStr(item.priorableName());
      const {at_cursor_index, editor_content} = this;
      const start_str = editor_content.substring(0, at_cursor_index);
      const end_str = editor_content.substring(at_cursor_index);
      const new_str = `${start_str}${atStr}${end_str}`;
      const new_cursor_index = `${start_str}${atStr}`.length;
      // this.closeAt();
      this.$nextTick(function(){
        this.editor_content = new_str;
        setTimeout(()=>{
          // 设置新光标位置
          this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
          this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
        },50)
      })
      
    },
    handleAddAt(item) {
      const atStr = this.$api.at.atStr(item.atName())
      this.$store.commit('set_task_chat_at_callback', Object.assign(
        {},
        {atStr}
      ))
      this.$closeAt()
    },
    handleAtAll() {
      const atStr = this.$api.at.atAll()
      this.$store.commit('set_task_chat_at_callback', Object.assign(
        {},
        {atStr}
      ))
      this.$closeAt()
    }

  },
  async mounted(){
    const {task_chat_message_list,} = this;
    if(!task_chat_message_list || !task_chat_message_list.length){
      await this.init();
     
    }
    // this.getAppointMessageList();
  },
 
  
}
</script>

<style lang="scss" scoped>
.dialog_chat_page{
  @include flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.content_group{
  @include bbox;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.empty_group{
  @include flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  .empty_text{
    padding-top: 40px;
    font-size: 16px;
    color: #666666;
  }
}
.message_group{
  @include bbox;
  @include flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 100%;
  padding: 40px 0;
  .message_box{
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  .no_more{
    text-align: center;
    font-size: 12px;
    padding: 0 0 20px;
    color: #ccc;
  }
  .loading_more{
    @include flex;
    width: 100%;
    justify-content: center;
    padding: 0 0 20px;
    .icon{
      width: 17px;
      height: 17px;
    }
    .text{
      font-size: 13px;
      line-height: 17px;
      padding-left: 8px;
      color: #ccc;
    }
  }
  .msg_unit{
    @include bbox;
    @include flex;
    width: 100%;
    padding: 0 20px 30px;
    align-items: flex-start;
    .avatar{
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .msg_info{
      @include flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      .title_group{
        @include flex;
        width: 100%;
        .name{
          font-size: 12px;
          padding-right: 13px;
          color: $main_blue;
        }
        .time{
          font-size: 12px;
          color: #82929C;
        }
      }
      .content{
        @include text_justify;
        white-space: pre-wrap;
        text-align-last: left;
        font-size: 14px;
        line-height: 20px;
        padding-top: 10px;
        color: #666;
        width: 100%;
      }
    }
  }
}
.edit_group{
  @include flex;
  @include bbox;
  flex-shrink: 0;
  width: 100%;
  border-top: 2px solid #f4f4f4;
  padding: 20px;
  align-items: flex-start;
  overflow: hidden;
  .avatar{
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 12px;
  }
  .input_group{
    @include bbox;
    @include flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    height: 120px;
    background: #F8F8F8;
    border-radius: 0px 4px 4px 4px;
    border-radius: 4px;
    // position: absolute;
    overflow: hidden;
    padding: 4px 0;
    .editor{
      width: 100%;
      // height: 70px;
      background-color: transparent;
      font-size: 14px;
    }
    .send_group{
      @include flex;
      justify-content: flex-end;
      width: 100%;
      .send{
        font-size: 14px;
        color: $main_blue;
        cursor: pointer;
      }
      .option_btn{
        @include background(10px, 8px);
        cursor: pointer;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background: {
          image: url(~@/assets/images/dialog/send_group_option.png);
        }
      }
    }
  }
}
.send_option_group{
  .options{
    @include transition;
    @include flex;
    cursor: pointer;
    &.on{
      .content{
        color: $main_blue;
      }
      .selected{
        background-image:url(~@/assets/images/dialog/send_options_selected.png);
      }
    }
    .content{
      font-size: 14px;
      line-height: 28px;
      color: #666;
      padding-right: 12px;
    }
    .selected{
      @include background(14px,14px);
      width: 20px;
      height: 20px;
    }
  }
}
</style>
<style  scoped> 
/* 注意不能用scss，使用scss不能识别到>>>,因而会不起作用 */
.editor >>> .w-e-toolbar,
.editor >>> .w-e-text-container{
  background-color: transparent!important;
  border: none!important;
}
.editor >>> .w-e-text p,
.editor >>> .w-e-text h1,
.editor >>> .w-e-text h2,
.editor >>> .w-e-text h3,
.editor >>> .w-e-text h4,
.editor >>> .w-e-text h5,
.editor >>> .w-e-text table,
.editor >>> .w-e-text pre{
  margin: 4px 0;
}
.editor >>> .w-e-text-container .placeholder{
  top: 4px;
  font-size: 14px;
  width: 100%;
  padding-right: 20px;
  text-align: justify;
  text-justify: inter-ideograph;
  text-align-last: left;
}
.editor >>> .w-e-toolbar p,
.editor >>> .w-e-text-container p,
.editor >>> .w-e-menu-panel p{
  font-size: 14px!important;
}
/* element textarea 样式调整 */
.editor >>> .el-textarea__inner{
  font-family: 'MicroSoft YaHei';
  border: none;
  background-color: transparent;
}
</style>
