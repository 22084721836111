var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "components_pop_up_call_task_correlation_list_page bbox d-flex flex-column",
    },
    [
      _c(
        "div",
        { ref: "list", staticClass: "list d-flex flex-wrap align-center" },
        [
          _vm._l(_vm.task_list, function (item, index) {
            return [
              _c("thumb-task", {
                key: index,
                staticClass: "mr-10 mb-12 pl-10",
                attrs: {
                  item: item,
                  menu: false,
                  show_from: _vm.type == "craft" ? false : true,
                },
                on: { handleClick: _vm.callTask },
              }),
            ]
          }),
        ],
        2
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loadmore flex-shrink-0" },
            [_c("loading-icon", { attrs: { size: "20px" } })],
            1
          )
        : _vm._e(),
      _vm.nomore
        ? _c("div", { staticClass: "nomore un_sel flex-shrink-0" }, [
            _vm._v("没有更多了"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }