<!--
 * @Author       : Hugo
 * @Date         : 2020-07-29 10:44:01
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-22 12:32:35
 * @Description  : 选择所有联系人
 * @FilePath     : /miaohang/src/components/FunTemplate/SelectContactContent.vue
--> 
<template>
  <div class="select_contact_content_wrapper">
    <div class="title_group">
      <div class="title">选择联系人</div>
      <div class="close cp" @click="closeSelectContact"></div>
    </div>
    <div class="content_group">
      <!-- 带选择列表 -->
      <div
        class="select_list_group list_group"
        :class="{cantselect: !optional, disabled: !optional}"
      >
        <div class="group_wrapper">
          <div class="search_group">
            <div class="search_box">
              <div class="icon"></div>
              <input type="text" class="search" placeholder="搜索" 
                v-model="list_keyword"
              />
              <div class="clear" 
                v-show="list_keyword"
                @click.stop="list_keyword = ''"
              ></div>
            </div>
          </div>
          <!-- 最近 -->
          <template v-if="message_box_list">
            <div class="hint">最近...</div>
            <template
              v-for="(item, index) in message_box_list"
            >
              <thumb-unit
                v-if="devSign && index < 10 && Number(item.chatId) !== 5494 && Number(item.chatType) !== 0 "
                :key="`messageBoxList${index}`"
                :item="item"
                :search="list_keyword"
                :class="{on: Number(item.chatId) === Number(select_project_id), disabled: !optional}"
                @click.native.stop="messageBoxListHandler(item)"
              ></thumb-unit>
              <div
                v-if="!devSign && index<10 && item.chatId != 5494 && item.chatType != 0 "
                class="select_list_item select_item cp"
                :class="{on: item.chatId == select_project_id, disabled: !optional}"
                :key="`${item.id || item.chatId}select_list_item`"
                @click="messageBoxListHandler(item)"
              >
                <div class="name_group">
                  <!-- 单聊 -->
                  <template v-if="item.chatType == '0' && (!list_keyword || ( list_keyword && item.friendRemark.indexOf(list_keyword) !== -1))">
                    <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt class="avatar my-10"  />
                    <div class="name">{{item.friendRemark}}</div>
                  </template>

                  <!-- 工作组 -->
                  <template v-if="item.chatType == '1'  && (!list_keyword || ( list_keyword && ( item.name.indexOf(list_keyword.toLowerCase()) !== -1) || (item.name.indexOf(list_keyword.toUpperCase()) !== -1)))">
                    <!-- <basic-work-team-avatar :userlist="item.pictures"></basic-work-team-avatar> -->
                    <img :src="`${$avatar_url}${item.photo}@!small200`" alt class="avatar my-10"  />
                    <div class="name">{{item.name}}</div>
                  </template>
                </div>
              </div>
            </template>
          </template>
          <!-- 联系人 -->

          <div class="hint">联系人</div>
          <template v-for="(item, index) in friends_list_group">
            <div class="hint"
              v-if="0"
              :key="`${index}friends_list_group`">
              {{index}}
            </div>

            <template
              v-for="(subval, subindex) in item"
            >
              <thumb-unit
                v-if="devSign"
                :key="`${index}-${subindex}`"
                :item="subval"
                :search="list_keyword"
                type="contact"
                :class="{on: subval.userId == select_project_id}"
                @click.native.stop="clickFriendList(subval)"
              ></thumb-unit>
              <div
                v-if="!devSign"
                class="select_list_item select_item"
                :class="{on: subval.userId == select_project_id}"
                :key="`${subval.userId}friends_list_groupsubindex`"
              >
                <div
                  class="name_group"
                  @click="clickFriendList(subval)"
                  v-if="!list_keyword || (list_keyword && (subval.friendRemark.indexOf(list_keyword.toLowerCase()) !== -1 || subval.friendRemark.indexOf(list_keyword.toUpperCase()) !== -1 || subval.userName.indexOf(list_keyword.toLowerCase()) !== -1 || subval.userName.indexOf(list_keyword.toLowerCase()) !== -1))"
                >
                  <img :src="`${$avatar_url}${subval.thumbnail}@!small200`" alt class="avatar my-10" />
                  <div class="name">{{list_keyword ? (subval.friendRemark.indexOf(list_keyword.toLowerCase()) !== -1 || subval.friendRemark.indexOf(list_keyword.toUpperCase()) !== -1 ? subval.friendRemark : subval.userName) : (subval.friendRemark || subval.userName)}}</div>
                </div>
              </div>
            </template>
          </template>
          <!-- 工作组 -->
          <div class="hint">工作组</div>
          <template
            v-if="devSign"
          >
            <template
              v-for="(item, index) in workteam_list"
            >
              <thumb-unit
                :key="index"
                :item="item"
                type="workteam"
                :search="list_keyword"
                :class="{on: Number(item.chatId) === Number(select_project_id) }"
                @click.native.stop="clickWorkteamList(item)"
              ></thumb-unit>
            </template>
          </template>
          <template
            v-if="!devSign"
          >
            <div
              class="select_list_item select_item"
              v-for="(item, index) in workteam_list"
              :key="`workteam${item.chatId}${index}`"
              :class="{on: item.chatId == select_project_id}"
              @click="clickWorkteamList(item)"
            >
              <div class="name_group"
                v-if="!list_keyword || (list_keyword && (item.chatName.indexOf(list_keyword.toLowerCase()) !== -1 || item.chatName.indexOf(list_keyword.toUpperCase()) !== -1 ))"
              >
                <!-- <basic-work-team-avatar :userlist="item.pictures"></basic-work-team-avatar> -->
                <img :src="`${$avatar_url}${item.photo}@!small200`" alt class="avatar my-10" />
                <div class="name">{{item.chatName}}</div>
              </div>
            </div>
          </template>
        </div>
        
      </div>
      <!-- 已选项目 -->
      <div class="selected_project_group list_group">
        <!-- <div class="hint">最近...</div> -->
        <div class="group_wrapper">
          <div
            v-if="select_project_type=='1'"
            class="search_group"
          >
            <div class="search_box">
              <div class="icon"></div>
              <input type="text" class="search" placeholder="搜索" 
                v-model="detail_keyword"
              />
              <div class="clear"
                v-show="detail_keyword"
                @click.stop="detail_keyword = ''"
              ></div>
            </div>
          </div>
          <template v-if="select_project && select_project.length && select_project_type=='1'">
            <div
              v-if="select_project_type=='1'"
              class="select_all_group cp"
              :class="{on: is_all}"
              @click="doSelectAll"
            >
              <div class="text">全选</div>
              <div class="select_box">
                <div class="select cp"></div>
              </div>
            </div>
            <template v-for="(item, index) in select_project">
              <select-unit
                v-if="devSign && Number(item.userId) !== Number(task_lord_id)"
                ref="selectUnit"
                :key="`${index}list_group`"
                :item="item"
                :search="detail_keyword"
                @click.native.stop="selectItem(item, index)"
              ></select-unit>
              <div
                v-if="!devSign && (item.userId != task_lord_id)"
                class="selected_project_item select_item cp"
                :key="`${index}list_group`"
                @click="selectItem(item, index)"
              >
                <template
                  v-if="!detail_keyword || (detail_keyword && (item.friendRemark.indexOf(detail_keyword.toLowerCase()) !== -1 || item.friendRemark.indexOf(detail_keyword.toUpperCase()) !== -1 || item.chatUserName.indexOf(detail_keyword.toLowerCase()) !== -1 || item.chatUserName.indexOf(detail_keyword.toUpperCase()) !== -1 || item.userName.indexOf(detail_keyword.toLowerCase()) !== -1 || item.userName.indexOf(detail_keyword.toUpperCase()) !== -1 ))"
                >
                  <div class="name_group"
                  >
                    <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt class="avatar my-10" />
                    <div class="name">{{ detail_keyword ? ((item.friendRemark.indexOf(detail_keyword.toLowerCase()) !== -1 || item.friendRemark.indexOf(detail_keyword.toUpperCase()) !== -1 ) ? item.friendRemark: (item.chatUserName.indexOf(detail_keyword.toLowerCase()) !== -1 || item.chatUserName.indexOf(detail_keyword.toUpperCase()) !== -1 ) ? item.chatUserName : item.userName)  : (item.friendRemark || item.chatUserName  || item.userName)}}</div>
                  </div>
                  <div class="selected cp" :class="{on: item.on}" ></div>
                </template>
              </div>
            </template>
            
          </template>
          <template
            v-if="select_project && select_project_type=='0'"
          >
            <div
              class="selected_project_item select_item un_hover"
              :style="{ 'padding-top': '16px' }"
            >
              <div
                class="name_group"
              >
                <img :src="`${$avatar_url}${select_project.thumbnail}@!small200`" alt class="avatar" v-if="select_project.thumbnail" />
                <div class="name" v-if="select_project.friendRemark">{{select_project.friendRemark}}</div>
              </div>
              <div
                class="selected cp"
                :class="{on: select_project.on}"
              ></div>
            </div>
          </template>
        </div>
      </div>
      <!-- 选择列表 -->
      <div class="selected_list_group list_group">
        <!-- <div class="hint">最近...</div> -->
        <template v-if="selected_list && select_project_type=='0'">
          <div class="selected_list_item select_item" v-for="(item, index) in selected_list" :key="`${item.userId}selected_list`">
            <div class="name_group">
              <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt class="avatar" />
              <div class="name">{{item.friendRemark || item.userName}}</div>
            </div>
            <div class="del" v-show="0"></div>
          </div>
        </template>
        <template v-if="selected_list && select_project_type=='1'">
          <template v-for="(item, index) in selected_list">
            <div
              class="selected_list_item select_item"
              :style="{
                'padding-top': '11px',
                'padding-bottom': '11px'
              }"
              :key="`${item.userId}selected_list1`"
              v-if="item.userId != task_lord_id"
            >
              <div class="name_group">
                <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt class="avatar" />
                <div class="name">{{item.friendRemark || item.userName}}</div>
              </div>
              <div class="del cp" @click="delSelectedHandler(item, index)"></div>
            </div>
          </template>
          
        </template>
      </div>
    </div>
    <div class="fun_group">
      <div class="fun sure cp" @click="doSure">确定</div>
      <div class="fun cancle cp" @click="closeSelectContact">取消</div>
    </div>
  </div>
</template>
<script>
import BasicWorkTeamAvatar from '@/components/basic/workTeamAvatar.vue';
// import ContactUnit from './SelectContactContent/ContactUnit.vue'
// import WorkTeamUnit from './SelectContactContent/WorkteamUnit.vue'
import ThumbUnit from '@/components/FunTemplate/SelectContactContent/ThumbUnit'
import SelectUnit from '@/components/FunTemplate/SelectContactContent/SelectUnit'
export default {
  components:{
    SelectUnit,
    BasicWorkTeamAvatar,
    ThumbUnit
    // ContactUnit,
    // WorkTeamUnit,
  },
  props: {
    select_target: {
      type: Boolean,
      default: false,
    },
    select_target_chat_id: {
      default: null,
    },
    select_target_chat_type: {
      default: null,
    },
    list: {
      type: Array,
      default: function(){
        return [];
      }
    }
  },
  computed: {
    /**
     * 是否可以选择群组或联系人[一级]
     * 依据: 当前任务数据是否有id
     */
    optional(){
      const {select_target} = this;
      if(select_target){
        return true;
      }
      let optional = true;
      let {task_data, task_select_users_data, task_reelect_dialog} = this.$store.state;
      let {chatId,chatType} = task_data || {};
      if(task_reelect_dialog){
        return true; // [分享点击我要使用]
      }
      if(chatId){
        optional = false;
      }
      return optional;
    },
    // 群主id
    task_lord_id(){
      let {task_data} = this;
      let task_lord_id ;
      if(task_data.taskType == 2){
        task_lord_id = this.$tools.getUserId();
      }else{
        // 任务数据存在 || 当前用户id
        task_lord_id = task_data.userId || this.$tools.getUserId();
      }
      return task_lord_id;
    },
    // 好友列表
    friends_list(){
      let friends_list = this.$tools.deepClone(this.$store.state.friends_list);
      for(let i in friends_list){
        friends_list[i].userId = friends_list[i].friendUserId;
      }
      return friends_list;
    },
    // 好友列表(按首字符分组)
    friends_list_group() {
      let friends_list_group = this.$tools.deepClone(this.$store.state.friends_list_group);
      // 将userId改成好友id
      for(let i in friends_list_group){
        for(let j in friends_list_group[i]){
          friends_list_group[i][j].userId = friends_list_group[i][j].friendUserId;
        }
      }
      // console.log(friends_list_group)
      return friends_list_group;
    },
    // 工作组列表
    workteam_list() {
      console.log({ workteam: this.$store.state.workteam_list})
      return this.$store.state.workteam_list
    },
    // 消息盒子列表 用于最近
    message_box_list() {
      let message_box_list = this.$tools.deepClone(
        this.$store.state.message_box_list
      );
      if (message_box_list) {
        message_box_list.map((item) => {
          if(item.chatType == 0){
            item.userId = item.chatId;
            item.thumbnail = item.photo || "";
          }
          item.friendRemark = item.name;
        });
      }
      return message_box_list;
    },
    /**任务单相关用户 */
    task_users() {
      let task_users = this.$tools.deepClone(this.$store.state.task_users);
      return task_users;
    },
    /**任务数据 */
    task_data(){
      let task_data = this.$tools.deepClone(this.$store.state.task_data);
      return task_data;
    },
    /**初始数据加载完成 */
    loading(){
      if(this.task_data && this.friends_list && this.workteam_list){
        return true;
      }
      return false;
    },
    /**任务选择用户数据 */
    task_select_users_data(){
      return this.$store.state.task_select_users_data;
    },
    /** 是否全选 */
    is_all(){
      let {
        select_project,
        select_project_type,
        task_lord_id,
        $refs,
        detail_keyword
      } = this
      let is_all = true
      if (detail_keyword && select_project) {
        // 搜索
        select_project.forEach((item) => {
          if (!item.on && (Number(item.userId) !== Number(task_lord_id))) {
            // 未选中 && 不是创建着
            const selectUnit = $refs.selectUnit.find((unit) => {
              return Number(unit.item.userId) === Number(item.userId)
            })
            console.log(selectUnit)
            if (selectUnit && ((selectUnit.nameForSearch ?? '') !== '')) {
              // 已选择项目存在 && 已选择项目的搜索名不为空值
              is_all = false
            }
          }
        })
        // 如果未选中的搜索项 则判断是否搜索列表为空 如果为空则显示为false
        if (is_all) {
          const hasOne = $refs.selectUnit.find((item) => {
            return (item.nameForSearch ?? '') !== ''
          })
          console.log(hasOne)
          if (hasOne === undefined) {
            is_all = false
          }
        }
        console.log(is_all)

      } else {
        if (select_project_type == 1 && select_project) {
          select_project.forEach((item) => {
            if (!item.on && item.userId != task_lord_id) {
              is_all = false
            }
          })
        } else {
          is_all = false
        }
      }

      return is_all
    },
    
  },
  watch: {
    loading: {
      handler: function(val){
        if(val && !this.loading_handler_status){
          this.loadingHandler();
        }
      },
      deep: true,
    },
    list_keyword: function(val){
      if(val.trim() === ''){
        this.detail_keyword = ''
      }
    },
  },
  data() {
    return {
      devSign: 1,
      select_project: null,
      selected_list: null,
      select_project_type: 0, // 0 / 1 联系人/工作组
      data_edit_task_users: null,
      select_project_id: null, //选中项目id
      // optional: true, // 是否可选
      loading_handler_status: false, //是否执行初始化函数
      from: null, //来源

      list_keyword: '',
      detail_keyword: '',
    };
  },
  /**
   * 必读:
   * task_data 负责判断是否是新创建的或新开的任务 主要参数 id[taskId] chatId
   * task_select_users_data 用于保存选择的数据
   */
  methods: {
    /**
     * @description: 加载初始化数据 (判断是否切换其他任务)
     */
    async init() {
      if (this.friends_list_group == null) {
        await this.$store.dispatch("get_friends_list");
      }
      if (this.workteam_list == null) {
        await this.$store.dispatch("get_workteam_list");
      }
      if (this.message_box_list == null) {
        await this.$store.dispatch("get_message_box_list");
      }
      const {select_target} = this;
      if(select_target){
        return;
      }
      let {task_data, task_select_users_data, task_users} = this;
      // // console.log(task_data, task_select_users_data)
      // 判断是否切换其他任务 任务id存在 && 不相等 [任务id不存在时在关闭任务和发送任务后需要手动置空]
      if(task_data.id && (task_data.id != task_select_users_data.taskId)){
        // // console.log('修改task_select_users_data');
        task_select_users_data.chatId = task_data.chatId || '';
        task_select_users_data.chatType = task_data.chatType || '';
        task_select_users_data.taskId = task_data.id || '';
        task_select_users_data.taskType = task_data.taskType || '';
        // task_select_users_data.taskType = task_data.taskType || '';
        // task_select_users_data.taskUsers = task_users;
      }else{
        // // console.log('不修改task_select_users_data');
      }
    },
    /**初始加载数据处理 */
    loadingHandler(){
      const {select_target} = this;
      if(this.loading && !this.loading_handler_status){
        /**初始是否有chatID */
        let {task_select_users_data, select_target_chat_id, select_target_chat_type} = this;
        if(select_target){
          this.select_project_id = select_target_chat_id;
          if(select_target_chat_type !== null){
            if(select_target_chat_type == 1){
              let item = this.workteam_list.find((val)=>{
                return val.chatId == select_target_chat_id;
              })
              this.workteamListHandler(item);
            }else if(select_target_chat_type == 0){
              let item = this.friends_list.find((val)=>{
                return val.userId == select_target_chat_id;
              })
              // console.log(item)
              if(item){
                this.friendsListHandler(item);
              }
            }
          }


        }else{
           if(task_select_users_data.chatId){
            this.select_project_id = task_select_users_data.chatId;
            /**选中默认联系人(未完成) */
            if(task_select_users_data.chatType == 1){
              let item = this.workteam_list.find((val)=>{
                return val.chatId == task_select_users_data.chatId;
              })
              this.workteamListHandler(item);
            }else if(task_select_users_data.chatType == 0){
              let item = this.friends_list.find((val)=>{
                return val.userId == task_select_users_data.chatId;
              })
              // console.log(item)
              if(item){
                this.friendsListHandler(item);
              }else{
                // // console.log('userId不在好友列表中')
              }
            }
          }
        }

       
        this.loading_handler_status = true;// 执行完毕 不再执行
      }
    },
    /**
     * 最近消息
     */
    async messageBoxListHandler(item) {
      const { select_target } = this
      if (this.optional) {
        let select_project = item
        // this.selectProject(select_project);
        if (item.chatType == '0') {
          // 单聊
          // console.log(11)
          this.friendsListHandler(select_project)
        } else if (item.chatType == '1') {
          // 群聊
          this.workteamListHandler(select_project)
        }
        if (select_target) {
          return
        }
        // 点击时设置chatType；
        let task_data = this.$tools.deepClone(this.$store.state.task_data)
        task_data.chatType = item.chatType
        this.$store.commit('set_task_data', task_data)
      }

    },
    /**
     * @description:  选择工作组点击事件
     * @param {*} item
     */
    clickWorkteamList(item){
      if(this.optional){
        this.workteamListHandler(item);
      }
    },
    /**
     * @description:  选择好友点击事件
     * @param {*} item
     */
    clickFriendList(item){
      if(this.optional){
        this.friendsListHandler(item);
      }
    },
    /**
     * 选择单聊好友
     */
    friendsListHandler(item) {
      // 单聊可以选择对方也可以不选
      this.select_project_type = 0
      this.select_project_id = item.userId
      this.from = item.friendRemark || item.userName
      // 自动添加任务用户
      let data_edit_task_users = []
      if (item) {
        item.on= true
      }
      data_edit_task_users.push(item)
      this.$set(this, 'data_task_users', data_edit_task_users)
      this.$set(this, 'select_project', item)
      this.$set(this, 'selected_list', [item])
      this.detail_keyword = ''
      // // console.log(this.data_edit_task_users, this.select_project, this.selected_list)
    },
    /**
     * 选择工作组[切换清空已选择]
     */
    async workteamListHandler(item){
      this.select_project_type = 1
      this.select_project_id = item.chatId
      this.from = item.name || item.chatName
      let res = await this.$http.request('/projectFriends/chatUserList',{chatId: item.chatId})
      let select_project = res.contents.list
      // this.selectProject(select_project)
      // 设置已选中用户
      let selected_list = []
      for (let i in select_project) {
        let has
        const {select_target} = this
        if (select_target) {
          has = this.list.find((val)=>{
            return val.userId == select_project[i].userId
          })
        } else {
          has = this.task_users.find((val) => {
            return val.userId == select_project[i].userId
          })
        }

        if (select_target) {
          if (this.select_project_id != this.select_target_chat_id) {
            // 清空已选择
            select_project[i].on = false
          } else {
            if (has) {
              select_project[i].on = true
              selected_list.push(select_project[i])
            }
          }
        } else{
          if (this.select_project_id != this.task_select_users_data.chatId) {
            // 清空已选择
            select_project[i].on = false
          } else {
            if (has) {
              select_project[i].on = true
              selected_list.push(select_project[i])
            }
          }
        }

      }
      this.$set(this, 'select_project', select_project)
      this.$set(this, 'selected_list', selected_list)
      this.detail_keyword = ''
    },
    /**
     * 选择工作组用户/用户
     */
    selectItem(item, index){
      if(item.on){
        // 移除
        item.on = false;
        let findIndex = this.selected_list.findIndex((val)=>{
          return val.userId == item.userId;
        })
        let selected_list = this.$tools.deepClone(this.selected_list);
        if(findIndex != -1){
          selected_list.splice(findIndex, 1); //删除一个
        }
        this.$set(this, 'selected_list', selected_list);
      }else{
        // 添加
        item.on = true;
        let selected_list = [];
        if(this.selected_list != null){
          selected_list = this.$tools.deepClone(this.selected_list);
        }
        selected_list.push(item);
        this.$set(this, 'selected_list', selected_list);
      }
      // this.$set(this.select_project, index, item);
      console.log(item)
    },
    /**
     * 删除选中的项目
     */
    delSelectedHandler(item, index){
      let selected_list = this.$tools.deepClone(this.selected_list);
      selected_list.splice(index, 1);
      this.$set(this, 'selected_list', selected_list);
      if(this.select_project_type==0){
        // this.$set(this.select_project, 'on', false);
      }else{
        // let select_project = this.$tools.deepClone(this.select_project);
        let select_project = this.select_project
        let findIndex = select_project.findIndex((val)=>{
          return val.userId == item.userId;
        })
        this.$set(this.select_project[findIndex], 'on', false);
      }
     
    },
    /**关闭 */
    closeSelectContact(){
      this.$emit('closeSelectContact');
    },
    /**
     * @description: 确定 (需要确认的数据: 选择的用户task_users, chatId, chatType)
     */
   
    doSure(){
      const {select_target} = this;
      if(select_target){

        const selected_list = this.$tools.deepClone(this.selected_list);
        if(selected_list){

          const index = selected_list.findIndex((unit)=>{
            return unit.userId == this.task_lord_id;
          })
          // 移除群主
          if(index != -1){
            selected_list.splice(index,1);
          }
          this.$emit('handleSure', {
            list: selected_list,
            chat_type:this.select_project_type,
            chat_id: this.select_project_id,
            from: this.from, //来源名
          })
        }else{
          this.closeSelectContact();
        }
      }else{
        // // console.log(this.data_edit_task_users, this.selected_list)
        if(this.select_project_type == '0'){
          this.$store.commit('set_task_users', this.selected_list);
        } else if (this.select_project_type == 1){
          this.$store.commit('set_task_users', this.selected_list);
        }
  
        // 判断task_lord_id是否在里面 如果不在, 追加到第一位
        // 判断当前用户是否在里面 如果task_select_users_data.taskId不存在&&第一个不是当前用户 需要将当前用户追加在第一位
        let {task_select_users_data, task_lord_id, select_project, selected_list} = this;
        // let task_data = this.$store.state.task_data;
        // let {taskId} = task_select_users_data;
        let user = this.$tools.getCookie('user');
        let userid = this.$tools.getUserId();
        let task_lord_data ;
        // 获取群主信息
        if(task_lord_id == userid){
          task_lord_data = user;
        }else{
          select_project.forEach( item => {
            if(item.userId == task_lord_id){
              task_lord_data = item;
            }
          })
        }
        if(selected_list && ((selected_list[0] && selected_list[0].userId != task_lord_id) || !selected_list[0])){
          selected_list.unshift(task_lord_data);
          this.$store.commit('set_task_users', this.selected_list);
        }
  
        // // console.log(task_lord_data);
        // // console.log(taskId,this.selected_list.length, this.selected_list[0] ? this.selected_list[0].userId : this.selected_list[0] , user.userId)
        // if( (!task_data.id || task_data.taskType == 2 || task_data.taskType == 4) && (this.selected_list.length == 0 || (this.selected_list[0] && this.selected_list[0].userId != user.userId))){
        //   this.selected_list.unshift(user);
        //   this.$store.commit('set_task_users', this.selected_list);
        // }
        this.$set(this.task_select_users_data, 'chatId', this.select_project_id);
        this.$set(this.task_select_users_data, 'chatType', this.select_project_type);
      }
      this.closeSelectContact();
      // // console.log(this.task_users, this.task_select_users_data);
    },
    /**
     * @description: 全选按钮
     * @param {*}
     * @return {*}
     */
    doSelectAll() {
      let {
        select_project,
        is_all,
        select_target,
        detail_keyword,
        $refs
      } = this
      console.log($refs.selectUnit)
      const userid = this.task_lord_id
      let is_select_none = true //是否一个人都没选[除了自己]
      if (select_project) {
        select_project.forEach((item) => {
          if (item.on && item.userId != userid) {
            is_select_none = false
          }
        })
        if (is_select_none || is_all) {
          // 一个都没选 / 全选
          select_project.forEach((item, index) => {
            if (item.userId != userid) {
              if (detail_keyword) {
                // 搜索
                const selectUnit = $refs.selectUnit.find((unit) => {
                  return Number(unit.item.userId) === Number(item.userId)
                })
                if (selectUnit && ((selectUnit.nameForSearch ?? '') !== '')) {
                  this.selectItem(item, index)
                }
                // if ($refs.selectUnit[index] && (($refs.selectUnit[index].nameForSearch ?? '') !== '')) {
                //   // 有搜索内容
                //   this.selectItem(item, index)
                // }
              } else {
                // 未搜索
                this.selectItem(item, index)
              }
            }
          })
        } else {
          // 选择了一部分
          select_project.forEach((item, index) => {
            if (!item.on && item.userId != userid) {
              if (detail_keyword) {
                console.log($refs.selectUnit[index], index)
                // 搜索
                const selectUnit = $refs.selectUnit.find((unit) => {
                  return Number(unit.item.userId) === Number(item.userId)
                })
                if (selectUnit && ((selectUnit.nameForSearch ?? '') !== '')) {
                  this.selectItem(item, index)
                }
                // if ($refs.selectUnit[index] && (($refs.selectUnit[index].nameForSearch ?? '') !== '')) {
                //   // 有搜索内容
                //   this.selectItem(item, index)
                // }
              } else {
                // 未搜索
                this.selectItem(item, index)
              }
            }
          })
        }
      }
      // // console.log(this.selected_list);
    },
   
  },
  async mounted() {
    this.init();
    this.loadingHandler();
    
  },
};
</script>
<style lang="scss" scoped>
.select_contact_content_wrapper {
  @include flex;
  flex-direction: column;
  // height: 80%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .title_group {
    @include flex;
    @include bbox;
    border-bottom: 2px solid $border_color;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 84px;
    flex-shrink: 0;
    .title {
      font-size: 18px;
      
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      right: 30px;
      background: {
        image: url(~@/assets/images/common/close.png);
        size: 12px 12px;
        position: center;
        repeat: no-repeat;
      }
    }
  }
  .content_group {
    @include flex;
    height: 100%;
    flex: 1;
    overflow: hidden;
    .list_group {
      @include bbox;
      @include scrollbar;
      width: 300px;
      height: 100%;
      overflow: auto;
      border-right: 2px solid $border_color;
      &:last-child {
        border-right: 0;
      }
      .search_group {
        @include bbox;
        padding-top: 15px;
        padding-bottom: 10px;
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: {
          left: 36px;
          right: 28px;
        }
        width: 100%;
      }
      .search_box {
        @include flex;
        @include bbox;
        padding: 0 20px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background-color: #f3f3f3;
        .icon {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
          background: {
            image: url(~@/assets/images/common/search_icon.png);
            size: 100%;
          }
        }
        .search {
          @include bbox;
          @include placeholder(#999);
          width: 160px;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0 10px;
          font-size: 14px;
          
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
        }
        .clear {
          width: 20px;
          height: 20px;
          background: {
            image: url(~@/assets/images/common/clear_icon.png);
            size: 14px 14px;
            position: center;
            repeat: no-repeat;
          }
        }
      }
    }
    .select_list_group {
      position: relative;
      &.cantselect{
        .select_item:hover{
          background-color: transparent;
        }
        .select_item.on:hover{
          background-color: #eee;
        }
      }
      // .search_group {
      //   @include bbox;
      //   padding-top: 30px;
      //   padding-bottom: 10px;
      //   position: sticky;
      //   top: 0;
      //   background-color: #fff;
      //   padding: {
      //     left: 36px;
      //     right: 28px;
      //   }
      //   width: 100%;
      // }
      // .search_box {
      //   @include flex;
      //   @include bbox;
      //   padding: 0 20px;
      //   width: 100%;
      //   height: 40px;
      //   border-radius: 4px;
      //   background-color: #f3f3f3;
      //   .icon {
      //     width: 14px;
      //     height: 14px;
      //     flex-shrink: 0;
      //     background: {
      //       image: url(~@/assets/images/common/search_icon.png);
      //       size: 100%;
      //     }
      //   }
      //   .search {
      //     @include bbox;
      //     @include placeholder(#999);
      //     width: 160px;
      //     background-color: transparent;
      //     border: none;
      //     outline: none;
      //     padding: 0 10px;
      //     font-size: 14px;
          
      //     font-weight: 400;
      //     color: rgba(153, 153, 153, 1);
      //   }
      //   .clear {
      //     width: 20px;
      //     height: 20px;
      //     background: {
      //       image: url(~@/assets/images/common/clear_icon.png);
      //       size: 14px 14px;
      //       position: center;
      //       repeat: no-repeat;
      //     }
      //   }
      // }
    }
    .selected_project_group {
      // padding-top: 10px;
      .select_all_group{
        @include flexsb;
        @include bbox;
        padding: {
          left: 37px;
          right: 28px;
          top: 6px;
          bottom: 10px;
        }
        .text{
          font-size: 14px;
        }
        &.on{
          .select_box{
            .select{
              border:none;
              background-image: url(~@/assets/images/create_dialog/selected.png);
              background-size: 100%;
            }
          }
        }
        .select_box{
          width:18px;
          height:18px;
          .select{
            @include bbox;
            // @include transition;
            width: 100%;
            height: 100%;
            border:2px solid rgba(153,153,153,1);
            border-radius:50%;
          }
        }

      }
    }
    .selected_list_group {
      padding-top: 10px;
    }
    .hint {
      padding: {
        left: 36px;
        top: 20px;
        bottom: 15px;
      }
      font-size: 14px;
      
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }
    .select_item {
      @include flex;
      @include bbox;
      @include transition;
      width: 100%;
      padding: {
        left: 36px;
        right: 28px;
        // top: 11px;
        // bottom: 11px;
      }
      &:hover,
      &.on {
        background-color: #eee;
      }
      &.un_hover:hover{
        background-color: transparent;
      }
      .name_group {
        @include bbox;
        @include flex;
        flex: 1;
        width: 100%;
        overflow: hidden;
        .avatar {
          flex-shrink: 0;
          width: 44px;
          height: 44px;
          border-radius: 4px;
          display: block;
          background-color: $img_color;
        }
        .name {
          @include bbox;
          flex: 1;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 10px;
          font-size: 14px;
          
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    .selected_project_item {
      .selected {
        @include transition;
        @include bbox;
        width: 18px;
        height: 18px;
        border: 2px solid #999;
        border-radius: 50%;
        &.on {
          border: none;
          background: {
            image: url(~@/assets/images/common/selected.png);
            size: 100%;
          }
        }
      }
    }
    .selected_list_group {
      .del {
        width: 18px;
        height: 18px;
        background: {
          image: url(~@/assets/images/common/clear_icon.png);
          size: 100%;
        }
      }
    }
  }
  .fun_group {
    @include flex;
    @include bbox;
    border-top: 2px solid $border_color;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    height: 90px;
    .fun {
      line-height: 50px;
      font-size: 18px;
      
      font-weight: 400;
      color: $main_blue;
      width: 160px;
      border-radius: 4px;
      text-align: center;
      background: rgba(0, 84, 167, 0.1);
      margin: 0 20px;
      &.sure {
        background-color: $main_blue;
        color: #fff;
      }
    }
  }
}
@media screen and (max-height: 800px){
.select_contact_content_wrapper{
  .title_group {
    height: 54px;
    flex-shrink: 0;
    .title {
      font-size: 16px;
    }
  }
  .content_group {
    .list_group {
    }
    .selected_project_group {
      padding-top: 10px;
    }
    .selected_list_group {
      padding-top: 10px;
    }
    .hint {
      padding: {
        top: 10px;
        bottom: 10px;
      }
    }
    .select_item {
      padding: {
        top:6px;
        bottom: 6px;
      }
      .name_group {
        .avatar {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .fun_group {
    height: 60px;
    .fun {
      line-height: 30px;
      font-size: 12px;
      width: 120px;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
}
}
</style>