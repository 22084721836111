var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_task_main_task_title_status_tag d-flex align-center" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            width: "120",
            trigger: "click",
            "popper-class": "c_task_main_task_title_status_tag_select_group",
            "visible-arrow": false,
          },
          on: { show: _vm.showSelect },
          model: {
            value: _vm.switchOpen,
            callback: function ($$v) {
              _vm.switchOpen = $$v
            },
            expression: "switchOpen",
          },
        },
        [
          _c("select-group", {
            ref: "selectGroup",
            on: { handleClose: _vm.handleClose },
          }),
          _c("template", { slot: "reference" }, [
            _c("div", { staticClass: "toggle_button cp" }),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }