<template>
  <div
    class="components_task_side_bar_teach_pages_start_page bbox d-flex align-center justify-center"
  >
    <div class="un_sel d-flex flex-column align-center">
      <img src="~@/assets/images/common/empty.png" alt="" class="start">
      <div class="hint">当前没有教学内容</div>
      <div class="button btn_dark"
        @click="doStart"
      >开始制作教学内容</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    can_teach: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    doStart(){
      const {can_teach} = this;
      if(can_teach){
        this.$emit('handleChangePage', 'price')
        return ;
      }

      this.$openMessageBox({
        title:'提示',
        desc:'签约讲师功能暂未上线，敬请期待...',
        sure:'返回',
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.components_task_side_bar_teach_pages_start_page{
  width: 100%;
  height: 100%;
  .hint{
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    padding: {
      top: 20px;
      bottom: 40px;
    }
  }
  .button{
    font-size: 14px;
    color: #FFFFFF;
    line-height: 44px;
    padding: 0 44px;
    border-radius: 4px;
  }
}
</style>