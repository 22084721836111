var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_pop_up_call_task_custom_page d-flex flex-column",
    },
    [
      0
        ? [
            _vm.status_breadcrumb
              ? _c(
                  "div",
                  { staticClass: "path_group flex-shrink-0 bbox" },
                  [
                    _c(
                      "overlay-scrollbars",
                      { ref: "overlay", staticClass: "overlay_container" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "path_overlay_group d-flex flex-align",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text top cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToTop()
                                  },
                                },
                              },
                              [_vm._v("全部分组")]
                            ),
                            _c("breadcrumb", {
                              attrs: {
                                data: _vm.breadcrumb,
                                folder_id: _vm.folder_id,
                                call_custom: true,
                                current: true,
                              },
                              on: { handleChangeFolder: _vm.changeFolder },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("views", {
              ref: "views",
              attrs: { call_custom: true, folder_id: _vm.folder_id },
              on: {
                handleChangeFolder: _vm.changeFolder,
                handleCallTask: _vm.callTask,
              },
            }),
          ]
        : _c("collect-main", {
            attrs: { show_menu: false, call: true, top_jump: false },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }