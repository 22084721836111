<!--
 * @Author       : Hugo
 * @Date         : 2021-09-29 14:21:06
 * @LastEditTime : 2021-10-25 14:44:44
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/CustomClassify.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_popup_custom_classify_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0">
          <div class="title">自定义分类</div>
          <div class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main d-flex flex-column">
          <div class="path_group flex-shrink-0 bbox"
            v-if="status_breadcrumb"
          >
            <overlay-scrollbars
              class="overlay_container"
              ref="overlay"
            >
              <div class="path_overlay_group d-flex flex-align">
                <div class="text top cp"
                  @click="goToTop();"
                >全部分组</div>
                <breadcrumb
                  :data="breadcrumb"
                  :folder_id="folder_id"
                  :call_custom="true"
                  :current="true"
                  @handleChangeFolder="changeFolder"
                ></breadcrumb>
              </div>
              
            </overlay-scrollbars>
            
            
          </div>
          <views
            :call_custom="true"
            :folder_id="folder_id"
            ref="views"
            @handleChangeFolder="changeFolder"
            @handleCallTask="callTask"
          ></views>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Views from '@/components/FunTemplate/Custom/View.vue';
import Breadcrumb from '@/components/FunTemplate/Custom/Breadcrumb.vue';
export default {
  components: {
    Views,
    Breadcrumb,
  },
  data(){
    return {
      index: 1,
      breadcrumb: {},
      folder_id: 0,
    }
  },
  computed:{
     // 面包屑导航
    status_breadcrumb(){
      const {folder_id} = this;
      if(folder_id === 0){
        return false;
      }
      return true;
    },
  },
  methods: {
    /**
     * @description: 获取面包屑导航
     * @param {*}
     * @return {*}
     */
    async getBreadcurmb(fId){
      // const {folderid} = this.$route.params;
      // let fId = 0;
      // if(folderid){
      //   fId = this.$global.decodeBase64(folderid);
      // }
      if(fId){
        let res = await this.$global.doPost({
          url: '/projectCustom/queryCustomFolderRoute',
          data: {fId},
          notice: '获取文件路径失败',
        })
        if(res.contents && res.contents.PFolderRoute){
          this.$set(this, 'breadcrumb', res.contents.PFolderRoute);
          this.$nextTick(function(){
            this.$refs.overlay.osInstance().scroll({x: '100%'});
          })
        }
      }
    },
    changeFolder(data){
      const {id} = data;
      this.folder_id = id;
      this.$nextTick(function(){
        this.getBreadcurmb(id);
        this.$refs.views.init();
      })
    },
    goToTop(){
      this.folder_id = 0;
      this.$nextTick(function(){
        this.getBreadcurmb(0);
        this.$refs.views.init();
      })
    },
    callTask(data){
      
      this.$emit('handleCallTask', data);
      this.doClose();
    },
    doClose(){
      this.$emit('handleClose');
    }
  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
  },
}
</script>
<style lang="scss" scoped>
.components_popup_custom_classify_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    width: 100%;
    height: 100%;
    max-width: 80%;
    // min-width: 1000px; 
    // max-height: 80%;
    max-height: 90%;
    // min-height: 800px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    background-color: #f8f8f8;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0 0 4px 4px;

  }
  .path_group{
    width: 100%;
    padding: 10px 20px;
  }
}
</style>