var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pannel_record_wrapper" }, [
    0
      ? _c("div", { staticClass: "title_bar" }, [
          _c("div", { staticClass: "title_group" }, [
            _c(
              "div",
              {
                staticClass: "title",
                class: { on: _vm.type == "time" },
                on: {
                  click: function ($event) {
                    _vm.type = "time"
                    _vm.getTaskLog(1)
                  },
                },
              },
              [_vm._v("时间")]
            ),
            0
              ? _c(
                  "div",
                  {
                    staticClass: "title",
                    class: { on: _vm.type == "contact" },
                    on: {
                      click: function ($event) {
                        _vm.type = "contact"
                        _vm.getTaskLog(2)
                      },
                    },
                  },
                  [_vm._v("成员")]
                )
              : _vm._e(),
            0
              ? _c(
                  "div",
                  {
                    staticClass: "title",
                    class: { on: _vm.type == "control" },
                    on: {
                      click: function ($event) {
                        _vm.type = "control"
                        _vm.getTaskLog(3)
                      },
                    },
                  },
                  [_vm._v("控件")]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "record_container" }, [
      _c(
        "div",
        { staticClass: "content time" },
        _vm._l(_vm.clone_task_log, function (item) {
          return _c("div", { key: item.id, staticClass: "unit" }, [
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(_vm._f("formatDate")(item.createTime))),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.userName))]),
            _c(
              "div",
              { staticClass: "details" },
              [
                _vm._l(item.details, function (unit, index) {
                  return _c("div", { key: index, staticClass: "text_line" }, [
                    _c("div", { staticClass: "desc_line" }, [
                      _vm._v(
                        _vm._s(_vm.$api.transformDetailsType(unit.type)) +
                          " " +
                          _vm._s(
                            _vm.$api.transformDetailsControlType(
                              unit.controlType
                            )
                          ) +
                          _vm._s(
                            _vm.$api.transformDetailsTitle(
                              unit.type,
                              unit.controlType,
                              unit.title
                            )
                          )
                      ),
                    ]),
                    _c("div", {
                      staticClass: "content_line",
                      domProps: { innerHTML: _vm._s(unit.content) },
                    }),
                  ])
                }),
                _c("div", [_vm._v(_vm._s(item.work))]),
              ],
              2
            ),
          ])
        }),
        0
      ),
      !_vm.nomore
        ? _c(
            "div",
            {
              staticClass: "loadmore_group",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.getMore.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "loadmore cp" }, [
                _vm._v("展开更多记录"),
              ]),
              _c("div", { staticClass: "icon cp" }),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }