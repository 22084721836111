<template>
  <div
    class="components_task_sidebar_widget_button_page d-flex flex-column align-center justify-center un_sel"
    :class="{disabled: !editable}"
  >
    <div
      class="icon"
      :class="[name]"
    ></div>
    <div
      class="text"
    >
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: 'text',
    },
    title: {
      type: String,
      default: '文本',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  }
}
</script>
<style lang="scss" scoped>
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
.components_task_sidebar_widget_button_page{
  @include transition;
  @include background;
  width: 90px;
  height: 90px;
  margin: 10px;
  // background-image: url(~@/assets/images/task/widget_button.png);
  box-shadow: 0 0 10px 3px RGBA(0, 84, 167, 0.1);
  border-radius: 4px;
  &:hover{
    background-color: #eee;
  }
  .icon{
    @include background(30px 30px);
    width: 30px;
    height: 30px;
    @include icon(text);
    @include icon(number);
    @include icon(money);
    @include icon(time);
    @include icon(file);
    @include icon(hyperlink);
    @include icon(picture);
    @include icon(contact);
    @include icon(wisdom);
  }
  .text{
    font-size: 14px;
    color: #002141;
    line-height: 20px;
    padding-top: 6px;
  }
}
</style>