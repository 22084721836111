<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-25 13:26:19
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-30 21:30:42
 * @FilePath: /miaohang/src/components/PopUp/NetUserList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="components_pop_up_net_user_list_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0">
          <div class="title">{{title}}（{{list.length}}）</div>
          <div class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main">
          <div class="list bbox d-flex align-center flex-wrap">
            <template
              v-for="(item, index) in list"
            >
              <div
                :key="index"
                class="user d-flex align-center flex-column flex-shrink-0"
                @click="openUserDetail(item);"
              >
                <div class="avatar_group">
                  <img 
                    :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                  <div class="readed_group d-flex align-center justify-center"
                    v-if="open_readed && item.isread"
                  >
                    <div class="text">已读</div>
                  </div>
                </div>
                <div class="name">{{priorityName(item)}}</div>
              </div>
            </template>
            <div 
              class="loading_group d-flex align-center justify-center"
              v-show="loading_users"
            >
              <loading-icon
                size="20px"
              ></loading-icon>
            </div>
            <div class="add_group cp un_sel"
              v-if="open_add"
              @click="touchAdd();"
            >
              <div class="text bbox">添加</div>
            </div>
            

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/basic/loadingIcon.vue'
export default {
  components: {
    LoadingIcon,
  },
  props: {
    title: {
      type: String,
      default: '查看用户'
    },
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    open_readed: {
      type: Boolean,
      default: false,
    },
    open_add: {
      type: Boolean,
      default: false,
    },
    loading_users: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      index:1 ,
    }
  },
  computed: {
     task(){
      return this.$store.state.task;
    },
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    touchAdd(){
      this.$emit('handleTouchAdd');
    },
    priorityName(item){
      const {updateremark, friendRemark, userName, chatUserName} = item;
      if(updateremark === 1){
        return friendRemark || chatUserName || userName;
      }
      return chatUserName || userName;

    },
    openUserDetail(item){
      this.$api.user.getInfo(item);
      // this.$user_detail({data: item});
      // // console.log(item);
    },
  },
  mounted(){
    console.log(this.list)
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    // this.init();
    // console.log(this.list);
  },
}
</script>

<style lang="scss" scoped>
.components_pop_up_net_user_list_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    width: 60%;
    height: 70%;
    // max-width: 60%;
    // max-height: 70%;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
  .main{
    background-color: #f8f8f8;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0 0 4px 4px;
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .list{
    @include scrollbar;
    overflow-y:auto;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .user{
    width: 62px;
    margin: 5px 5px 10px;
  }
  .avatar_group{
    width: 52px;
    height: 52px;
    position: relative;
  }
  .avatar{
    width: 52px;
    height: 52px;
    // background-color: rgba(255,0,255, 0.6);
    border-radius: 4px;
    z-index: 2;
  }
  .readed_group{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    text-align: center;
    .text{
      transform: scale(0.6);
    }
  }
  .name{
    @include single_line_intercept;
    font-size: 12px;
    padding-top: 5px;
    color: #333;
    width:100%;
    text-align: center;
  }
  .loading_group{
    width: 62px;
  }
  .add_group{
    margin: 5px 5px 30px;
    width: 62px;
    .text{
      width: 52px;
      line-height: 50px;
      height:52px;
      border-radius: 4px;
      border: 1px solid #aaa;
      text-align: center;
      font-size: 12px;
      letter-spacing: 2px;
      padding-left: 2px;
      color: #999;
    }
  }
}
</style>