var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_select_contacts_dialog_list bbox d-flex flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "div",
            { staticClass: "sticky_group" },
            [
              _c("search-group", {
                staticClass: "flex-shrink-0 bbox borderside_padding pb-16",
              }),
            ],
            1
          ),
          _c("div", { staticClass: "category py-5 borderside_padding bbox" }, [
            _vm._v("最近..."),
          ]),
          _vm._l(40, function (item) {
            return [
              _c("unit", {
                key: item,
                staticClass: "py-10 user borderside_padding",
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }