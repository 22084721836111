<!--
 * @Author       : Hugo
 * @Date         : 2020-06-29 11:39:52
 * @LastEditTime : 2022-03-28 12:00:47
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : /miaohang/src/components/dialog/EditTask/PannelRecord.vue
 * @^_^
--> 
<template>
  <div class="pannel_record_wrapper">
    <div class="title_bar" v-if="0">
      <div class="title_group">
        <div class="title" :class="{on: type == 'time'}" @click="type = 'time';getTaskLog(1);">时间</div>
        <div class="title" :class="{on: type == 'contact'}" @click="type = 'contact';getTaskLog(2);" v-if="0">成员</div>
        <div class="title" :class="{on: type == 'control'}" @click="type = 'control';getTaskLog(3);" v-if="0">控件</div>
      </div>
    </div>
    <div class="record_container">
      <div class="content time">
        <div class="unit" v-for="item in clone_task_log" :key="item.id">
          <div class="date">{{item.createTime | formatDate}}</div>
          <div class="name">{{item.userName}}</div>
          <div class="details">
            <div
              class="text_line"
              v-for="(unit, index) in item.details"
              :key="index"
            >
              <div
                class="desc_line"
              >{{$api.transformDetailsType(unit.type)}} {{$api.transformDetailsControlType(unit.controlType)}}{{$api.transformDetailsTitle(unit.type, unit.controlType, unit.title)}}</div>
              <div class="content_line" v-html="unit.content"></div>
            </div>
            <div>{{item.work}}</div>
          </div>
        </div>
      </div>
      <div class="loadmore_group" v-if="!nomore" @click.stop="getMore">
        <div class="loadmore cp">展开更多记录</div>
        <div class="icon cp"></div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    task_id: {
      type: Number,
      default: null,
    },
  },
  data(){
    return {
      type: 'time',
      nomore: true,
      clone_task_log: null,
      log_type: 1,
      loading: false,
    }
  },
  computed:{
    task_log(){
      let task_log = this.$tools.deepClone(this.$store.state.task_log);
      return task_log;
    },
  },
  watch:{
    task_log:{
      handler: function(val){
        if(val && val.length < 20){
          this.nomore = true;
        }else{
          this.nomore = false;
        }
        this.$set(this, 'clone_task_log', val);
      },
      deep: true,
    }
  },
  methods:{
    /**
     * @description: 获取控件记录
     * @param {Number} querytype 查询类型 1时间(default) 2成员 3控件
     * @return: null
     */
    async getTaskLog(querytype){
      const {task_id} = this;
      this.log_type = querytype;
      this.$store.commit('set_task_log', null); //置空
      if(task_id){
        let res = await this.$global.doPost({
          url: '/projectTask/getTaskLog',
          data: {taskId: task_id, querytype}
        });
        this.$store.commit('set_task_log', res.contents.logList);
      }else{
        this.$store.dispatch('get_task_log', querytype);
      }
    },
    /**
     * @description: 获取更多记录
     * @param {type} 
     * @return {type} 
     */
    async getMore(){
      if(this.loading){
        return;
      }
      this.loading = true;
      const {task_config} = this.$store.state;
      const {task_id} = task_config;
      // let taskId = this.$store.state.task_id;
      let {clone_task_log,log_type} = this;
      let startId = clone_task_log[clone_task_log.length - 1].id;
      let res = await this.$http.request('/projectTask/getTaskLog', {taskId: task_id,querytype: log_type, startId});
      let append_task_log = res.contents.logList;
      if(append_task_log && append_task_log.length){
        this.$set(this,'clone_task_log', [...clone_task_log, ...append_task_log]);
        //是否有更多
        // console.log(append_task_log, append_task_log.length, append_task_log.length<20)
      }
      if(append_task_log.length < 20){
        this.nomore = true;
      }
      this.loading = false;
    },
  },
  async mounted(){
    await this.getTaskLog(1); // 默认时间排序
    this.$set(this, 'clone_task_log', this.task_log);
    let {clone_task_log} = this;
    if(!clone_task_log){
      this.nomore = true;
    }else if(clone_task_log && clone_task_log.length < 20){
      this.nomore = true;
    }else{
      this.nomore = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.pannel_record_wrapper{
  @include flex;
  @include bbox;
  flex-direction: column;
  width: 100%;
  height:100%;
  overflow: hidden;
  .title_bar{
    @include bbox;
    padding: 0 25px;
    width:100%;
    .title_group{
      width:100%;
      @include flexsb;
      border-bottom: 2px solid $border_color;
    }
    .title{
      @include bbox;
      @include transition;
      cursor: pointer;
      line-height: 65px;
      text-align: center;
      width: 30%;
      color: #333;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      border-bottom: 4px solid transparent;
      &.on{
        color: $main_blue;
        border-bottom-color: $main_blue;
      }
    }
  }
  .record_container{
    @include scrollbar;
    @include bbox;
    padding: 0 25px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    flex: 1;
    .content{
      @include bbox;
      padding: 10px 0;
      // 时间排序
      &.time{
        .unit{
          @include bbox;
          padding: 20px 0;
          border-bottom: 2px solid $border_color;
          font-size: 14px;
          .date{
            padding-bottom: 14px;
            color: $main_blue;
          }
          .name{
            color: #555;
            padding-bottom: 10px;
          }
          .details{
            font-size:14px;
            
            font-weight:400;
            color:#999;
            div{
              padding-bottom: 10px;
              word-break: break-all;
              &:last-child{
                padding-bottom: 0;
              }
            }
            .content_line{
              white-space: pre-wrap;
            }
          }
        }
      }
    }
    .loadmore_group{
      @include flex;
      justify-content: center;
      width:100%;
      padding: 10px 0 20px;
      .loadmore{
        font-size:14px;
        
        font-weight:400;
        color:rgba(15,94,171,1);
        padding-right: 5px;
      }
      .icon{
        height: 14px;
        width: 14px;
        background: {
          image: url(~@/assets/images/dialog/edit_task_loadmore.png);
          size: 10px 6px;
          position: center;
          repeat: no-repeat;
        }
      }
    }
  }
}
</style>