var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_net_user_list_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c("div", { staticClass: "container bbox d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass:
                  "title_group d-flex align-center un_sel justify-center flex-shrink-0",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.title) + "（" + _vm._s(_vm.list.length) + "）"
                  ),
                ]),
                _c("div", {
                  staticClass: "close cp",
                  on: {
                    click: function ($event) {
                      return _vm.doClose()
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "list bbox d-flex align-center flex-wrap" },
                [
                  _vm._l(_vm.list, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "user d-flex align-center flex-column flex-shrink-0",
                          on: {
                            click: function ($event) {
                              return _vm.openUserDetail(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "avatar_group" }, [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                alt: "",
                              },
                            }),
                            _vm.open_readed && item.isread
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "readed_group d-flex align-center justify-center",
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v("已读"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.priorityName(item))),
                          ]),
                        ]
                      ),
                    ]
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_users,
                          expression: "loading_users",
                        },
                      ],
                      staticClass:
                        "loading_group d-flex align-center justify-center",
                    },
                    [_c("loading-icon", { attrs: { size: "20px" } })],
                    1
                  ),
                  _vm.open_add
                    ? _c(
                        "div",
                        {
                          staticClass: "add_group cp un_sel",
                          on: {
                            click: function ($event) {
                              return _vm.touchAdd()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text bbox" }, [
                            _vm._v("添加"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }