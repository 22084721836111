<template>
  <div
    class="select_list d-flex flex-column align-stretch"
  >
    <div class="tag_list">
      <template
        v-for="(item, index) in levelList"
      >
        <div
          :key="index"
          class="tag d-flex align-center"
          :class="{
            [item.type]: Boolean(item.type),
            active: item.active
          }"
          @click.stop="selectLevel(item)"
        >
          <div class="select_icon"></div>
          <div
            v-if="item.type"
            class="type_icon"
            :class="{
              [item.type]: Boolean(item.type),
              active: item.active
            }"
          ></div>
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </template>
    </div>
    <div class="tag_list">
      <template
        v-for="(item, index) in statusList"
      >
        <div
          :key="index"
          class="tag d-flex align-center"
          :class="{
                [item.type]: Boolean(item.type),
                active: item.active
              }"
          @click.stop="selectStatus(item)"
        >
          <div class="select_icon"></div>
          <div
            v-if="item.type"
            class="type_icon"
            :class="{ [item.type]: Boolean(item.type) }"
          ></div>
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </template>
    </div>
    <div class="fun_group d-flex align-center">
      <div
        class="btn_dark btn cp"
        @click.stop="doSure()"
      >
        确定
      </div>
      <div
        class="btn_light btn cp"
        @click.stop="doClose()"
      >
        取消
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'SelectGroup',
  data() {
    return {
      levelSelectList: [],
      levelList: [
        { name: '无', value: '', active: true },
        { name: '紧急', value: 1, type: 'urgency', active: false },
        { name: '重要', value: 2, type: 'importance', active: false },
      ],
      statusList: [
        { name: '无', value: '', type: '', active: true },
        { name: '完成', value: 3, type: 'finish', active: false },
        { name: '未完成', value: 1, type: 'unfinished', active: false },
        { name: '暂停', value: 2, type: 'pause', active: false },
      ]
    }
  },
  computed: {
    task() {
      return store.state.task
    },
    levelStatus() {
      const { task } = this
      if (task && task.task) {
        return task.task.levelStatus ?? ''
      }
      return ''
    },
    processStatus() {
      const { task } = this
      if (task && task.task) {
        return task.task.processStatus ?? ''
      }
      return ''
    }
  },
  methods: {
    doSure() {
      const { levelList, statusList, levelSelectList } = this
      console.log(levelSelectList)
      let levelStatus = ''
      let processStatus = ''
      // levelList.forEach((item) => {
      //   if (item.value !== '' && item.active) {
      //     levelStatus += levelStatus === '' ? `${item.value}` : `,${item.value}`
      //   }
      // })
      // levelStatus = levelSelectList.join(',')
      levelSelectList.forEach((item) => {
        if (String(item) !== '0') {
          levelStatus += `${levelStatus === '' ? '' : ','}${item}`
        }
      })
      if (levelStatus === '') {
        levelStatus = 0
      }
      statusList.forEach((item) => {
        if (item.active) {
          processStatus = String(item.value)
        }
      })
      if (processStatus === '') {
        processStatus = 0
      }
      // 标记是否修改
      // if (String(store.state.task.task.levelStatus) !== String(levelStatus)){
      //   store.state.task.task.levelStatusModified = levelStatus
      // }
      // if (String(store.state.task.task.processStatus) !== String(processStatus)){
      //   store.state.task.task.processStatusModified = processStatus
      // }
      console.log(levelStatus,processStatus)
      store.state.task.task.levelStatus = String(levelStatus)
      store.state.task.task.processStatus = String(processStatus)
      this.doClose()
    },
    doClose() {
      this.$emit('handleClose')
    },
    init() {
      this.initList()
      const { levelStatus, processStatus, levelList, statusList } = this
      if ((levelStatus ?? '') !== '') {
        // 初始化已选择列表
        this.levelSelectList = levelStatus.split(',')
        const isUrgency = String(levelStatus).indexOf('1') !== -1
        const isImportance = String(levelStatus).indexOf('2') !== -1
        if (isUrgency || isImportance) {
          const aim = levelList.find((item) => {
            return item.value === ''
          })
          if (aim) {
            aim.active = false
          }
          levelList.forEach((item) => {
            if (isUrgency && item.value === 1) {
              item.active = true
            }
            if (isImportance && item.value === 2) {
              item.active = true
            }
          })
        }
      }
      if ((processStatus ?? '') !== '') {
        const isUnfinished = String(processStatus).indexOf('1') !== -1
        const isPause = String(processStatus).indexOf('2') !== -1
        const isFinish = String(processStatus).indexOf('3') !== -1
        if (isUnfinished || isPause || isFinish) {
          const aim = statusList.find((item) => {
            return item.value === ''
          })
          if (aim) {
            aim.active = false
          }
          statusList.forEach((item) => {
            if (isUnfinished && item.value === 1) {
              item.active = true
            }
            if (isPause && item.value === 2) {
              item.active = true
            }
            if (isFinish && item.value === 3) {
              item.active = true
            }
          })
        }
      }
    },
    /**
     * 列表恢复初始状态
     */
    initList() {
      const { levelList, statusList } = this
      levelList.forEach((item) => {
        if (item.value === '') {
          item.active = true
        } else {
          item.active = false
        }
      })
      statusList.forEach((item) => {
        if (item.value === '') {
          item.active = true
        } else {
          item.active = false
        }
      })
    },
    selectLevel(item) {
      const { levelList, levelSelectList } = this
      if (item.value === '') {
        item.active = true
        this.levelSelectList = []
        levelList.forEach((unit) => {
          if (unit.value !== '') {
            unit.active = false
          }
        })
        return
      }
      item.active = !item.active
      // this.$set(item, 'active', !item.active)
      if (item.active) {
        // 选中， 则将无项active设置为false
        const aim = levelList.find((unit) => {
          return unit.value === ''
        })
        if (aim) {
          aim.active = false
        }
        if (item.value !== '') {
          levelSelectList.push(item.value)
        }
      } else {
        const index = levelSelectList.findIndex((unit) => {
          return Number(unit) === item.value
        })
        if (index !== -1) {
          levelSelectList.splice(index, 1)
        }
      }

      // 如果全都为false 则选中无
      const activeAim = levelList.find((item) => {
        return item.active
      })
      if (activeAim === undefined) {
        const aim = levelList.find((unit) => {
          return unit.value === ''
        })
        aim.active = true
      }
    },
    selectStatus(item) {
      const { statusList } = this
      item.active = true
      statusList.forEach((unit) => {
        if (unit.value !== item.value) {
          unit.active = false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin tag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/#{$name}.png');
  }
}
@mixin unTag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/_#{$name}.png');
  }
}
.select_list{
  .tag_list{
    padding: 12px 0;
    border-bottom: 2px solid #F4F4F4;
    .tag{
      padding: 8px 0 ;
      width: 100%;
      cursor: pointer;
      &.active{
        .select_icon{
          border: 5px solid #0054A7;
        }
        .type_icon{
          @include tag(urgency);
          @include tag(importance);
          @include tag(finish);
          @include tag(unfinished);
          @include tag(pause);
        }
      }
      //&:hover{
      //  .type_icon{
      //    @include tag(urgency);
      //    @include tag(importance);
      //    @include tag(finish);
      //    @include tag(unfinished);
      //    @include tag(pause);
      //  }
      //}
      .select_icon{
        @include transition;
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #AAAAAA;
        margin-right: 20px;
      }
      .type_icon{
        @include background(20px, 20px);
        @include transition;
        @include unTag(urgency);
        @include unTag(importance);
        @include unTag(finish);
        @include unTag(unfinished);
        @include unTag(pause);
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      .name{
        font-size: 14px;
        font-family: MicrosoftYaHei,sans-serif;
        color: #333333;
        line-height: 19px;
      }
    }
  }
  .fun_group{
    padding: 12px 0;
    .btn{
      font-size: 12px;
      text-align: center;
      padding: 0 12px;
      line-height: 26px;
      border-radius: 4px;
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>