<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 16:26:47
 * @LastEditTime : 2021-11-24 11:21:33
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/Selected.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div 
    class="components_pop_up_select_contacts_selected bbox d-flex flex-column"
  >
    <div class="list">
      <div class="sticky_group">
        <div class="selected_number py-10 borderside_padding">已选择n位</div>
      </div>
      <template
        v-for="item in 40"
      > 
        <unit
          :key="item"
          class="py-10 borderside_padding"
          type="delete"
        ></unit>
      </template>
    </div>
  </div>
</template>
<script>
import Unit from './modules/Unit.vue';
export default {
  components: {
    Unit,
  }
}
</script>
<style lang="scss" scoped>
.components_pop_up_select_contacts_selected{
  padding: 16px 0;
  width: 300px;
  height: 100%;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
  font-size: 14px;
  color: #666666;
  .borderside_padding{
    padding: {
      left: 30px;
      right: 30px;
    }
  }
  .list{
    @include scrollbar;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    position: relative;
    .sticky_group{
      position: sticky;
      top:0;
      left: 0;
      background-color: #fff;
      // padding-right: 26px;
    }
  }
}
</style>