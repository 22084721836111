var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_select_contacts_selected bbox d-flex flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm._m(0),
          _vm._l(40, function (item) {
            return [
              _c("unit", {
                key: item,
                staticClass: "py-10 borderside_padding",
                attrs: { type: "delete" },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sticky_group" }, [
      _c("div", { staticClass: "selected_number py-10 borderside_padding" }, [
        _vm._v("已选择n位"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }