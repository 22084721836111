var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_task_sidebar_fun_page d-flex align-center bbox",
      class: {
        "flex-column": !_vm.switch_sidebar,
        close: !_vm.switch_sidebar,
      },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "item bbox cp",
              class: { active: item.active },
              on: {
                click: function ($event) {
                  return _vm.clickItem(item)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }