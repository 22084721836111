<template>
  <div
    class="components_pop_up_call_task_tag_page bbox d-flex align-end flex-shrink-0"
    ref="wrapper"
  >
    <div class="list d-flex align-center"
      :class="{'flex-wrap': switch_more, all: switch_more}"
    >
      <div class="tag flex-shrink-0"
        @click="clickAll"
      >全部</div>
      <template
        v-for="(item,index) in tag_list"
      >
        <div
          :key="index"
          class="tag flex-shrink-0 cp"
          :class="{active:item.active}"
          ref="tag"
          @click="changeTag(item)"
          v-if="switch_more ? 1 : (tag_max_num ? index<tag_max_num-3 : 1)"
        >{{item.tagName}}</div>
      </template>
    </div>
    <div class="button flex-shrink-0"
      v-if="tag_max_num"
      @click="switch_more=!switch_more"
    >{{switch_more? '收起' : '更多...'}}</div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      }
    }
  },
  data(){
    return{
      switch_more: false,
      tag_max_num: null,
      tag_list: [],
    }
  },
  computed: {
    tags(){
      const {tag_list} = this;
      let tags = '';
      tag_list.forEach(item=>{
        if(item.active){
          tags += tags ? `,${item.id}` : item.id;
        }
      })
      return tags;
    },
  },
  methods: {
    changeTag(item){
      this.$set(item, 'active', !item.active);
      // // console.log(this.tags);
      this.emitChange();
    },
    clickAll(){
      const {tag_list} = this;
      tag_list.forEach(item=>{
        if(item.active){
          this.$set(item, 'active', false);
        }
      })
      this.emitChange();
    },
    emitChange(){
      this.$emit('handleChange', this.tags);
    }
  },
  mounted(){
    this.tag_list = this.$tools.deepClone(this.list);
    this.$nextTick(function(){
      const wrapper = this.$refs.wrapper;
      const wrapper_width = wrapper.clientWidth;
      let tag = this.$refs.tag;
      for (let i in tag) {
        const offsetWidth = tag[i].offsetWidth;
        const offsetLeft = tag[i].offsetLeft;
        // // console.log(offsetWidth,offsetLeft)
        // // console.log(wrapper_width - (offsetWidth+offsetLeft))
        if(wrapper_width < (offsetWidth+offsetLeft)){
          if(this.tag_max_num == null){
            this.tag_max_num = i;
          }
        }
        // if (wrapper_width > (offsetWidth+offsetLeft)) { //超过 
        //     this.$set(this.list[i], 'show', true);
        // } else {
        //     this.$set(this.list[i], 'show', false);
        // }
      }
    })
    
  }
}
</script>
<style lang="scss" scoped>
.components_pop_up_call_task_tag_page{
  line-height: 30px;
  font-size: 14px;
  flex: 1;
  overflow: auto;
  .list{
    overflow: auto;
    position: relative;
    flex: 1;
    &.all{
      @include scrollbar;
      height: 36*3px;
    }
    .tag{
      background: #E9E9E9;
      border-radius: 16px;
      color: #555;
      padding: 0 12px;
      margin-right: 10px;
      margin-bottom: 6px;
      &:last-child{
        margin-right: 0;
      }
      &.active{
        color: $main_blue;
        background-color: #DFE7F0;
      }
    }
  }
  .button{
    font-size: 12px;
    line-height: 30px;
    padding-left: 10px;
    color: $main_blue;
    padding-right: 5px;
    margin-bottom: 6px;
  }
}
</style>