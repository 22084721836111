var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select_contact_content_wrapper" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("选择联系人")]),
      _c("div", {
        staticClass: "close cp",
        on: { click: _vm.closeSelectContact },
      }),
    ]),
    _c("div", { staticClass: "content_group" }, [
      _c(
        "div",
        {
          staticClass: "select_list_group list_group",
          class: { cantselect: !_vm.optional, disabled: !_vm.optional },
        },
        [
          _c(
            "div",
            { staticClass: "group_wrapper" },
            [
              _c("div", { staticClass: "search_group" }, [
                _c("div", { staticClass: "search_box" }, [
                  _c("div", { staticClass: "icon" }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.list_keyword,
                        expression: "list_keyword",
                      },
                    ],
                    staticClass: "search",
                    attrs: { type: "text", placeholder: "搜索" },
                    domProps: { value: _vm.list_keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.list_keyword = $event.target.value
                      },
                    },
                  }),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.list_keyword,
                        expression: "list_keyword",
                      },
                    ],
                    staticClass: "clear",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.list_keyword = ""
                      },
                    },
                  }),
                ]),
              ]),
              _vm.message_box_list
                ? [
                    _c("div", { staticClass: "hint" }, [_vm._v("最近...")]),
                    _vm._l(_vm.message_box_list, function (item, index) {
                      return [
                        _vm.devSign &&
                        index < 10 &&
                        Number(item.chatId) !== 5494 &&
                        Number(item.chatType) !== 0
                          ? _c("thumb-unit", {
                              key: `messageBoxList${index}`,
                              class: {
                                on:
                                  Number(item.chatId) ===
                                  Number(_vm.select_project_id),
                                disabled: !_vm.optional,
                              },
                              attrs: { item: item, search: _vm.list_keyword },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.messageBoxListHandler(item)
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.devSign &&
                        index < 10 &&
                        item.chatId != 5494 &&
                        item.chatType != 0
                          ? _c(
                              "div",
                              {
                                key: `${
                                  item.id || item.chatId
                                }select_list_item`,
                                staticClass: "select_list_item select_item cp",
                                class: {
                                  on: item.chatId == _vm.select_project_id,
                                  disabled: !_vm.optional,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.messageBoxListHandler(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "name_group" },
                                  [
                                    item.chatType == "0" &&
                                    (!_vm.list_keyword ||
                                      (_vm.list_keyword &&
                                        item.friendRemark.indexOf(
                                          _vm.list_keyword
                                        ) !== -1))
                                      ? [
                                          _c("img", {
                                            staticClass: "avatar my-10",
                                            attrs: {
                                              src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                              alt: "",
                                            },
                                          }),
                                          _c("div", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.friendRemark)),
                                          ]),
                                        ]
                                      : _vm._e(),
                                    item.chatType == "1" &&
                                    (!_vm.list_keyword ||
                                      (_vm.list_keyword &&
                                        item.name.indexOf(
                                          _vm.list_keyword.toLowerCase()
                                        ) !== -1) ||
                                      item.name.indexOf(
                                        _vm.list_keyword.toUpperCase()
                                      ) !== -1)
                                      ? [
                                          _c("img", {
                                            staticClass: "avatar my-10",
                                            attrs: {
                                              src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                              alt: "",
                                            },
                                          }),
                                          _c("div", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "hint" }, [_vm._v("联系人")]),
              _vm._l(_vm.friends_list_group, function (item, index) {
                return [
                  0
                    ? _c(
                        "div",
                        {
                          key: `${index}friends_list_group`,
                          staticClass: "hint",
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(index) + "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(item, function (subval, subindex) {
                    return [
                      _vm.devSign
                        ? _c("thumb-unit", {
                            key: `${index}-${subindex}`,
                            class: {
                              on: subval.userId == _vm.select_project_id,
                            },
                            attrs: {
                              item: subval,
                              search: _vm.list_keyword,
                              type: "contact",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clickFriendList(subval)
                              },
                            },
                          })
                        : _vm._e(),
                      !_vm.devSign
                        ? _c(
                            "div",
                            {
                              key: `${subval.userId}friends_list_groupsubindex`,
                              staticClass: "select_list_item select_item",
                              class: {
                                on: subval.userId == _vm.select_project_id,
                              },
                            },
                            [
                              !_vm.list_keyword ||
                              (_vm.list_keyword &&
                                (subval.friendRemark.indexOf(
                                  _vm.list_keyword.toLowerCase()
                                ) !== -1 ||
                                  subval.friendRemark.indexOf(
                                    _vm.list_keyword.toUpperCase()
                                  ) !== -1 ||
                                  subval.userName.indexOf(
                                    _vm.list_keyword.toLowerCase()
                                  ) !== -1 ||
                                  subval.userName.indexOf(
                                    _vm.list_keyword.toLowerCase()
                                  ) !== -1))
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "name_group",
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickFriendList(subval)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "avatar my-10",
                                        attrs: {
                                          src: `${_vm.$avatar_url}${subval.thumbnail}@!small200`,
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.list_keyword
                                              ? subval.friendRemark.indexOf(
                                                  _vm.list_keyword.toLowerCase()
                                                ) !== -1 ||
                                                subval.friendRemark.indexOf(
                                                  _vm.list_keyword.toUpperCase()
                                                ) !== -1
                                                ? subval.friendRemark
                                                : subval.userName
                                              : subval.friendRemark ||
                                                  subval.userName
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ]
              }),
              _c("div", { staticClass: "hint" }, [_vm._v("工作组")]),
              _vm.devSign
                ? [
                    _vm._l(_vm.workteam_list, function (item, index) {
                      return [
                        _c("thumb-unit", {
                          key: index,
                          class: {
                            on:
                              Number(item.chatId) ===
                              Number(_vm.select_project_id),
                          },
                          attrs: {
                            item: item,
                            type: "workteam",
                            search: _vm.list_keyword,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickWorkteamList(item)
                            },
                          },
                        }),
                      ]
                    }),
                  ]
                : _vm._e(),
              !_vm.devSign
                ? _vm._l(_vm.workteam_list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: `workteam${item.chatId}${index}`,
                        staticClass: "select_list_item select_item",
                        class: { on: item.chatId == _vm.select_project_id },
                        on: {
                          click: function ($event) {
                            return _vm.clickWorkteamList(item)
                          },
                        },
                      },
                      [
                        !_vm.list_keyword ||
                        (_vm.list_keyword &&
                          (item.chatName.indexOf(
                            _vm.list_keyword.toLowerCase()
                          ) !== -1 ||
                            item.chatName.indexOf(
                              _vm.list_keyword.toUpperCase()
                            ) !== -1))
                          ? _c("div", { staticClass: "name_group" }, [
                              _c("img", {
                                staticClass: "avatar my-10",
                                attrs: {
                                  src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.chatName)),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c("div", { staticClass: "selected_project_group list_group" }, [
        _c(
          "div",
          { staticClass: "group_wrapper" },
          [
            _vm.select_project_type == "1"
              ? _c("div", { staticClass: "search_group" }, [
                  _c("div", { staticClass: "search_box" }, [
                    _c("div", { staticClass: "icon" }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detail_keyword,
                          expression: "detail_keyword",
                        },
                      ],
                      staticClass: "search",
                      attrs: { type: "text", placeholder: "搜索" },
                      domProps: { value: _vm.detail_keyword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.detail_keyword = $event.target.value
                        },
                      },
                    }),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.detail_keyword,
                          expression: "detail_keyword",
                        },
                      ],
                      staticClass: "clear",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.detail_keyword = ""
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm.select_project &&
            _vm.select_project.length &&
            _vm.select_project_type == "1"
              ? [
                  _vm.select_project_type == "1"
                    ? _c(
                        "div",
                        {
                          staticClass: "select_all_group cp",
                          class: { on: _vm.is_all },
                          on: { click: _vm.doSelectAll },
                        },
                        [
                          _c("div", { staticClass: "text" }, [_vm._v("全选")]),
                          _vm._m(0),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.select_project, function (item, index) {
                    return [
                      _vm.devSign &&
                      Number(item.userId) !== Number(_vm.task_lord_id)
                        ? _c("select-unit", {
                            key: `${index}list_group`,
                            ref: "selectUnit",
                            refInFor: true,
                            attrs: { item: item, search: _vm.detail_keyword },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectItem(item, index)
                              },
                            },
                          })
                        : _vm._e(),
                      !_vm.devSign && item.userId != _vm.task_lord_id
                        ? _c(
                            "div",
                            {
                              key: `${index}list_group`,
                              staticClass:
                                "selected_project_item select_item cp",
                              on: {
                                click: function ($event) {
                                  return _vm.selectItem(item, index)
                                },
                              },
                            },
                            [
                              !_vm.detail_keyword ||
                              (_vm.detail_keyword &&
                                (item.friendRemark.indexOf(
                                  _vm.detail_keyword.toLowerCase()
                                ) !== -1 ||
                                  item.friendRemark.indexOf(
                                    _vm.detail_keyword.toUpperCase()
                                  ) !== -1 ||
                                  item.chatUserName.indexOf(
                                    _vm.detail_keyword.toLowerCase()
                                  ) !== -1 ||
                                  item.chatUserName.indexOf(
                                    _vm.detail_keyword.toUpperCase()
                                  ) !== -1 ||
                                  item.userName.indexOf(
                                    _vm.detail_keyword.toLowerCase()
                                  ) !== -1 ||
                                  item.userName.indexOf(
                                    _vm.detail_keyword.toUpperCase()
                                  ) !== -1))
                                ? [
                                    _c("div", { staticClass: "name_group" }, [
                                      _c("img", {
                                        staticClass: "avatar my-10",
                                        attrs: {
                                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detail_keyword
                                              ? item.friendRemark.indexOf(
                                                  _vm.detail_keyword.toLowerCase()
                                                ) !== -1 ||
                                                item.friendRemark.indexOf(
                                                  _vm.detail_keyword.toUpperCase()
                                                ) !== -1
                                                ? item.friendRemark
                                                : item.chatUserName.indexOf(
                                                    _vm.detail_keyword.toLowerCase()
                                                  ) !== -1 ||
                                                  item.chatUserName.indexOf(
                                                    _vm.detail_keyword.toUpperCase()
                                                  ) !== -1
                                                ? item.chatUserName
                                                : item.userName
                                              : item.friendRemark ||
                                                  item.chatUserName ||
                                                  item.userName
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", {
                                      staticClass: "selected cp",
                                      class: { on: item.on },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ]
              : _vm._e(),
            _vm.select_project && _vm.select_project_type == "0"
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "selected_project_item select_item un_hover",
                      style: { "padding-top": "16px" },
                    },
                    [
                      _c("div", { staticClass: "name_group" }, [
                        _vm.select_project.thumbnail
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${_vm.select_project.thumbnail}@!small200`,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm.select_project.friendRemark
                          ? _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.select_project.friendRemark)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", {
                        staticClass: "selected cp",
                        class: { on: _vm.select_project.on },
                      }),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "selected_list_group list_group" },
        [
          _vm.selected_list && _vm.select_project_type == "0"
            ? _vm._l(_vm.selected_list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `${item.userId}selected_list`,
                    staticClass: "selected_list_item select_item",
                  },
                  [
                    _c("div", { staticClass: "name_group" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.friendRemark || item.userName)),
                      ]),
                    ]),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: 0,
                          expression: "0",
                        },
                      ],
                      staticClass: "del",
                    }),
                  ]
                )
              })
            : _vm._e(),
          _vm.selected_list && _vm.select_project_type == "1"
            ? [
                _vm._l(_vm.selected_list, function (item, index) {
                  return [
                    item.userId != _vm.task_lord_id
                      ? _c(
                          "div",
                          {
                            key: `${item.userId}selected_list1`,
                            staticClass: "selected_list_item select_item",
                            style: {
                              "padding-top": "11px",
                              "padding-bottom": "11px",
                            },
                          },
                          [
                            _c("div", { staticClass: "name_group" }, [
                              _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(
                                  _vm._s(item.friendRemark || item.userName)
                                ),
                              ]),
                            ]),
                            _c("div", {
                              staticClass: "del cp",
                              on: {
                                click: function ($event) {
                                  return _vm.delSelectedHandler(item, index)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "fun sure cp", on: { click: _vm.doSure } }, [
        _vm._v("确定"),
      ]),
      _c(
        "div",
        { staticClass: "fun cancle cp", on: { click: _vm.closeSelectContact } },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select_box" }, [
      _c("div", { staticClass: "select cp" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }