var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_call_task_search_type_page bbox d-flex align-center",
      class: { fixed_width: _vm.search_type !== "custom" },
    },
    [
      _vm.appoint === null
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-start",
                "popper-class":
                  "components_pop_up_call_task_search_type_page_popover",
                trigger: "click",
                "visible-arrow": false,
              },
              model: {
                value: _vm.switch_popover,
                callback: function ($$v) {
                  _vm.switch_popover = $$v
                },
                expression: "switch_popover",
              },
            },
            [
              _c(
                "div",
                { staticClass: "list" },
                [
                  _vm._l(_vm.type_list, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass: "item bbox cp",
                          class: { on: _vm.search_type == item.type },
                          on: {
                            click: function ($event) {
                              return _vm.changeType(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c("template", { slot: "reference" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "type_group bbox d-flex align-center cp flex-shrink-0",
                  },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.search_type_name) +
                          "\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "icon" }),
                  ]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "input_group" }, [
        _vm.search_type !== "custom"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.keyword,
                  expression: "keyword",
                },
              ],
              staticClass: "input bbox",
              attrs: { type: "text", placeholder: "搜索" },
              domProps: { value: _vm.keyword },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doSearch($event)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.keyword = $event.target.value
                },
              },
            })
          : _vm._e(),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.keyword,
              expression: "keyword",
            },
          ],
          staticClass: "clear cp",
          on: {
            click: function ($event) {
              return _vm.clearKeyword()
            },
          },
        }),
      ]),
      _vm.show_package_or_task
        ? _c(
            "div",
            {
              staticClass: "package_or_task d-flex align-center flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "item cp",
                  class: { active: _vm.task_active },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showTask()
                    },
                  },
                },
                [_vm._v("\n      教学模板\n    ")]
              ),
              _c(
                "div",
                {
                  staticClass: "item cp",
                  class: { active: _vm.package_active },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showPackage()
                    },
                  },
                },
                [_vm._v("\n      教学包\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }