var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_sidebar_widget_container_page bbox" },
    [
      _c(
        "div",
        { staticClass: "wrapper d-flex flex-column" },
        [
          _c(
            "draggable",
            {
              staticClass:
                "control_draggable d-flex flex-wrap align-center flex-shrink-0",
              attrs: {
                list: _vm.list,
                group: {
                  name: "widget",
                  pull: _vm.editable ? "clone" : false,
                  put: false,
                },
                clone: _vm.cloneWidget,
                sort: false,
              },
            },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  !(_vm.public_notice && item.type === 11)
                    ? _c("widget-button", {
                        key: index,
                        staticClass: "cp",
                        attrs: {
                          name: item.name,
                          title: item.title,
                          type: item.type,
                          editable: _vm.editable,
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickNewWidget(item)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm.copyWidgetList && _vm.copyWidgetList.length
            ? _c("div", { staticClass: "reference_gruop" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "title_group d-flex align-center justify-space-between",
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n          复制的控件 [" +
                          _vm._s(_vm.copyWidgetList.length) +
                          "]\n        "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "clear cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clearCopyList.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n          清空\n        ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "copy_list_group" },
                  [
                    _c(
                      "draggable",
                      {
                        staticClass: "control_draggable flex-shrink-0",
                        attrs: {
                          list: _vm.copyWidgetList,
                          group: {
                            name: "widget",
                            pull: _vm.editable ? "clone" : false,
                            put: false,
                          },
                          clone: _vm.pasteWidget,
                          sort: false,
                        },
                      },
                      [
                        _vm._l(_vm.copyWidgetList, function (item, index) {
                          return [
                            _c("CopyButton", {
                              key: index,
                              attrs: { "p-task-detail": item },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.clickCopyWidget(item)
                                },
                              },
                            }),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }