var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_sidebar_teach_container_page bbox" },
    [
      _c("page-start", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page_status == "start",
            expression: "page_status=='start'",
          },
        ],
        attrs: { can_teach: _vm.can_teach },
        on: { handleChangePage: _vm.changePage },
      }),
      _c("page-price", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page_status == "price",
            expression: "page_status == 'price'",
          },
        ],
        ref: "page_price",
        on: { handleChangePage: _vm.changePage },
      }),
      _c("page-make", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page_status == "make",
            expression: "page_status == 'make'",
          },
        ],
        attrs: {
          edit: _vm.edit,
          use: _vm.use,
          preview: _vm.preview,
          maintain: _vm.maintain,
          buy: _vm.buy,
          sell: _vm.sell,
          modify_mark: _vm.modify_mark,
          show_intro: _vm.show_intro,
          shopping_mail: _vm.shopping_mail,
        },
        on: {
          handleChangePage: _vm.changePage,
          handleSubmit: _vm.doSumbit,
          handleSaveDraft: _vm.saveDraft,
          handleSavaTask: _vm.handleSavaTask,
          handleReEdit: _vm.handleReEdit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }