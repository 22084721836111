<template>
  <div
    v-if="showUnit"
    class="c_fun_template_select_contace_content_select_unit d-flex justify-space-between"
  >
    <div
      class="name_group"
    >
      <img
        :src="`${$avatar_url}${item.thumbnail}@!small200`"
        alt
        class="avatar my-10"
      >
      <div
        class="name"
        v-html="name"
      >
        {{ name }}
      </div>
    </div>
    <div
      class="selected cp"
      :class="{ on }"
    ></div>
  </div>
</template>

<script>
import tools from '@/api/tools'
import store from '@/store'
export default {
  name: 'SelectUnit',
  props: {
    item: {
      type: Object,
      default: undefined
    },
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    showUnit() {
      const { search, nameForSearch } = this
      if (search !== '') {
        return (nameForSearch ?? '') !== ''
      }
      return true
    },
    name() {
      const { item, search, nameForSearch } = this
      const { friends_list } = store.state
      const { userName, chatUserName, userId } = item
      const aim = friends_list.find((unit) => {
        return unit.friendUserId === userId
      })
      let friendRemark
      if (aim) {
        friendRemark = aim.friendRemark
      }
      if (search !== '' && ((nameForSearch ?? '') !== '')) {
        return nameForSearch
      }
      return friendRemark || chatUserName || userName
    },
    nameForSearch() {
      const { item, search } = this
      const { friends_list } = store.state
      const { userName, chatUserName, userId } = item
      const aim = friends_list.find((unit) => {
        return unit.friendUserId === userId
      })
      let friendRemark
      if (aim) {
        friendRemark = aim.friendRemark
      }
      if (search !== '') {
        const remarkRes = this.searchName(friendRemark)
        if ((remarkRes ?? '') !== '') {
          return remarkRes
        }
        const chatRes = this.searchName(chatUserName)
        if ((chatRes ?? '') !== '') {
          return chatRes
        }
        const nameRes = this.searchName(userName)
        return nameRes
      }
      return undefined
    },
    on() {
      const { item } = this
      // console.log(item, item.on)
      return item.on
    }
  },
  methods: {
    searchName(str) {
      const { search } = this
      if (((str ?? '') !== '') && str.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return tools.highlightKeywords(str, search)
      }
      return undefined
      // console.log('searchName')
    }
  }
}
</script>

<style scoped lang="scss">
.c_fun_template_select_contace_content_select_unit{
  @include bbox;
  @include transition;
  width: 100%;
  padding: {
    left: 36px;
    right: 28px;
    // top: 11px;
    // bottom: 11px;
  }
  &:hover,
  &.on {
    background-color: #eee;
  }
  &.un_hover:hover{
    background-color: transparent;
  }
  .name_group {
    @include bbox;
    @include flex;
    flex: 1;
    width: 100%;
    overflow: hidden;
    .avatar {
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: block;
      background-color: $img_color;
    }
    .name {
      @include bbox;
      flex: 1;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
      font-size: 14px;

      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
  }
  .selected {
    @include transition;
    @include bbox;
    width: 18px;
    height: 18px;
    border: 2px solid #999;
    border-radius: 50%;
    &.on {
      border: none;
      background: {
        image: url(~@/assets/images/common/selected.png);
        size: 100%;
      }
    }
  }
}
</style>