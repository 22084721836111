<template>
  <div
    class="components_pop_up_call_task_search_type_page bbox d-flex align-center"
    :class="{fixed_width: search_type !== 'custom'}"
  >
    <el-popover
      placement="bottom-start"
      popper-class="components_pop_up_call_task_search_type_page_popover"
      trigger="click"
      v-model="switch_popover"
      :visible-arrow="false"
      v-if="appoint === null"
    >
      <div class="list">
        <template
          v-for="(item, index) in type_list"
        >
          <div
            class="item bbox cp"
            :key="index"
            :class="{on: search_type == item.type}"
            @click="changeType(item)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <template
        slot="reference"
      >
        <div
          class="type_group bbox d-flex align-center cp flex-shrink-0"
        >
          <div
            class="text"
          >
            {{ search_type_name }}
          </div>
          <div
            class="icon"
          ></div>
        </div>
      </template>
    </el-popover>
    <div class="input_group">
      <input
        v-if="search_type !== 'custom'"
        type="text"
        class="input bbox"
        v-model="keyword"
        placeholder="搜索"
        @keydown.enter="doSearch($event)"
      >
      <div
        v-show="keyword"
        class="clear cp"
        @click="clearKeyword();"
      ></div>
    </div>
    <div
      v-if="show_package_or_task"
      class="package_or_task d-flex align-center flex-shrink-0"
    >
      <div
        class="item cp"
        :class="{active: task_active}"
        @click.stop="showTask()"
      >
        教学模板
      </div>
      <div
        class="item cp"
        :class="{active: package_active}"
        @click.stop="showPackage()"
      >
        教学包
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import tools from '@/api/tools.js'
export default {
  data(){
    return{
      switch_popover: false,
      search_type: 'default', // 'custom' / 'craft'
      search_type_name: '默认',
      type_list: [
        {type: 'default', name: '默认'},
        {type: 'purchase', name: '我购买的'},
        {type: 'sell', name: '我销售的'},
        {type: 'custom', name: '我收藏的'},
        {type: 'craft', name: '草稿'},
      ],
      keyword: '',
    }
  },
  computed: {
    show_package_or_task(){
      const {search_type} = this
      if(search_type === 'purchase'){
        return true
      }
      if(search_type === 'sell'){
        return true
      }
      return false
    },
    package_active(){
      const {task_call_config} = this
      console.log(task_call_config)
      return task_call_config.package
    },
    task_active(){
      const {task_call_config} = this
      console.log(task_call_config)
      return !task_call_config.package
    },
  },
  props: {
    appoint: {
      type: Object,
      default: null,
    },
    props_keyword: {
      type: String,
      default: '',
    },
    task_call_config: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    this.keyword = this.props_keyword
  },
  methods: {
    reset() {
      this.keyword = ''
      this.changeType({
        type: 'default',
        name: '默认'
      })
    },
    changeType(item) {
      this.task_call_config.package = false
      this.task_call_config.package_id = undefined
      this.task_call_config.p_package = undefined
      this.switch_popover = false
      this.search_type = item.type
      this.keyword=''
      this.search_type_name = item.name
      if (item.type === 'custom') {
        store.commit('set_collect_nav', undefined)
        tools.removeCookie('collect_nav')
      }
      this.$emit('handleChange', {
        type:item.type,
        keyword: ''
      })
    },
    chineseHandler(e) {
      let status = false
      if (e.keyCode === 229) {
        status = true
      }
      return status
    },
    doSearch(e) {
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e)
      if (is_chinese) {
        return false
      }
      this.changeKeyword()
    },
    changeKeyword() {
      console.log(this.keyword)
      this.$emit('handleChange', { keyword:this.keyword.trim() })
    },
    clearKeyword() {
      this.keyword = ''
      this.changeKeyword()
    },
    showPackage() {
      this.keyword = ''
      this.changeKeyword()
      this.task_call_config.package = true
      this.task_call_config.package_id = undefined
      this.task_call_config.p_package = undefined
    },
    showTask() {
      this.keyword = ''
      this.changeKeyword()
      this.task_call_config.package = false
      this.task_call_config.package_id = undefined
      this.task_call_config.p_package = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_call_task_search_type_page{
  height: 30px;
  background-color: #E9E9E9;
  border-radius: 4px;
  font-size: 14px;
  line-height: 30px;
  color: #333;
  margin-right: 16px;
  position: relative;
  &.fixed_width{
    // width: 340px;
  }
  .type_group{
    @include transition;
    padding: 0 12px;
    background-color: #d4d4d4;
    .text{
      padding-right: 7px;
    }
    .icon{
      width: 9px;
      height: 6px;
      background-image: url(~@/assets/images/call_task/search_type.png);
    }
  }
  .input_group{
    position: relative;
  }
  .input{
    border:none;
    outline: none;
    font-size: 14px;
    line-height: 30px;
    background-color: #E9E9E9;
    padding: {
      left: 10px;
      right: 30px;
    }
    flex: 1;
  }
  .clear{
    @include background(16px, 16px);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    background-image: url(~@/assets/images/task/close_tag.png);

  }
  .package_or_task{
    background-color: #f8f8f8;
    height: 30px;
    .item{
      @include transition;
      font-size: 14px;
      color: #333;
      padding: 0 10px;
      line-height: 18px;
      &:hover, &.active{
        color: $main_blue;
      }
      &:first-child{
        border-right: 1px solid #eee;
      }
    }
  }
}
</style>

<style lang="scss">
.components_pop_up_call_task_search_type_page_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  .list{
    .item{
      @include transition;
      padding: 0 10px;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      &:hover{
        background-color: #eee;
      }
      &.on{
        background-color: #ddd;
        font-weight: bold;
        &:hover{
          background-color: #ddd;
        }
      }
    }
  }
}
</style>