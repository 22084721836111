var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_popup_call_task_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c("div", { staticClass: "container bbox d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass:
                  "title_group d-flex align-center un_sel justify-center flex-shrink-0",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n          调用新建任务\n        "),
                ]),
                _c("div", {
                  staticClass: "close cp",
                  on: {
                    click: function ($event) {
                      return _vm.doClose()
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "main d-flex flex-column" }, [
              _vm.appoint !== null
                ? _c(
                    "div",
                    {
                      staticClass:
                        "appoint_group flex-shrink-0 d-flex align-center bbox",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "group d-flex align-center bbox" },
                        [
                          _c("div", {
                            staticClass: "clear cp",
                            on: {
                              click: function ($event) {
                                return _vm.changeAppoint(null)
                              },
                            },
                          }),
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: `${_vm.$avatar_url}${_vm.appoint.thumbnail}@!small200`,
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.appoint.name) + ":"),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "search_group flex-shrink-0 d-flex align-start bbox",
                },
                [
                  _c("search-type", {
                    ref: "search_type",
                    attrs: {
                      task_call_config: _vm.task_call_config,
                      appoint: _vm.appoint,
                      props_keyword: _vm.search_keyword,
                    },
                    on: { handleChange: _vm.changeSearch },
                  }),
                  _vm.tag_list.length && _vm.tag_status
                    ? _c("tag", {
                        attrs: { list: _vm.tag_list },
                        on: { handleChange: _vm.changeTag },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "search_content bbox" }, [
                _c(
                  "div",
                  { staticClass: "default_wrapper" },
                  [
                    _vm.is_list
                      ? _c("default-list", {
                          ref: "default_list",
                          attrs: {
                            type: _vm.search_type,
                            appoint: _vm.appoint,
                            keyword: _vm.search_keyword,
                            tags: _vm.search_tags,
                          },
                          on: { handleCallTask: _vm.callTask },
                        })
                      : _vm._e(),
                    _vm.result_status
                      ? _c("search-result", {
                          ref: "search_result",
                          attrs: { keyword: _vm.search_keyword },
                          on: {
                            handleCallTask: _vm.callTask,
                            handleAppoint: _vm.changeAppoint,
                          },
                        })
                      : _vm._e(),
                    _vm.custom_status
                      ? _c("custom", { on: { handleCallTask: _vm.callTask } })
                      : _vm._e(),
                    _vm.sell_status
                      ? _c("purchase-and-sell", {
                          ref: "purchaseAndSell",
                          attrs: { task_call_config: _vm.task_call_config },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.switch_custom
        ? _c("custom-classify", {
            on: { handleClose: _vm.closeCustom, handleCallTask: _vm.callTask },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }