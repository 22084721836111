<template>
  <div
    class="components_task_sidebar_fun_page d-flex align-center bbox"
    :class="{ 
      'flex-column': !switch_sidebar, 
      'close': !switch_sidebar,
    }"
  >
    <template
      v-for="(item,index) in list"
    >
      <div
        :key="index"
        :class="{active: item.active}"
        class="item bbox cp"
        @click="clickItem(item)"
      >{{item.name}}</div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    switch_sidebar: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    clickItem(item){
      this.$emit('handleClick', item.type);
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_fun_page{
  font-size: 14px;
  color:#333;
  width: 100%;
  &.close{
    .item{
      line-height: 16px;
      padding: 10px 0;
      height: auto;
      &.active{
        border-bottom: none;
      }
    }
  }
  .item{
    margin: 0 15px;
    line-height: 50px;
    height: 50px;
    &.active{
      border-bottom: 3px solid $main_blue;
      color: $main_blue;
    }
  }
}
</style>