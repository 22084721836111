<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 16:41:19
 * @LastEditTime : 2021-11-24 16:08:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/Main.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_select_contacts_main d-flex bbox"
  >
    <dialog-list
      v-if="select_target"
    ></dialog-list>
    <list
      :dialog_user_list="dialog_user_list"
    ></list>
    <selected></selected>
  </div>
</template>

<script>
import DialogList from './DialogList.vue';
import List from './List.vue';
import Selected from './Selected.vue';
export default {
  props: {
    column: {
      type: Number,
      default: 2,
    },
    dialog_user_list: {
      type: Array,
      default: null,
    },
    select_target: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogList,
    List,
    Selected,
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_main{
  overflow: auto;
}
</style>
