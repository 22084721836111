<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 10:21:25
 * @LastEditTime : 2022-04-21 13:42:02
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/Consulting.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_consulting_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0">
          <div class="title">咨询</div>
          <div class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main bbox">
          <div class="hint">{{hint}}</div>
          <!-- 您已提交过信息，请保持手机畅通，耐心等待工作人员与您联系。 -->
          <div class="line bbox d-flex">
            <div class="key un_sel bbox required">真实姓名</div>
            <div class="val_group">
              <input type="text" class="name bbox"
                :class="{readonly: !edit}"
                :readonly="!edit"
                v-model="name"
              >
              <div class="modify button"
                v-if="0"
              >修改</div>
            </div>
          </div>
          <div class="line bbox d-flex">
            <div class="key un_sel bbox required">联系方式</div>
            <div class="val_group fulid">
              <input type="text" class="phone bbox"
                :class="{readonly: !edit}"
                :readonly="!edit"
                v-model="phone"
              >
              <div class="modify button"
                v-if="0"
              >修改</div>
            </div>
          </div>
          <div class="line bbox d-flex">
            <div class="key un_sel bbox">您的身份</div>
            <div class="val_group fulid">
              <el-radio-group
                v-model="identity"
                fill="#0054A7"
                :disabled="!edit"
              >
                <el-radio :label="1">教师</el-radio>
                <el-radio :label="2">学生</el-radio>
                <el-radio :label="3">职工</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line bbox d-flex">
            <div class="key un_sel bbox">学校名称</div>
            <div class="val_group fulid">
              <input type="text" class="school bbox"
                v-model="school"
                :class="{readonly: !edit}"
                :readonly="!edit"
              >
              <div class="modify button"
                v-if="0"
              >修改</div>
            </div>
          </div>
          <div class="line bbox d-flex">
            <div class="key un_sel bbox required">简短描述</div>
            <div class="val_group fulid">
              <el-input
                v-model="sketch"
                placeholder="请输入简短描述"
                type="text"
                resize="none"
                :autosize="{ minRows: 1, maxRows: 6 }"
                show-word-limit
                maxlength="80"
                class="textarea"
                :class="{readonly: !edit}"
                :readonly="!edit"
              ></el-input>
            </div>
          </div>
          <div class="operating_button btn_dark"
            @click="doSubmit();"
            v-if="edit"
          >完成</div>
          <div class="operating_button btn_dark"
            v-if="!edit"
            @click="reConsulting()"
          >再次咨询</div>
        </div>
  

      </div>
    </div>

  </div>
</template>

<script>

// import MainPart from './Reminder/Main.vue';
// import ListPart from './Reminder/List.vue';
export default {
  name: 'reminder',
  components: {
  },
  props: {
    param_id: {
      type: Number,
      default: null,
    },
    enquire_type: {
      type: Number,
      default: 0,
    }
  },
  data(){
    return {
      index: 1,
      // hint: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
      name: '',
      phone: '',
      identity: '',
      school: '',
      sketch: '',
      edit: true,
      id: '',
    }
  },
  computed:{
    hint(){
      const {edit} = this;
      if(edit){
        return '您好，请确认并填写以下信息，方便工作人员与您及时联系。';
      }
      return '您已提交过信息，请保持手机畅通，耐心等待工作人员与您联系。';
    },
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    reConsulting(){
      this.edit = true;
    },
    async query(){
      const { param_id, enquire_type } = this
      const res = await this.$api.consulting.query({paramId: param_id, enquireType:enquire_type})
      // console.log(res);
      const { contents, message } = res
      const { PEnquire } = contents
      if (message === 'success' && PEnquire) {
        this.edit = false
        const {
          content,
          id,
          identities,
          name,
          phone,
          school,
        } = PEnquire
        // this.hint = '您已提交过信息，请保持手机畅通，耐心等待工作人员与您联系。'
        this.name = name
        this.phone = phone
        this.identity = identities
        this.school = school
        this.sketch = content
        this.id = id
      }else{
        const user = this.$tools.getCookie('user')
        // console.log(user)
        this.name = user.userRealName
        this.phone = user.phone
        this.identity = user.identity || ''
        this.school = user.ownedEnterprises || ''
        this.sketch = ''
      }
      
    },
    async init(){
      await this.query();
    },
    
    async doSubmit(){
      const {name, phone, identity, school, sketch, param_id, enquire_type, id} = this;
      if(name.trim() == ''){
        this.$noitce({desc: '请输入姓名'});
        return;
      }
      const is_right_phone = this.$regexp.phone.test(phone);
      if(!is_right_phone){
        this.$notice({desc: '请输入正确的手机号'})
        return;
      }
      if(sketch.trim() == ''){
        this.$notice({desc: '请输入简述'});
        return;
      }
      const data = {
        name: name.trim(),
        phone,
        identities: identity,
        school:school.trim(),
        content: sketch.trim(),
        paramId: param_id,
        enquireType: enquire_type,
      }
      // console.log(data)
      let res;
      if(id){
        data.id = id;
        delete data.paramId;
        delete data.enquireType;
        res= await this.$api.consulting.edit(data);
      }else{
        res = await this.$api.consulting.create(data);
      }
      if(res.message == 'success'){
        this.$notice({desc: '提交成功', type: 'success'});
        this.doClose();
      }
    },

  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;

    this.init();
  },
}
</script>
<style lang="scss" scoped>
.components_pop_up_consulting_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    // width: 100%;
    // height: 100%;
    max-width: 80%;
    max-height: 90%;
    min-width: 600px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px 4px 0 0;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    width: 100%;
    font-size: 14px;
    color: #333333;
    padding: 10px 30px;
  }
  .hint{
    padding-top: 10px;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    font-weight: bold;
  }
  .line{
    padding-top: 15px;
    .key{
      width: 90px;
      padding-left: 10px;
      font-size: 14px;
      line-height: 36px;
      color: #333;
      &.required{
        position: relative;
        z-index: 2;
        &::before{
          content: '*';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          color: #E12F2F;
        }
      }
    }
  }
  .operating_button{
    width: 240px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .val_group{
    position: relative;
    &.fulid{
      flex: 1;
      input{
        width: 100%;
      }
    }
    .modify{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 12px;
      color: $main_blue;
      padding-right: 10px;
    }
  }
  input.name{
    width: 200px;
  }
  input{
    @include transition;
    font-size: 14px;
    line-height: 34px;
    border: 1px solid #E5E7EA;
    border-radius: 4px;
    padding: {
      left: 10px;
      right: 50px;
    }
    &:focus{
      border: 1px solid #1E4682;
    }
    &.readonly{
      border: 1px solid transparent;
    }
    
  }
}
</style>

<style lang="scss">
.components_pop_up_consulting_page{
  .textarea{
    &.readonly{
      .el-textarea__inner, .el-input__inner{
        border: 1px solid transparent;
      }
    }
    .el-textarea__inner,.el-input__inner{
      font-family: 'PingFang SC', 'Microsoft YaHei';
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding: {
        top: 7px;
        bottom: 7px;
        left: 10px;
        right: 50px;
      }
    }
  }
}
</style>