<template>
  <div
    v-if="showUnit"
    class="c_fun_template_select_contace_content_thumb_unit d-flex"
  >
    <div
      class="name_group"
    >
      <img
        :src="`${$avatar_url}${avatar}@!small200`"
        alt
        class="avatar my-10"
      >
      <div
        class="name"
        v-html="name"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import tools from '@/api/tools'
export default {
  name: 'ThumbUnit',
  props: {
    item: {
      type: Object,
      default: undefined
    },
    type: {
      type: String, // messageBox || contact || workteam
      default: 'messageBox'
    },
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    showUnit() {
      const { name } = this
      return (name ?? '') !== ''
    },
    avatar () {
      const { item } = this
      const { chatType, photo, thumbnail } = item
      if (chatType) {
        return photo
      }
      return thumbnail || photo
    },
    // 如果返回 false 则不显示该条目
    name () {
      const { item, search, type } = this
      const { friendRemark, name, chatType, chatName, userName } = item
      if (type === 'messageBox') {
        if (chatType) {
          if (search !== '') {
            const res = this.searchName(name)
            return res
          }
          return name
        }
        return friendRemark
      }
      if (type === 'contact') {
        if (search !== '') {
          const remarkRes = this.searchName(friendRemark)
          if ((remarkRes ?? '') !== '') {
            return remarkRes
          }
          const nameRes = this.searchName(userName)
          return nameRes
        }
        return friendRemark || userName
      }
      if (type === 'workteam') {
        if (search !== '') {
          const res = this.searchName(chatName)
          return res
        }
        return chatName
      }
      return undefined
    },
  },
  methods: {
    searchName(str) {
      const { search } = this
      if ( (str ?? '') === '') {
        return undefined
      }
      if (str && str.toLowerCase() && str.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return tools.highlightKeywords(str, search)
      }
      return undefined
      // console.log('searchName')
    }
  }
}
</script>

<style scoped lang="scss">
.c_fun_template_select_contace_content_thumb_unit{
  @include bbox;
  @include transition;
  width: 100%;
  padding: {
    left: 36px;
    right: 28px;
    // top: 11px;
    // bottom: 11px;
  }
  &:hover,
  &.on {
    background-color: #eee;
  }
  &.un_hover:hover{
    background-color: transparent;
  }
  .name_group {
    @include bbox;
    @include flex;
    flex: 1;
    width: 100%;
    overflow: hidden;
    .avatar {
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: block;
      background-color: $img_color;
    }
    .name {
      @include bbox;
      flex: 1;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
      font-size: 14px;

      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>