<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 16:32:21
 * @LastEditTime : 2021-11-24 11:30:20
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/Operation.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_select_contacts_operation d-flex align-center justify-end bbox flex-shrink-0"
  >
    <div class="btn btn_light cp"
      @click="handleCancel();"
    >取消</div>
    <div class="btn btn_dark cp">确定</div>

  </div>
</template>

<script>
export default {
  methods: {
    handleCancel(){
      this.$emit('handleCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_operation{
  width: 100%;
  border-top: 1px solid #f0f0f0;
  padding: {
    top: 10px;
    bottom: 10px;
    right: 15px;
  }
  .btn{
    margin-right: 15px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 4px;
    padding: 0 25px;
  }
}
</style>