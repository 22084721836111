<template>
  <div
    class="components_pop_up_call_task_search_result_page bbox"
  >
    <div
      v-if="initLoading"
      class="loading_wrapper d-flex align-center justify-center"
    >
      <div
        class="loading_group"
      >
        <loading-icon
          size="30px"
        ></loading-icon>
      </div>
    </div>
    <template
      v-if="!initLoading"
    >
      <template>
        <div
          class="title_group d-flex align-center un_sel"
          :class="{close: !switch_task}"
        >
          <div
            class="icon cp"
            @click="switch_task = !switch_task"
          ></div>
          <div
            class="title cp"
            @click="switch_task = !switch_task"
          >任务</div>
        </div>
        <template
          v-if="switch_task"
        >
          <template
            v-if="task_list.length !== 0"
          >
            <div class="task_list d-flex align-start flex-wrap">
              <template
                v-for="(item,index) in task_list"
              >
                <thumb-task
                  class="mr-10 mb-12 pl-10"
                  :key="index"
                  :item="item"
                  :menu="false"
                  :show_from="true"
                  @handleClick="callTask"
                ></thumb-task>
              </template>
            </div>
            <div
              v-if="!nomore_task"
              class="load_more"
            >
              <span
                v-if="!loading_task"
                class="text cp"
                @click="loadMoreTask();"
              >
                加载更多
              </span>
              <div
                v-if="loading_task"
                class="loading_group"
              >
                <loading-icon
                  size="20px"
                ></loading-icon>
              </div>
            </div>
          </template>
          <template
            v-else
          >
            <div
              class="no_data"
            >
              无相关任务
            </div>
          </template>
        </template>
      </template>
      <template
        v-if="user_list.length || 1"
      >
        <div
          class="title_group d-flex align-center un_sel"
          :class="{close: !switch_user}"
        >
          <div
            class="icon cp"
            @click="switchTag('switch_user')"
          ></div>
          <div
            class="title cp"
            @click="switchTag('switch_user')"
          >联系人</div>
        </div>
        <template
          v-if="switch_user"
        >
          <template
            v-if="!loadingWeb"
          >
            <template
              v-if="user_list && user_list.length !== 0"
            >
              <div
                class="user_list d-flex align-center flex-wrap"
              >
                <template
                  v-for="(item,index) in user_list"
                >
                  <div
                    :key="index"
                    class="user_group d-flex align-center cp"
                    @click="appointUser(item)"
                  >
                    <img
                      :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar"
                    >
                    <div class="name">{{item.friendRemark}}</div>
                  </div>
                </template>

              </div>
              <div
                v-if="!nomore_user && 0"
                class="load_more"
              >
                <span
                  class="text cp"
                  @click="loadMoreTask"
                >
                  加载更多
                </span>
              </div>
            </template>
            <template
              v-else
            >
              <div
                class="no_data"
              >
                无相关联系人
              </div>
            </template>
          </template>
          <template
            v-if="loadingWeb"
          >
            <div
              class="loading_group"
            >
              <loading-icon
                size="20px"
              ></loading-icon>
            </div>
          </template>
        </template>


      </template>
      <template
        v-if="group_list.length || 1"
      >
        <div
          class="title_group d-flex align-center un_sel"
          :class="{close: !switch_group}"
        >
          <div
            class="icon cp"
            @click="switchTag('switch_group')"
          ></div>
          <div
            class="title cp"
            @click="switchTag('switch_group')"
          >
            工作组
          </div>
        </div>
        <template
          v-if="switch_group"
        >
          <template
            v-if="!loadingWeb"
          >
            <template
              v-if="group_list && group_list.length !== 0"
            >
              <div class="group_list d-flex align-center flex-wrap">
                <template
                  v-for="(item,index) in group_list"
                >
                  <div
                    :key="index"
                    class="group_group d-flex align-center cp"
                    @click="appointGroup(item)"
                  >
                    <img
                      :src="`${$avatar_url}${item.photo}@!small200`" alt="" class="avatar"
                    >
                    <div
                      class="d-flex flex-column align-start"
                    >
                      <div class="name">{{item.chatName}}</div>
                      <div
                        class="member"
                        v-html="$tools.highlightKeywords(`成员: ${item.secondleveContent}`, keyword)"
                        v-if="item.secondleveContent != null"
                      ></div>
                    </div>
                  </div>
                </template>

              </div>
              <div class="load_more"
                   v-if="!nomore_group && 0"
              ><span class="text cp"
                     @click="loadMoreTask();"
              >加载更多</span></div>
            </template>
            <template
              v-else
            >
              <div
                class="no_data"
              >
                无相关工作组
              </div>
            </template>
          </template>
          <template
            v-if="loadingWeb"
          >
            <div
              class="loading_group"
            >
              <loading-icon
                size="20px"
              ></loading-icon>
            </div>
          </template>
        </template>
      </template>
      <div
        v-if="nodata && 0"
        class="nodata"
      >
        搜索结果为空
      </div>
    </template>
  </div>
</template>
<script>
import ThumbTask from '@/components/Common/ThumbTask.vue'
import LoadingIcon from '@/components/basic/loadingIcon.vue'
import global from '@/api/global'
// import Template from '@/components/Group/Aside/Template'
export default {
  components: {
    // Template,
    ThumbTask,
    LoadingIcon
  },
  props: {
    keyword: {
      type: String,
      default: '',
    }
  },
  data(){
    return{
      task_list: [],
      loading_task: false,
      nomore_task: false,
      switch_task: true,
      user_list: [],
      loading_user: false,
      nomore_user: false,
      switch_user: false,
      group_list: [],
      loading_group: false,
      nomore_group: false,
      switch_group: false,
      initLoading: false,
      loadWeb: false, // 加载searchWeb接口
      loadingWeb: false
    }
  },
  computed: {
    nodata () {
      const {task_list, group_list, user_list} = this
      if (task_list.length == 0 && group_list.length == 0 && user_list == 0) {
        return true
      }
      return false
    }
  },
  async mounted(){
    await this.init();
  },
  methods: {
    async getSearchWeb () {
      const { keyword } = this
      console.log(this.loadingWeb)
      if (this.loadingWeb) {
        return
      }
      this.loadingWeb = true
      const res = await global.doPost({
        url: '/project/searchWeb',
        data: { param: keyword },
        openNotice: false
      })
      this.loadingWeb = false
      if (res.message === 'success') {
        const { chatGoups, friends } = res.contents
        if (chatGoups) {
          this.$set(this, 'group_list', chatGoups)
        }
        if (friends) {
          this.$set(this, 'user_list', friends)
        }
      }
      this.loadWeb = true // 已加载过 标记

    },
    switchTag (type) {
      this[type] = !this[type]
      console.log(this.loadWeb)
      if (!this.loadWeb) {
        this.getSearchWeb()
      }
    },
    handleChange(){
      this.clearData()
      this.init()
    },
    clearData(){
      console.log('clearData')
      this.loadWeb = false
      this.switch_task = true
      this.switch_group = false
      this.switch_user = false
      this.$set(this, 'user_list', [])
      this.$set(this, 'group_list', [])
    },
    async init () {
      this.initLoading = true
      await this.getSearch()
      this.initLoading = false
    },
    async getSearch () {
      const { keyword } = this
      // console.log(keyword);
      const res = await this.$global.doPost({
        url: '/project/searchTask',
        data: { param: keyword },
        openNotice: false
      })
      if (res.message === 'success') {
        const { tasks } = res.contents
        // if (chatGoups) {
        //   this.$set(this, 'group_list', chatGoups)
        // }
        // if (friends) {
        //   this.$set(this, 'user_list', friends)
        // }
        if (tasks) {
          this.$set(this, 'task_list', tasks)
          if (tasks.length < 20) {
            this.nomore_task = true
          }
        }
      }
    },
    async getTask(){
      this.loading_task = true;
      const res = await this.$global.doPost({
        url: 'project/searchTask',
        data: {
          param:this.keyword,
          startId: this.task_list[this.task_list.length-1].id
        },
        openNotice: false
      })
      if (res.message === 'success') {
        const {tasks} = res.contents;
        if (tasks && tasks.length) {
          this.$set(this, 'task_list', this.task_list.concat(tasks))
        }
        if (tasks.length < 20 || !tasks) {
          this.nomore_task = true
        }
      }
      this.loading_task = false
    },
    loadMoreTask(){
      const {loading_task, nomore_task} = this;
      if(nomore_task || loading_task){
        return ;
      }
      this.getTask();
    },
    appointUser(item){
      this.$emit('handleAppoint', {
        name: item.friendRemark || item.userName, 
        chat_id: item.friendUserId,
        chat_type: 0,
        thumbnail: item.thumbnail
      });
    },
    appointGroup(item){
      this.$emit('handleAppoint', {
        name: item.chatName,
        chat_id: item.chatId,
        chat_type: 1,
        thumbnail: item.photo,
      });
    },
    callTask(data){
      this.$emit('handleCallTask', data);
    }

  }
}
</script>
<style lang="scss" scoped>
.components_pop_up_call_task_search_result_page{
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .loading_wrapper {
    width: 100%;
    height: 100%;
  }
  .title_group{
    padding: 10px;
    &.close{
      .icon{
        height: 11px;
         background-image: url(~@/assets/images/call_task/title_close.png);
      }
    }
    .icon{
      @include background;
      width: 10px;
      height: 9px;
      background-image: url(~@/assets/images/call_task/title_open.png);
    }
    .title{
      padding-left: 5px;
      font-size: 12px;
      color: #999999;
      line-height: 19px;
    }
  }
  .load_more{
    font-size: 12px;
    color: #0054A7;
    padding: 0 10px;
    line-height: 20px;
    text-align: center;
  }
  .mr-10{
    margin-right: 10px;
  }
  .mb-12{
    margin-bottom: 12px;
  }
  .pl-10{
    padding-left: 10px;
  }
  .user_list, .group_list{
    padding: 0 10px;
    .user_group, .group_group{
      height: 58px;
      background: #FFFFFF;
      border: 1px solid #E8EEFF;
      margin: {
        right: 10px;
        bottom: 12px;
      }
      padding: 0 12px;
      .avatar{
        width: 34px;
        height: 34px;
        border-radius: 4px;
        margin-right: 10px;
      }
      .name{
        font-size: 12px;
        color: #333333;
        line-height: 16px;
        word-break: keep-all;
      }
      .member{
        font-size: 12px;
        color: #999;
        line-height: 16px;
        word-break: keep-all;
      }
    }
  }
  .nodata{
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    padding: 30px;
    color: #aaa;
  }
  .no_data {
    width: 100%;
    padding: 10px;
    text-align: center;
    user-select: none;
    font-size: 14px;
    color: #999;
  }
}
</style>