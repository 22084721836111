<template>
  <div class="c_common_mudoles_thumb_package bbox"
    @click.stop="handleClick()"
  >
    <div class="name_group d-flex align-center bbox">
      <div class="name">{{p_package.title}}</div>
      <div class="menu"
        v-if="show_menu"
      ></div>
    </div>
    <img 
      :src="`${$img_url}${p_package.webpic}`"
      alt="" class="img mt-10">
    <div class="status_group mt-10">
      <div class="status success">通过</div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    p_package: {
      type: Object,
      default: undefined
    },
    show_menu: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    handleClick(){
      this.$emit('handleClick', this.p_package)
    }
  }
}
</script>

<style lang="scss" scoped>
.c_common_mudoles_thumb_package{
  background-color: #fff;
  width: 280px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #E8EEFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  .name_group{
    .name{
      @include single_line_intercept;
      flex: 1;
      font-size: 16px;
      color: #333;
    }
  }
  .img{
    width: 240px;
    height: 135px;
    display: block;
  }
  .status_group{
    .status{
      font-size: 14px;
      color: #333;
      &.success{
        color: #05AD00;
      }
    }
  }
}
</style>