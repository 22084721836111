var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_select_contacts_modules_user bbox d-flex align-center justify-space-between",
    },
    [
      _c("div", { staticClass: "info_group d-flex align-center" }, [
        _c("img", {
          staticClass: "avatar flex-shrink-0 mr-16",
          attrs: { src: _vm.avatar, alt: "" },
        }),
        _c("div", { staticClass: "user_name" }, [_vm._v(_vm._s(_vm.name))]),
      ]),
      _vm.type === "select"
        ? _c("div", { staticClass: "check cp flex-shrink-0" })
        : _vm._e(),
      _vm.type === "delete"
        ? _c("div", { staticClass: "delete cp flex-shrink-0" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }