var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_pop_up_consulting_page bbox" }, [
    _c(
      "div",
      {
        staticClass: "mask d-flex align-center justify-center",
        style: { zIndex: _vm.index },
      },
      [
        _c("div", { staticClass: "container bbox d-flex flex-column" }, [
          _c(
            "div",
            {
              staticClass:
                "title_group d-flex align-center un_sel justify-center flex-shrink-0",
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("咨询")]),
              _c("div", {
                staticClass: "close cp",
                on: {
                  click: function ($event) {
                    return _vm.doClose()
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "main bbox" }, [
            _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))]),
            _c("div", { staticClass: "line bbox d-flex" }, [
              _c("div", { staticClass: "key un_sel bbox required" }, [
                _vm._v("真实姓名"),
              ]),
              _c("div", { staticClass: "val_group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  staticClass: "name bbox",
                  class: { readonly: !_vm.edit },
                  attrs: { type: "text", readonly: !_vm.edit },
                  domProps: { value: _vm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.name = $event.target.value
                    },
                  },
                }),
                0
                  ? _c("div", { staticClass: "modify button" }, [
                      _vm._v("修改"),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "line bbox d-flex" }, [
              _c("div", { staticClass: "key un_sel bbox required" }, [
                _vm._v("联系方式"),
              ]),
              _c("div", { staticClass: "val_group fulid" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phone,
                      expression: "phone",
                    },
                  ],
                  staticClass: "phone bbox",
                  class: { readonly: !_vm.edit },
                  attrs: { type: "text", readonly: !_vm.edit },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phone = $event.target.value
                    },
                  },
                }),
                0
                  ? _c("div", { staticClass: "modify button" }, [
                      _vm._v("修改"),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "line bbox d-flex" }, [
              _c("div", { staticClass: "key un_sel bbox" }, [
                _vm._v("您的身份"),
              ]),
              _c(
                "div",
                { staticClass: "val_group fulid" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { fill: "#0054A7", disabled: !_vm.edit },
                      model: {
                        value: _vm.identity,
                        callback: function ($$v) {
                          _vm.identity = $$v
                        },
                        expression: "identity",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("教师")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("学生")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("职工")]),
                      _c("el-radio", { attrs: { label: 4 } }, [_vm._v("其他")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line bbox d-flex" }, [
              _c("div", { staticClass: "key un_sel bbox" }, [
                _vm._v("学校名称"),
              ]),
              _c("div", { staticClass: "val_group fulid" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.school,
                      expression: "school",
                    },
                  ],
                  staticClass: "school bbox",
                  class: { readonly: !_vm.edit },
                  attrs: { type: "text", readonly: !_vm.edit },
                  domProps: { value: _vm.school },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.school = $event.target.value
                    },
                  },
                }),
                0
                  ? _c("div", { staticClass: "modify button" }, [
                      _vm._v("修改"),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "line bbox d-flex" }, [
              _c("div", { staticClass: "key un_sel bbox required" }, [
                _vm._v("简短描述"),
              ]),
              _c(
                "div",
                { staticClass: "val_group fulid" },
                [
                  _c("el-input", {
                    staticClass: "textarea",
                    class: { readonly: !_vm.edit },
                    attrs: {
                      placeholder: "请输入简短描述",
                      type: "text",
                      resize: "none",
                      autosize: { minRows: 1, maxRows: 6 },
                      "show-word-limit": "",
                      maxlength: "80",
                      readonly: !_vm.edit,
                    },
                    model: {
                      value: _vm.sketch,
                      callback: function ($$v) {
                        _vm.sketch = $$v
                      },
                      expression: "sketch",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.edit
              ? _c(
                  "div",
                  {
                    staticClass: "operating_button btn_dark",
                    on: {
                      click: function ($event) {
                        return _vm.doSubmit()
                      },
                    },
                  },
                  [_vm._v("完成")]
                )
              : _vm._e(),
            !_vm.edit
              ? _c(
                  "div",
                  {
                    staticClass: "operating_button btn_dark",
                    on: {
                      click: function ($event) {
                        return _vm.reConsulting()
                      },
                    },
                  },
                  [_vm._v("再次咨询")]
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }