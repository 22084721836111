var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_linebar_task_tag_page bbox d-flex align-center",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.switch_input,
              expression: "!switch_input",
            },
          ],
          staticClass: "add_group cp flex-shrink-0 d-flex align-center",
          on: {
            click: function ($event) {
              return _vm.startAdd()
            },
          },
        },
        [
          _c("div", { staticClass: "icon" }),
          !_vm.list || (_vm.list && !_vm.list.length)
            ? _c("div", { staticClass: "text" }, [_vm._v("添加自定义标签")])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.switch_input,
              expression: "switch_input",
            },
          ],
          staticClass: "input_group flex-shrink-0",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input_tag,
                expression: "input_tag",
              },
            ],
            ref: "input",
            staticClass: "input bbox",
            attrs: { type: "text" },
            domProps: { value: _vm.input_tag },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.input_tag = $event.target.value
                },
                function ($event) {
                  return _vm.handleInput()
                },
              ],
              focus: function ($event) {
                return _vm.handleFocus()
              },
              blur: function ($event) {
                return _vm.handleBlur()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.inputAddTag()
              },
            },
          }),
          _c("div", { staticClass: "placeholder bbox" }, [
            _vm._v(_vm._s(_vm.input_tag)),
          ]),
        ]
      ),
      _c(
        "overlay-scrollbars",
        {
          ref: "overlay",
          staticClass: "overlay_container bbox d-flex align-center",
        },
        [
          _c(
            "div",
            { staticClass: "list d-flex align-center" },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("div", { key: index, staticClass: "tag flex-shrink-0" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.tagName)),
                    ]),
                    _c("div", {
                      staticClass: "close cp",
                      on: {
                        click: function ($event) {
                          return _vm.removeTag(item.tagName)
                        },
                      },
                    }),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "tag_list_group bbox",
          class: { active: _vm.switch_tag_list },
        },
        [
          _c(
            "div",
            {
              staticClass: "tag_list bbox",
              on: {
                click: function ($event) {
                  return _vm.$refs.input.focus()
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("选择已有标签")]),
              _c(
                "div",
                { staticClass: "tag_group bbox d-flex align-center flex-wrap" },
                [
                  _vm._l(_vm.tag_list, function (val, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tag cp",
                          class: { on: val.selected },
                          on: {
                            click: function ($event) {
                              return _vm.selectTagList(val)
                            },
                          },
                        },
                        [_vm._v(_vm._s(val.tagName))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }