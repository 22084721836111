var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_sidebar_widget_button_page d-flex flex-column align-center justify-center un_sel",
      class: { disabled: !_vm.editable },
    },
    [
      _c("div", { staticClass: "icon", class: [_vm.name] }),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }