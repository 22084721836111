var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_sidebar_record_container_page bbox" },
    [_c("record", { attrs: { task_id: _vm.task_id } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }