<template>
  <div class="c_pop_up_call_task_modules_package_detail bbox">
    <div class="list_group d-flex align-center flex-wrap">
      <template
        v-for="(item,index) in list"
      >
        <thumb-task
          :key="index"
          :p_task="item"
          class="task mr-10 mt-10"
          :show_from="false"
          @handleClick="handleClick"
        ></thumb-task>
      </template>
    </div>
    <div class="loadmore_group d-flex align-center justify-center bbox un_sel">
      <div class="loadmore cp"
        @click.stop="loadMore()"
        v-if="!nomore"
      >{{loading ? '加载中' : '加载更多'}}</div>
      <div class="nomore"
        v-if="nomore"
      >没有更多了</div>
    </div>
  </div>
</template>

<script> 
import global from '@/api/global.js'
import {listForClasses, PTask} from '@/types'
import ThumbTask from '@/components/Common/Modules/Thumb/Task.vue'
export default {
  components: {
    ThumbTask,
  },
  props: {
    task_call_config: {
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      nomore: false,
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    async init(){
      this.clearList()
      this.getList()
    },
    clearList(){
      this.loading = false
      this.nomore = false
      this.$set(this, 'list', [])
    },

    async getList(){
      const {task_call_config, loading, list} = this
      if(loading){
        return
      }
      const {p_package, search_keyword:keyword} = task_call_config
      const {id} = p_package
      const data = {
        packageId: id,
        keyword,
        // startId
        startId: list.length ? list[list.length-1].id : '',
      }
      this.loading = true
      const res = await global.doPost({
        url: '/projectTeach/queryPackageTaskList',
        data,
      })
      this.loading = false
      if(res.message === 'success'){
        const {contents} = res
        const {taskList} = contents
        // this.$set(this, 'list', listForClasses({
        //   list: taskList,
        //   classes: PTask,
        // }))
        this.$set(this, 'list', [
          ...new Set([
            ...this.list,
            ... listForClasses({
              list: taskList,
              classes: PTask,
            })
          ])]
        )
        if(!taskList || taskList.length < 20){
          this.nomore = true
        }
      }
    },
    async loadMore(){
      if(this.loading){
        return
      }
      this.getList()
    },
    handleClick(p_task){
      const {id:taskId} = p_task
      this.$api.moduleTask.callTask({taskId, config: {
        open_by_call: true,
      }})
    }
  }
}
</script>

<style lang="scss" scoped>
.c_pop_up_call_task_modules_package_detail{
  width: 100%;
  padding: 0 10px;
  .loadmore_group{
    padding: 10px 0;
    font-size: 14px;
    color: $main_blue;
    .nomore{
      color: #999;
    }
  }
}
</style>