<template>
  <div 
    class="components_task_sidebar_record_container_page bbox"
  >
    <record
      :task_id="task_id"
    ></record>
  </div>
</template>
<script>
import Record from '@/components/dialog/EditTask/PannelRecord.vue';
export default {
  components: {
    Record,
  },
  data(){
    return {
    }
  },
  computed: {
    task_id(){
      const {task} = this.$store.state;
      return task.task.id;
    },
  },
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_record_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;

}
</style>