<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 16:26:41
 * @LastEditTime : 2021-11-24 15:35:54
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/List.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_select_contacts_list bbox d-flex flex-column"
  >
   
    <div class="list">
      <div class="sticky_group">
        <search-group
          class="flex-shrink-0 bbox borderside_padding"
        ></search-group>
        <check-all
          class="pt-16 pb-16 flex-shrink-0 borderside_padding bbox"
        ></check-all>
      </div>
      <template
        v-for="item in dialog_user_list"
      >
        <unit
          :key="item.userId"
          class="py-10 user borderside_padding"
          :avatar="`${$avatar_url}${item.thumbnail}@!small200`"
          :name="UserInfo(item).priorableName()"
        ></unit>
      </template>
    </div>


  </div>
</template>

<script>
import {UserInfo} from '@/types';
import SearchGroup from './modules/SearchGroup.vue';
import CheckAll from './modules/CheckAll.vue';
import Unit from './modules/Unit.vue';
export default {
  components: {
    SearchGroup,
    CheckAll,
    Unit,
  },
  props: {
    dialog_user_list: {
      type: Array,
      default: null,
    },
  },
  mounted(){
    // console.log(this.dialog_user_list);
  },
  methods: {
    UserInfo(item){
      return new UserInfo(item);
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_list{
  padding: 16px 0;
  width: 300px;
  height: 100%;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
  .borderside_padding{
    padding: {
      left: 30px;
      right: 30px;
    }
  }
  .list{
    @include scrollbar;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    .user{
      // padding-right: 26px;
    }
    position: relative;
    .sticky_group{
      position: sticky;
      top:0;
      left: 0;
      background-color: #fff;
      // padding-right: 26px;
    }
  }
}
</style>