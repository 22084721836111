<!--
 * @Author       : Hugo
 * @Date         : 2021-11-23 16:30:07
 * @LastEditTime : 2021-11-24 11:23:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectContacts/DialogList.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_select_contacts_dialog_list bbox d-flex flex-column"
  >
   
    <div class="list">
      <div class="sticky_group">
        <search-group
          class="flex-shrink-0 bbox borderside_padding pb-16"
        ></search-group>
      </div>
      <div class="category py-5 borderside_padding bbox">最近...</div>
      <template
        v-for="item in 40"
      >
        <unit
          :key="item"
          class="py-10 user borderside_padding"
        ></unit>
      </template>
    </div>


  </div>
</template>

<script>
import SearchGroup from './modules/SearchGroup.vue';
import Unit from './modules/Unit.vue';
export default {
  components: {
    SearchGroup,
    Unit,
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_select_contacts_dialog_list{
  padding: 16px 0;
  width: 300px;
  height: 100%;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
  font-size: 14px;
  color: #666666;
  .borderside_padding{
    padding: {
      left: 30px;
      right: 30px;
    }
  }
  .list{
    @include scrollbar;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    .user{
      // padding-right: 26px;
    }
    position: relative;
    .sticky_group{
      position: sticky;
      top:0;
      left: 0;
      background-color: #fff;
      // padding-right: 26px;
    }
  }
}
</style>