var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_linebar_task_title_page bbox d-flex align-center",
    },
    [
      _c("div", { staticClass: "icon flex-shrink-0" }),
      _c("status-tag", { staticClass: "mr-4" }),
      _c("title-status-tag", {
        staticClass: "mr-5",
        attrs: {
          levelStatus: String(_vm.task.task.levelStatus),
          processStatus: String(_vm.task.task.processStatus),
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.task_config.task_name,
            expression: "task_config.task_name",
          },
        ],
        staticClass: "title",
        attrs: {
          type: "text",
          placeholder: "输入任务标题",
          readonly: !_vm.title_status,
        },
        domProps: { value: _vm.task_config.task_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.task_config, "task_name", $event.target.value)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }